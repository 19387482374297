import React from 'react';

import { QBox, QText } from '@qualio/ui-components';

const NotFound: React.FC = () => {
  return (
    <QBox>
      <QText>You seem to be lost</QText>
    </QBox>
  );
};

export default NotFound;
