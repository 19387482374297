import styled from 'styled-components';

export const Container = styled.div<{ isEmptyState?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ isEmptyState = false }) =>
    isEmptyState &&
    `
      align-items: center;
      padding-top: 12px;
  `}
`;
