import React, { useCallback } from 'react';

import { QButton, QCloseButton, QModal, QModalBody, QModalHeader, QStack, QText } from '@qualio/ui-components';
import { useNavigate } from 'react-router-dom';

export type VideoModalProps = {
  title: string;
  videoUrl: string;
  findOutMoreUrl: string;
  contactEmail?: string;
};

export const VideoModal: React.FC<VideoModalProps> = ({ title, videoUrl, findOutMoreUrl, contactEmail }) => {
  const navigate = useNavigate();
  const close = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const openFindOutMore = useCallback(() => {
    window.open(findOutMoreUrl, '_blank');
  }, [findOutMoreUrl]);

  const openContact = useCallback(() => {
    window.open(`mailto:${contactEmail}`);
  }, [contactEmail]);

  return (
    <QModal isOpen onClose={close} size="2xl">
      <QModalHeader>
        <QText>{title}</QText>
        <QCloseButton onClick={close} />
      </QModalHeader>
      <QModalBody>
        <QStack alignItems="center" marginBottom={4}>
          <iframe
            width="560px"
            height="312px"
            src={videoUrl}
            title={title}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          ></iframe>
          <QStack direction="row" w="full" justifyContent="center">
            <QButton variant="outline" onClick={openFindOutMore}>
              Find out more
            </QButton>
            {contactEmail ? <QButton onClick={openContact}>Contact us</QButton> : null}
          </QStack>
        </QStack>
      </QModalBody>
    </QModal>
  );
};
