import { useToastProvider, useAnalytics, useCurrentUser } from '@qualio/ui-components';
import { AxiosError } from 'axios';
import { transformFormikToApiPayload } from 'lib';
import { useMutation, useQuery } from 'react-query';
import { Supplier, SupplierStatus } from 'types';
import { AddSupplierFormikPayload } from 'v2types';

import supplierApi from '../api/supplier.api';

type UseSupplierQueryType = {
  isSupplierLoading: boolean;
  supplier: Supplier | undefined;
  refetchSupplier: () => void;
};

export const useSupplierQuery = (supplierId?: string): UseSupplierQueryType => {
  const { companyId } = useCurrentUser();

  const {
    isLoading: isSupplierLoading,
    refetch: refetchSupplier,
    data: supplier,
  } = useQuery(`supplierDetail-${supplierId}`, () => supplierApi.getSupplierById(companyId, supplierId), {
    enabled: !!supplierId,
  });

  return { isSupplierLoading, supplier, refetchSupplier };
};

type UseSupplierArchiveMutationType = {
  isArchiveSupplierLoading: boolean;
  archiveSupplierMutate: (supplier: Supplier) => void;
};

export const useSupplierArchiveMutation = (onSuccess: () => void): UseSupplierArchiveMutationType => {
  const { showToast } = useToastProvider();
  const { companyId } = useCurrentUser();

  const { isLoading: isArchiveSupplierLoading, mutate: archiveSupplierMutate } = useMutation(
    (supplier: Supplier) => supplierApi.archive(companyId, supplier.supplier as string),
    {
      onSuccess: (data) => {
        showToast({
          title: 'Archived',
          description: `${data.name} was archived`,
          status: 'success',
        });
        onSuccess();
      },
      onError: () => {
        showToast({
          title: 'Failure',
          description: `Supplier not archived`,
          status: 'error',
        });
      },
    },
  );
  return { isArchiveSupplierLoading, archiveSupplierMutate };
};

type UseSupplierUnArchiveMutationType = {
  isUnArchiveSupplierLoading: boolean;
  unArchiveSupplierMutate: (supplier: Supplier) => void;
};

export const useSupplierUnArchiveMutation = (onSuccess: () => void): UseSupplierUnArchiveMutationType => {
  const { showToast } = useToastProvider();
  const { companyId } = useCurrentUser();

  const { isLoading: isUnArchiveSupplierLoading, mutate: unArchiveSupplierMutate } = useMutation(
    (supplier: Supplier) => supplierApi.createSupplier(companyId, { ...supplier, status: 'DRAFT' }),
    {
      onSuccess: ({ supplier }) => {
        showToast({
          title: 'Un Archived',
          description: `${supplier.name} was unarchived`,
          status: 'success',
        });
        onSuccess();
      },
      onError: () => {
        showToast({
          title: 'Failure',
          description: `Supplier was not unarchived`,
          status: 'error',
        });
      },
    },
  );

  return { isUnArchiveSupplierLoading, unArchiveSupplierMutate };
};

type RevertToDraftSupplierType = {
  isRevertToDraftSupplierLoading: boolean;
  revertToDraftSupplierMutate: (supplier: Supplier) => void;
};

export const useSupplierRevertToDraftMutation = (onSuccess: () => void): RevertToDraftSupplierType => {
  const { showToast } = useToastProvider();
  const { companyId } = useCurrentUser();

  const { isLoading: isRevertToDraftSupplierLoading, mutate: revertToDraftSupplierMutate } = useMutation(
    (supplier: Supplier) => supplierApi.createSupplier(companyId, { ...supplier, status: 'DRAFT' }),
    {
      onSuccess: ({ supplier }) => {
        showToast({
          title: 'Reverted',
          description: `${supplier.name} was reverted to draft`,
          status: 'success',
        });
        onSuccess();
      },
      onError: () => {
        showToast({
          title: 'Failure',
          description: `Supplier was not reverted to draft`,
          status: 'error',
        });
      },
    },
  );

  return { isRevertToDraftSupplierLoading, revertToDraftSupplierMutate };
};

type MutateSupplier = { supplier: AddSupplierFormikPayload; status: SupplierStatus; isDraft: boolean };

type MutateOptions = {
  onError?: (args: AxiosError<{ errors: [{ field: string; message: string[] }] }>) => void;
};

type UseCreateSupplierMutate = {
  createSupplierMutate: (data: MutateSupplier, options?: MutateOptions) => void;
  isSupplierCreating: boolean;
};

export const useCreateSupplierMutate = (onSuccess: (supplierId: string) => void): UseCreateSupplierMutate => {
  const { showToast } = useToastProvider();
  const { companyId } = useCurrentUser();

  const { isLoading: isSupplierCreating, mutate: createSupplierMutate } = useMutation(
    ({
      supplier,
      status,
      isDraft,
    }: {
      supplier: AddSupplierFormikPayload;
      status: SupplierStatus;
      isDraft: boolean;
    }) => {
      if (!isDraft) {
        return supplierApi
          .createSupplierV2(companyId, { ...transformFormikToApiPayload(supplier), status })
          .then((supplierResponse) =>
            supplierApi
              .sendForReview(companyId, supplierResponse.supplier.supplier as string)
              .then(() => ({ ...supplierResponse, isDraft: false })),
          );
      } else {
        return supplierApi
          .createSupplierV2(companyId, { ...transformFormikToApiPayload(supplier), status })
          .then((response) => ({ ...response, isDraft: status === 'DRAFT' }));
      }
    },
    {
      onSuccess: ({ supplier, isDraft }) => {
        if (!isDraft) {
          showToast({
            title: 'Saved',
            description: `${supplier.name} was saved as draft.`,
            status: 'success',
          });
          showToast({
            title: 'Sent',
            description: `${supplier.name} was sent for approval`,
            status: 'success',
          });
        } else if (supplier.status === 'REVIEW_PENDING') {
          showToast({
            title: 'Sent',
            description: `${supplier.name} was sent for approval`,
            status: 'success',
          });
        } else {
          showToast({
            title: 'Saved',
            description: `${supplier.name} was saved as draft.`,
            status: 'success',
          });
        }
        onSuccess(supplier.supplier!);
      },
      onError: () => {
        showToast({
          title: 'Failure',
          description: `Supplier was not saved as a draft.`,
          status: 'error',
        });
      },
    },
  );
  return { isSupplierCreating, createSupplierMutate } as UseCreateSupplierMutate;
};

type UseSupplierExportMutationType = {
  isExportSupplierLoading: boolean;
  exportSupplierMutate: (supplier: Supplier) => void;
};

export const useSupplierExportMutation = (onSuccess: () => void): UseSupplierExportMutationType => {
  const { showToast } = useToastProvider();
  const { companyId } = useCurrentUser();

  const { isLoading: isExportSupplierLoading, mutate: exportSupplierMutate } = useMutation(
    (supplier: Supplier) => supplierApi.export(companyId, supplier.supplier as string),
    {
      onSuccess: (data) => {
        showToast({
          title: 'File Ready',
          description: (
            <span>
              The file is now available to{' '}
              <a style={{ color: '#0069FF' }} href={data.url}>
                download
              </a>
            </span>
          ),
          status: 'success',
        });
        onSuccess();
      },
      onError: () => {
        showToast({
          title: 'Failure',
          description: `Supplier not exported`,
          status: 'error',
        });
      },
    },
  );
  return { isExportSupplierLoading, exportSupplierMutate };
};

type RevertSupplierToDraftMutationType = {
  isRevertSupplierToDraftLoading: boolean;
  revertSupplierToDraftMutate: (supplier: Supplier) => void;
};

export const useRevertSupplierToDraftMutationType = (onSuccess: () => void): RevertSupplierToDraftMutationType => {
  const { showToast } = useToastProvider();
  const { companyId } = useCurrentUser();

  const { isLoading: isRevertSupplierToDraftLoading, mutate: revertSupplierToDraftMutate } = useMutation(
    (supplier: Supplier) => supplierApi.revertToDraft(companyId, supplier.supplier as string),
    {
      onSuccess: (supplier) => {
        if (supplier.status === 'DRAFT') {
          showToast({
            title: 'Sent',
            description: `${supplier.name} was reverted to draft`,
            status: 'success',
          });
        }
        onSuccess();
      },
      onError: () => {
        showToast({
          title: 'Failure',
          description: `Supplier not reverted to draft`,
          status: 'error',
        });
      },
    },
  );
  return { isRevertSupplierToDraftLoading, revertSupplierToDraftMutate };
};

type UseSupplierSpreadsheetExportMutationType = {
  isExportSupplierSpreadsheetLoading: boolean;
  exportSupplierSpreadsheetMutate: (supplierIds: string[]) => void;
};

export const useSupplierSpreadsheetExportMutation = (): UseSupplierSpreadsheetExportMutationType => {
  const { showToast } = useToastProvider();
  const analytics = useAnalytics();
  const { companyId } = useCurrentUser();

  const { isLoading: isExportSupplierSpreadsheetLoading, mutate: exportSupplierSpreadsheetMutate } = useMutation(
    (supplierIds: string[]) => supplierApi.exportSpreadsheet(companyId, supplierIds),
    {
      onMutate: () => {
        showToast({
          title: 'Preparing file for download',
          description: "We'll let you know when the file is ready",
          status: 'info',
          duration: 10000,
        });
        analytics.track('Suppliers | Export Supplier List as Spreadsheet');
      },
      onSuccess: (data) => {
        showToast({
          title: 'File Ready',
          description: (
            <span>
              The file is now available to{' '}
              <a style={{ color: '#0069FF' }} href={data.url}>
                download
              </a>
            </span>
          ),
          status: 'success',
          duration: null, //https://chakra-ui.com/docs/components/toast/props#:~:text=If%20set%20to%20null%2C%20toast%20will%20never%20dismiss.
        });
      },
      onError: () => {
        showToast({
          title: 'Failure',
          description: `Suppliers not exported`,
          status: 'error',
        });
      },
    },
  );
  return { isExportSupplierSpreadsheetLoading, exportSupplierSpreadsheetMutate };
};
