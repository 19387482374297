export const POLICY_TABS = [
  {
    id: 'documents',
    title: 'Required documents',
  },
  {
    id: 'audit',
    title: 'Audits',
  },
];

export const activePolicyRouteId = (pathname: string): string => {
  return POLICY_TABS.find((tab) => pathname?.includes(tab.id))?.id || 'documents';
};
