import React, { useCallback } from 'react';

import { QButton, QEmptyState, QEmptyStateButtonGroup, QEmptyStateSecondaryButtonGroup } from '@qualio/ui-components';
import { Outlet, useNavigate } from 'react-router-dom';

const SuppliersInsufficientPermissionsView: React.FC = () => {
  const navigate = useNavigate();
  const showProductVideo = useCallback(() => {
    navigate('/product-video');
  }, [navigate]);
  return (
    <>
      <div data-cy={'suppliers-insufficient-permissions'}>
        <QEmptyState
          title="Trying to do some supply chain work?"
          subtitle={[
            'Unfortunately, you currently don’t have access to the Suppliers module.',
            'Contact your admin so you can easily onboard, manage',
            'and continuously improve your supplier base.',
          ]}
        >
          <QEmptyStateButtonGroup>
            <QEmptyStateSecondaryButtonGroup>
              <QButton variant="outline" onClick={showProductVideo}>
                Watch product video
              </QButton>
            </QEmptyStateSecondaryButtonGroup>
          </QEmptyStateButtonGroup>
        </QEmptyState>
        <Outlet />
      </div>
    </>
  );
};

export default SuppliersInsufficientPermissionsView;
