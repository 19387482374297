import * as Locales from 'date-fns/locale';
import { registerLocale } from 'react-datepicker';

const USER_LANG = navigator.language;

export const registerLocalLocale = (): void => {
  switch (USER_LANG) {
    case 'en-GB':
      registerLocale('en-GB', Locales.enGB);
      break;
    default:
      registerLocale('en-US', Locales.enUS);
      break;
  }
};
