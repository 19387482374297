import React from 'react';

import { StyleSheet, View, Text } from '@react-pdf/renderer';
import { DateTime } from 'luxon';
import { Supplier, Audit } from 'types';
import { getLastAuditDueMillis } from 'utils';
import { ConfigItem } from 'v2types';

import NextAuditDue from '../../SupplierList/components/NextAuditDue';
import { columnStyles } from './SupplierListPage';

type SupplierRowProps = {
  supplier: Supplier;
  policyLinks: any;
  auditTypes: any;
};

const supplierRowStyle = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 8,
    paddingBottom: 8,
    borderBottom: '0.5px solid black',
  },
  defaultText: {
    fontSize: 8,
    fontWeight: 400,
  },
});

const SupplierRow: React.FC<SupplierRowProps> = ({ supplier, policyLinks, auditTypes }) => {
  const getLastAuditDate = (audits: Audit[] | undefined) => {
    const lastAudiDate = getLastAuditDueMillis(audits);
    if (!lastAudiDate) return '';
    return DateTime.fromMillis(lastAudiDate).toFormat('yyyy-LL-dd');
  };

  return (
    <View style={supplierRowStyle.row} wrap={false}>
      <View style={columnStyles.column1}>
        <Text style={{ ...supplierRowStyle.defaultText, fontWeight: 600 }}>{supplier.name}</Text>
        {supplier.website ? (
          <Text style={{ ...supplierRowStyle.defaultText, paddingTop: 3 }}>{supplier.website}</Text>
        ) : null}
      </View>
      <View style={columnStyles.column2}>
        <Text style={supplierRowStyle.defaultText}>{supplier.supplierType?.title}</Text>
      </View>
      <View style={columnStyles.column3}>
        <Text style={supplierRowStyle.defaultText}>{supplier.riskType?.title}</Text>
      </View>
      <View style={columnStyles.column4}>
        <Text style={supplierRowStyle.defaultText}>{supplier?.intendedUse}</Text>
      </View>
      <View style={columnStyles.column5}>
        <Text style={supplierRowStyle.defaultText}>{getLastAuditDate(supplier.audits)}</Text>
      </View>
      <View style={columnStyles.column6}>
        <Text style={supplierRowStyle.defaultText}>
          <NextAuditDue
            audits={supplier.audits}
            supplier={supplier.supplier}
            policyLinks={policyLinks}
            supplierType={supplier?.supplierType as ConfigItem}
            riskLevel={supplier?.riskType as ConfigItem}
            auditTypes={auditTypes}
            isForPdfExport={true}
          />
        </Text>
      </View>
    </View>
  );
};
export default SupplierRow;
