export const SUPPLIERS_TYPES_TEMPLATE = [
  {
    title: 'Manufacturer',
    description:
      'Is a person or company that produces finished goods from raw materials by using various tools, equipment, and processes, and then sells the goods to consumers.',
  },
  {
    title: 'Services',
    description: 'Describes work that supports a business but does not produce a tangible commodity.',
  },
  {
    title: 'Distributor',
    description:
      'A distributor is a person, entity or selling agent who works independently to sell the products of a manufacturer, and is bound by a financial contract.',
  },
  {
    title: 'Consultants',
    description:
      'A consultant is an person who provides professional or expert advice in a particular field of science or business to either an organization or individual.',
  },
];

export const RISK_TYPES_TEMPLATE = [
  {
    title: 'Critical',
    description: 'Highest-risk suppliers with critical impact on the quality or availability of the product.',
  },
  {
    title: 'High',
    description: 'Heavy-risk suppliers with direct impact on the product, but for which alternatives are available.',
  },
  {
    title: 'Medium',
    description: 'Moderate-risk suppliers have more of an indirect impact on the product.',
  },
  {
    title: 'Low',
    description: 'Low risk suppliers have no significant impact on the final product.',
  },
];

export const DOCUMENT_TYPES_TEMPLATE = [
  {
    title: 'Quality agreement',
    description:
      'Quality agreements are essential for defining in writing who is responsible for the tasks and duties laid out in the quality system.',
  },
  {
    title: 'SOC2 report',
    description:
      'SOC 2 defines criteria for managing customer data based on five "trust service principles" — security, availability, processing integrity, confidentiality and privacy.',
  },
  {
    title: 'GDPR statement',
    description:
      'It is a public-facing document that sets out the steps your company is taking, or that it has already taken, to become GDPR compliant.',
  },
  {
    title: 'SOW agreement',
    description:
      'A Statement of Work is a contract between you and your freelance clients and is often used in place of a more formal agreement.',
  },
  {
    title: 'Standard certification',
    description: 'Evidence of quality system compliance related to the operations being performed (for example ISO).',
  },
];

export const AUDIT_TYPES_TEMPLATE = [
  {
    title: 'on-site',
    description: 'All the internal audits can be tagged with this type.',
  },
  {
    title: 'remote',
    description: 'All the external audits can be tagged with this type.',
  },
];
