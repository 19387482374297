import React, { useState } from 'react';

import {
  QBox,
  QButton,
  QCloseButton,
  QDivider,
  QFlex,
  QHeading,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QSpacer,
  QSpinner,
  QStep,
  QStepper,
  QStepperPanelActions,
  QStepperProgress,
  QSteps,
  QStepsActions,
  QText,
} from '@qualio/ui-components';
import { useNavigate } from 'react-router-dom';
import { routes } from 'utils';
import { AddSupplierFormikPayload } from 'v2types';

import { ApprovalForm, GeneralInfoForm, RiskAssessmentForm, SupportingDocumentsForm } from './components';
import { useStepper } from './hooks';

type AddSupplierPresentationalProps = { formikValues: AddSupplierFormikPayload; isSupplierLoading: boolean };

export const AddSupplierPresentational: React.FC<AddSupplierPresentationalProps> = ({
  formikValues,
  isSupplierLoading,
}) => {
  const navigate = useNavigate();

  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isNameMissingModal, setIsNameMissingModal] = useState(false);
  const [isDraftButtonClicked, setIsDraftButtonClicked] = useState(false);

  const {
    handleNextStep,
    handlePreviousStep,
    handleStepChange,
    activeStep,
    steps,
    formik,
    handleCancelForm,
    handleSubmitForm,
    isSupplierCreating,
  } = useStepper(formikValues);

  const handleSaveDraftButton = (status: boolean) => {
    if (status) setIsDraftButtonClicked(true);
    else setIsDraftButtonClicked(false);
  };

  const saveAsADraftSupplier = () => {
    formik.setStatus(false);
    handleSaveDraftButton(true);
    if (formik.errors.contactEmail || formik.errors.name || formik.errors.intendedUse || formik.errors.contactNotes) {
      formik.setTouched({ ...formik.touched, contactEmail: true, name: true, contactNotes: true, intendedUse: true });
      handleStepChange(0); // Always show warnings on General Info page
    } else {
      if (!formik.values.name) {
        setIsNameMissingModal(true);
      } else {
        handleCancelForm();
      }
    }
  };

  const confirmSetIsNameMissingModalAction = () => {
    setIsNameMissingModal(false);
    handleStepChange(0);
  };

  return (
    <QBox minHeight="400px" data-cy="supplier-main-container">
      <QFlex align="center" mb={2}>
        <QHeading size={'lg'}>Add Supplier</QHeading>
        <QSpacer />
        <QCloseButton onClick={() => setIsCancelModalOpen(true)} />
      </QFlex>
      <QDivider />
      {isSupplierLoading ? (
        <QSpinner />
      ) : (
        <QBox mt={4}>
          <QStepper activeStep={activeStep} onStepItemClicked={handleStepChange}>
            <QSteps>
              <QStepperProgress />
              <QStep
                title="General info"
                description="Enter general supplier information"
                variant={steps[0].variant}
                data-cy="step-general-information"
              >
                <GeneralInfoForm formik={formik} isDraft={isDraftButtonClicked} handleStepChange={handleStepChange} />
              </QStep>
              <QStep
                title="Risk assessment"
                description="Select the supplier risk level"
                variant={steps[1].variant}
                data-cy="step-risk-assessment"
              >
                <RiskAssessmentForm formik={formik} />
              </QStep>
              <QStep
                title="Supporting documents"
                description="Upload or link required documentation"
                variant={steps[2].variant}
                data-cy="step-supporting-documents"
              >
                <SupportingDocumentsForm formik={formik} />
              </QStep>
              <QStep
                title="Approval"
                description="Check all information and send the supplier for approval"
                variant={steps[3].variant}
                data-cy="step-approval"
              >
                <ApprovalForm formik={formik} handleStepChange={handleStepChange} />
              </QStep>
              <QStepsActions>
                <QButton
                  data-cy="supplier-save-and-exit-btn"
                  width="100%"
                  variant="outline"
                  onClick={saveAsADraftSupplier}
                  isLoading={isSupplierCreating}
                >
                  Save as draft and exit
                </QButton>
              </QStepsActions>
            </QSteps>
            <QStepperPanelActions>
              <QButton variant="ghost" onClick={() => setIsCancelModalOpen(true)}>
                Cancel
              </QButton>
              <QButton
                leftIcon="ArrowLeft"
                variant="outline"
                onClick={handlePreviousStep}
                isDisabled={activeStep === 0}
              >
                Previous
              </QButton>
              {activeStep === steps.length - 1 ? (
                <QButton onClick={handleSubmitForm} isLoading={isSupplierCreating}>
                  Send for approval
                </QButton>
              ) : (
                <QButton data-cy="supplier-next-btn" rightIcon="ArrowRight" onClick={handleNextStep}>
                  Next
                </QButton>
              )}
            </QStepperPanelActions>
          </QStepper>
        </QBox>
      )}
      <QModal isOpen={isCancelModalOpen} onClose={() => setIsCancelModalOpen(false)}>
        <QModalHeader>
          <QText>Discard changes</QText>
          <QCloseButton onClick={() => setIsCancelModalOpen(false)} />
        </QModalHeader>
        <QModalBody>
          <QText>
            Are you sure you want to discard your changes to this supplier? You can also save it as draft and finish
            later.
          </QText>
        </QModalBody>
        <QModalActions>
          <QButton onClick={() => setIsCancelModalOpen(false)} variant="ghost">
            Cancel
          </QButton>
          <QButton onClick={saveAsADraftSupplier} isLoading={isSupplierCreating} variant="outline">
            Save as draft
          </QButton>
          <QButton isDestructive onClick={() => navigate(`/${routes.supplierList}`)}>
            Discard changes
          </QButton>
        </QModalActions>
      </QModal>
      <QModal isOpen={isNameMissingModal} onClose={() => setIsNameMissingModal(false)}>
        <QModalHeader>
          <QText>Can't save supplier</QText>
        </QModalHeader>
        <QModalBody>
          <QText>To save this supplier you need to give it a name.</QText>
        </QModalBody>
        <QModalActions>
          <QButton onClick={confirmSetIsNameMissingModalAction}>Got it</QButton>
        </QModalActions>
      </QModal>
    </QBox>
  );
};
