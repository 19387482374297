import { useEffect, useState } from 'react';

import { QSelectItem, useCurrentUser } from '@qualio/ui-components';
import { useQuery } from 'react-query';
import { User } from 'types';

import supplierApi from '../api/supplier.api';

export type UserOptionsType = QSelectItem & User;

type UseCompanyUsersQueryResult = {
  companyUsers?: User[];
  usersOptions: UserOptionsType[];
  isCompanyUsersLoading: boolean;
  companyUsersError: any;
};

export const useCompanyUsersQuery = (): UseCompanyUsersQueryResult => {
  const { companyId } = useCurrentUser();
  const [usersOptions, setUsersOptions] = useState<UserOptionsType[]>([
    { label: '', value: '', email: '', fullName: '', id: '' },
  ]);
  const {
    data: companyUsers,
    isLoading: isCompanyUsersLoading,
    error: companyUsersError,
  } = useQuery('getCompanyUsers', () => supplierApi.listUsers(companyId));

  useEffect(() => {
    if (companyUsers?.length) {
      setUsersOptions(
        companyUsers.map((user) => ({
          ...user,
          label: `${user.fullName || ''} (${user.email})`,
          value: '' + user.id || '',
        })),
      );
    }
  }, [companyUsers?.length, companyUsers]);

  return {
    companyUsers,
    isCompanyUsersLoading,
    companyUsersError,
    usersOptions,
  };
};
