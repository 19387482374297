import confetti from 'canvas-confetti';
const colors = ['#3D4CF2', '#21134D', '#FFFFFF', '#FCCD3F', '#EB8DD5', '#FF6E52'];

export const showConfetti = (): void => {
  const end = Date.now() + 15 * 400;
  return (function frame() {
    confetti({
      particleCount: 2,
      angle: 60,
      spread: 55,
      origin: { x: 0 },
      colors: colors,
    });
    confetti({
      particleCount: 2,
      angle: 120,
      spread: 55,
      origin: { x: 1 },
      colors: colors,
    });
    if (Date.now() < end) {
      requestAnimationFrame(frame);
    }
  })();
};
