import React, { useState } from 'react';

import {
  QEmptyState,
  QEmptyStateButtonGroup,
  QEmptyStatePrimaryButton,
  QButton,
  QStack,
  QDivider,
  QText,
} from '@qualio/ui-components';
import { useNavigate } from 'react-router-dom';
import { routes } from 'utils';
import { ImportModal } from 'v2components';

import { ImportModalProps } from './ImportModal';

export const EmptyState: React.VFC<Pick<ImportModalProps, 'refetchSuppliers'>> = ({ refetchSuppliers }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  return (
    <>
      <div data-cy="suppliers-empty-state">
        <QEmptyState
          title="You don’t have any suppliers yet"
          subtitle="Before you add a supplier it’s recommended to create a supplier policy. The policy will define supplier types you work with, risk levels that will help assess your suppliers and required types of documents each supplier needs to have to be approved."
        >
          <QEmptyStateButtonGroup>
            <QEmptyStatePrimaryButton>
              <QButton onClick={() => navigate(`/${routes.addSupplier}`)}>Add Supplier</QButton>
            </QEmptyStatePrimaryButton>
          </QEmptyStateButtonGroup>
        </QEmptyState>
      </div>

      <QStack direction="row" alignItems="center" pb={4}>
        <QDivider orientation="horizontal" />
        <QText>or</QText>
        <QDivider orientation="horizontal" />
      </QStack>
      <QStack direction="column" alignItems="center" spacing={4}>
        <QButton aria-label="icon-button" size="sm" variant="outline" onClick={() => setIsModalOpen(true)}>
          Import Suppliers
        </QButton>
        <QButton
          aria-label="icon-button"
          size="sm"
          variant="outline"
          onClick={() => navigate(`/${routes.policyConfiguration.root}/${routes.policyConfiguration.supplierTypes}`)}
        >
          Configure Policy
        </QButton>
        <ImportModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} refetchSuppliers={refetchSuppliers} />
      </QStack>
    </>
  );
};
