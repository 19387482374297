import React from 'react';

import { QBox, QDivider, QHeading, QStack, QTag, QText } from '@qualio/ui-components';
import { STATUS_CELL_MAPPING } from 'lib';
import { DateTime } from 'luxon';
import { useRouterOutletSupplier } from 'v2views';

const isEmpty = (item?: string | number) => (item ? item : '--');

const SupplierDetails: React.FC = () => {
  const { supplier } = useRouterOutletSupplier();

  const showApproverBadge = (userId: number) => {
    if (supplier.status === 'DRAFT' || !supplier.changeControl) return;
    const status =
      supplier.changeControl?.reviews?.find((review) => review.reviewer.id === userId)?.status || 'undefined';
    const tag = STATUS_CELL_MAPPING[status];
    return <QTag variantColor={tag.tagColor}>{tag.text}</QTag>;
  };

  return (
    <QStack maxWidth="800px" spacing={4}>
      <QHeading size="sm">Risk level</QHeading>
      {supplier?.riskType ? (
        <QBox w="fit-content">
          <QTag>{supplier.riskType?.title}</QTag>
          <QText fontSize="sm" color="gray.500">
            {isEmpty(supplier.riskType?.description)}
          </QText>
        </QBox>
      ) : (
        <QText fontSize="sm" color="gray.500">
          --
        </QText>
      )}

      <QDivider />

      <QHeading size="sm">General info</QHeading>
      <QStack direction={['column', 'column', 'row']} spacing={4}>
        <QStack direction="column" spacing={5} w={['100%', '100%', '50%']}>
          <span>
            <QText fontSize="sm" weight="semibold">
              Supplier name
            </QText>
            <QText color="gray.500" fontSize="sm">
              {isEmpty(supplier.name)}
            </QText>
          </span>
          <span>
            <QText fontSize="sm" weight="semibold">
              Supplier type
            </QText>
            <QText color="gray.500" fontSize="sm">
              {isEmpty(supplier.supplierType?.title)}
            </QText>
          </span>
          <span>
            <QText fontSize="sm" weight="semibold">
              Website
            </QText>
            <QText color="gray.500" fontSize="sm">
              {isEmpty(supplier.website)}
            </QText>
          </span>
          <span>
            <QText fontSize="sm" weight="semibold">
              Intended use
            </QText>
            <QText color="gray.500" fontSize="sm">
              {isEmpty(supplier.intendedUse)}
            </QText>
          </span>
        </QStack>
        <QStack direction="column" spacing={5} w={['100%', '100%', '50%']}>
          <span>
            <QText fontSize="sm" weight="semibold">
              Sponsor
            </QText>
            <QText color="gray.500" fontSize="sm">
              {isEmpty(supplier.sponsor?.fullName)}
            </QText>
          </span>
          <span data-cy="entered-supplier-approver">
            <QText fontSize="sm" weight="semibold">
              Approvers
            </QText>
            <QStack direction="column" spacing={2}>
              {supplier?.approvers?.length
                ? supplier.approvers.map((approver, index) => (
                    <QStack direction="row" spacing={3} key={index}>
                      <QText color="gray.500" fontSize="sm">
                        {approver.fullName}
                      </QText>
                      {showApproverBadge(approver.id)}
                    </QStack>
                  ))
                : '--'}
            </QStack>
          </span>
          <span>
            <QText fontSize="sm" weight="semibold">
              Created on
            </QText>
            <QText color="gray.500" fontSize="sm">
              {supplier?.created ? DateTime.fromISO(supplier.created).toFormat('yyyy-LL-dd HH:mm (ZZZZ)') : '--'}
            </QText>
          </span>
          <span>
            <QText fontSize="sm" weight="semibold">
              Last modified
            </QText>
            <QText color="gray.500" fontSize="sm">
              {supplier?.modified ? DateTime.fromISO(supplier.modified).toFormat('yyyy-LL-dd HH:mm (ZZZZ)') : '--'}
            </QText>
          </span>
        </QStack>
      </QStack>
      <QDivider />
      <QHeading size="sm">Supplier contact information</QHeading>
      <QStack direction={['column', 'column', 'row']} spacing={4}>
        <QStack direction="column" spacing={5} w={['100%', '100%', '50%']}>
          <span>
            <QText fontSize="sm" weight="semibold">
              Name
            </QText>
            <QText color="gray.500" fontSize="sm">
              {isEmpty(supplier.contactName)}
            </QText>
          </span>
          <span>
            <QText fontSize="sm" weight="semibold">
              Email
            </QText>
            <QText color="gray.500" fontSize="sm">
              {isEmpty(supplier.contactEmail)}
            </QText>
          </span>
          <span>
            <QText fontSize="sm" weight="semibold">
              Phone
            </QText>
            <QText color="gray.500" fontSize="sm">
              {isEmpty(supplier.contactPhone)}
            </QText>
          </span>
          <span>
            <QText fontSize="sm" weight="semibold">
              Address
            </QText>
            <QText color="gray.500" fontSize="sm">
              {isEmpty(supplier.contactAddress)}
            </QText>
          </span>
        </QStack>
        <QStack direction="column" spacing={5} w={['100%', '100%', '50%']}>
          <span>
            <QText fontSize="sm" weight="semibold">
              Notes
            </QText>
            <QText color="gray.500" fontSize="sm">
              {isEmpty(supplier.contactNotes)}
            </QText>
          </span>
        </QStack>
      </QStack>
    </QStack>
  );
};

export default SupplierDetails;
