import React, { useState } from 'react';

import { QBox, QIcon, QStack, QText, QButton, useToastProvider, QTheme, useCurrentUser } from '@qualio/ui-components';
import documentApi from 'api/document.api';
import { File } from 'providers/types';
interface ViewFileCardProps {
  file: File;
}

const ViewFileCard: React.FC<ViewFileCardProps> = ({ file }) => {
  const { companyId } = useCurrentUser();
  const { showToast } = useToastProvider();
  const [isVisible, setIsVisible] = useState(false);

  const hoverOn = () => {
    setIsVisible(true);
  };
  const hoverOut = () => {
    setIsVisible(false);
  };

  const handleDownloadDocument = async () => {
    if (!file?.id) return;
    try {
      const response = await documentApi.getDocumentFile(companyId, file.id);
      if (response) {
        const data = new Blob([response.data]);
        const object = window.URL.createObjectURL(data);
        const tempLink = document.createElement('a');
        tempLink.href = object;
        tempLink.setAttribute('download', file.fileName!);
        tempLink.click();
      }
    } catch (error) {
      console.error(error);
      showToast({
        title: 'Error',
        description: 'Something wrong happened downloading this document',
        status: 'error',
      });
    }
  };

  const showDownloadButton = (
    <QBox display={isVisible ? 'flex' : 'none'}>
      <QButton
        leftIcon="Download"
        variant="ghost"
        size="sm"
        data-cy="download-file-button"
        onClick={handleDownloadDocument}
      >
        Download
      </QButton>
    </QBox>
  );

  const showQualioButton = (
    <QBox display={isVisible ? 'flex' : 'none'}>
      <a href={`${window.location.origin}/library/e/${file.file_id}`} target="_blank" rel="noopener noreferrer">
        <QButton leftIcon="ExternalLink" size={'sm'} variant="ghost">
          Open
        </QButton>
      </a>
    </QBox>
  );

  const hoverStyle = {
    background: QTheme.colors.gray[50],
    borderRadius: '4px',
    cursor: 'pointer',
  };

  return (
    <QStack
      direction="column"
      py={2}
      pl={2}
      pr={2}
      spacing={0}
      onMouseOver={hoverOn}
      onMouseOut={hoverOut}
      style={isVisible ? hoverStyle : {}}
    >
      <QStack direction="row">
        <QBox ml={1} mr={1} my={1.5} color={'gray.400'}>
          <QIcon iconName={'FileText'} size="md" />
        </QBox>
        <QBox display="flex" flexDirection="row" justifyContent="space-between" width="100%" m={0}>
          <QBox my={1.5}>
            <QText fontSize="sm">{file?.fileName}</QText>
          </QBox>
          {file.type === 'FILE' ? showDownloadButton : showQualioButton}
        </QBox>
      </QStack>
      <QStack>
        <QBox ml={9}>
          {file?.description && (
            <QText color="gray.500" fontSize="xs">
              {file.description}
            </QText>
          )}
        </QBox>
      </QStack>
    </QStack>
  );
};

export default ViewFileCard;
