import React from 'react';

import { QEmptyState } from '@qualio/ui-components';

const EmergencyBreak: React.FC = () => (
  <div data-cy={'emergency-break'}>
    <QEmptyState
      title="Suppliers is under maintenance"
      subtitle="We are currently doing maintenance work on Suppliers. Get some coffee and check back a little later. Get in touch if you have any questions."
    />
  </div>
);

export default EmergencyBreak;
