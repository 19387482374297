import React from 'react';

import { StyleSheet, Text, View } from '@react-pdf/renderer';

import PageCounter from './PageCounter';

const pageFooterStyle = StyleSheet.create({
  bodyText: {
    fontSize: 8,
  },
  container: {
    position: 'absolute',
    bottom: 30,
    left: 42,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  pageCounterContainer: {
    fontSize: 8,
  },
});

const PageFooter: React.FC = () => {
  return (
    <View style={pageFooterStyle.container} fixed>
      <Text style={pageFooterStyle.bodyText}>Generated with Qualio, a QMS for Life Sciences</Text>
      <PageCounter />
    </View>
  );
};

export default PageFooter;
