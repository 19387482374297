import React from 'react';

import { QHeading, QTabs, QFlex, QSpinner, QCenter, QCloseButton, QSpacer, QDivider } from '@qualio/ui-components';
import { useConfigsQuery } from 'hooks';
import { Outlet, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { routes } from 'utils';

import { activeRouteId, TABS } from './constants';

export const SupplierPolicyConfigurationView: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    isConfigsLoading,
    supplierTypeOptions,
    riskTypeOptions,
    auditTypeOptions,
    documentTypeOptions,
    refetchConfigs,
    configs,
  } = useConfigsQuery();

  return (
    <>
      <QFlex align="center" mb={2}>
        <QHeading size={'lg'}>Policy Configuration</QHeading>
        <QSpacer />
        <QCloseButton onClick={() => navigate(`/${routes.policy.root}/${routes.policy.documents}`)} />
      </QFlex>
      <QDivider />
      {isConfigsLoading ? (
        <QCenter p={5}>
          <QSpinner />
        </QCenter>
      ) : (
        <QFlex flexDirection="row" mt={4}>
          <QTabs
            orientation="vertical"
            activeTabId={activeRouteId(pathname)}
            tabs={TABS}
            onClick={(tabItem) => navigate(`/settings/${tabItem.id}`)}
          />
          {isConfigsLoading ? (
            <QSpinner />
          ) : (
            <Outlet
              context={{
                isConfigsLoading,
                supplierTypeOptions,
                riskTypeOptions,
                auditTypeOptions,
                documentTypeOptions,
                refetchConfigs,
                configs,
              }}
            />
          )}
        </QFlex>
      )}
    </>
  );
};

export type OutletSupplierPolicyConfigurationType = {
  isConfigsLoading: boolean;
  supplierTypeOptions: any;
  riskTypeOptions: any;
  auditTypeOptions: any;
  documentTypeOptions: any;
  configs: any;
  refetchConfigs: () => void;
};

export const useRouterOutletSupplierPolicyConfiguration = (): OutletSupplierPolicyConfigurationType => {
  return useOutletContext<OutletSupplierPolicyConfigurationType>();
};
