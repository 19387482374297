import { Text } from '@qualio/ui-components';
import Select from 'react-select';
import styled from 'styled-components';

export const StyledSupplierPolicyContainer = styled.div`
  width: 100%;
  margin: 0px auto;
  border: 1px solid #e1e1e5;
  border-radius: 4px;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  p,
  div {
    margin-bottom: 0px;
  }

  .policy-lead-title {
    display: flex;
    .empty {
      display: flex;
      width: 15%;
      max-width: 160px;
      min-width: 160px;
      border: 1px solid #e1e1e5;
    }

    .column-header {
      border: 1px solid #e1e1e5;
      height: 36px;
      margin: 0px auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 5px;
      background: ${({ theme }) => theme.colors.background.secondary};
    }
  }

  .column-header {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }
`;

export const StyledSupplier = styled(Text)``;
export const StyledDataTable = styled.div`
  .rdt_TableHead {
    height: 40px;
  }
  .rdt_TableHeadRow > .rdt_TableCol {
    border: 1px solid #e1e1e5;
    height: 40px;
    padding-left: 10px;
  }
  .rdt_TableHeadRow > .rdt_TableCol:first-child {
    max-width: 160px;
    min-width: 160px;
    background: #f8f9fa;
    left: 0;
    z-index: 1;
    background: #fff;
    position: sticky;
  }
  .rdt_TableCell:first-child {
    max-width: 160px;
    min-width: 160px;
    left: 0;
    z-index: 1;
    background: #fff;
    position: sticky;
  }
  .rdt_TableCell {
    padding: 10px;
    display: table-cell;
    text-align: left;
    min-width: 220px;
    border: 1px solid #e1e1e5;
    min-height: 120px;
  }
  .rdt_TableCell > p {
    margin-bottom: 8px;
    display: flex;
    font-size: ${({ theme }) => theme.fontSizes.sm};
    span {
      margin-right: 5px;
      display: flex;
      button {
        font-size: 12px;
        margin-top: -8px;
      }
    }
  }
`;

export const StyledSupplierPolicyCell = styled.div`
  height: 100%;  
  button.edit-button {
    position: absolute;
    right: 5px;
    margin-top: -10px;
    display: none;
  }
  .policy-cell-container {
    width: 100%;
    height: 100%;
  }
  .policy-cell-container:hover {
    button.edit-button {
      display: inline-block;
    }
  }
  .empty-cell {
    p {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
    }
  }
  .header-container {
    display: flex;
    .config-card {
      width: 49%;
      margin: 10px;
      height: 56px;
      padding: 8px 16px 8px 8px;
      background: #f3f7ff;
      border: 1px solid #dbe8ff;
      border-radius: 4px;
      display: flex;

      .svg-container {
        width: 40px;
        height: 40px;
        margin: 0px 8px !important;
        background: #dbe8ff;
        border-radius: 3px;
        svg {
          margin: 0px auto;
          margin-top: 7px;
        }
      }
    }
  }
  hr {
    width: 98%;
    margin: 22px auto !important;
  }

  .type-list {
    display: flex;
    font-size: ${({ theme }) => theme.fontSizes.sm};px !important;

    button {
      font-size: ${({ theme }) => theme.fontSizes.sm};
      margin-top: -3px;
    }
  }

  .type-list.audits:first-of-type {
    margin-top: 16px;
  }

  .type-list.audits {
    display: flex;
    flex-direction: row;
    align-items: center;

    .due-select {
      width: 230px;
    }

    > div {
      padding: 0 15px;
    }

    p {
      font-size: ${({ theme }) => theme.fontSizes.sm};
    }
  }
`;

export const StyledSelect = styled(Select)<{ isError?: boolean }>`
  border-radius: 4px;
  margin: 8px 0;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  line-height: 24px;
  color: #8b95a3;
  width: 100%;

  .css-1rhbuit-multiValue {
    background: #ffffff;
    border: 1px solid #e1e1e5;
    ${({ isError }) => isError && `border-color: #E35D6A`}
    border-radius: 18px;
  }
  div {
    ${({ isError }) => isError && `border-color: #E35D6A`}
  }
`;
