import React, { useEffect, useState } from 'react';

import {
  QButton,
  QButtonGroup,
  QCloseButton,
  QFlex,
  QHeading,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QSpacer,
  QStack,
  QText,
} from '@qualio/ui-components';
import { Field, FieldArray, FormikProvider, useFormik } from 'formik';
import { usePostConfig } from 'hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from 'utils';
import { useRouterOutletSupplierPolicyConfiguration } from 'v2views';
import * as z from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import { activeRouteDataKeyTitle } from '../constants';
import { postConfigFormikValidation } from '../types';
import { transformConfigRequest } from '../utils';
import ConfigurationCard from './ConfigurationCard';
import { ConfigurationTemplate } from './ConfigurationTemplate';

export const Configuration: React.FC = () => {
  const navigate = useNavigate();
  const [confirmChangesModal, setConfirmChangesModal] = useState<boolean>(false);
  const { configurationType } = useParams();
  const configType: { title: string; dataKey: string; id: string; key: string; template: any } =
    activeRouteDataKeyTitle(configurationType!);
  const { configs, refetchConfigs } = useRouterOutletSupplierPolicyConfiguration();

  const onSuccess = () => {
    setConfirmChangesModal(false);
    formik.setStatus(null);
    refetchConfigs();
  };

  const { isConfigsMutateLoading, mutateConfigs } = usePostConfig(onSuccess);

  const formik = useFormik({
    initialValues: { [configType.id]: configs.find((item: any) => item.type === configType.key)?.options || [] },
    validationSchema: toFormikValidationSchema(
      z.object({
        [configType.id]: postConfigFormikValidation,
      }),
    ),
    onSubmit: (args) => {
      mutateConfigs({ type: configType.key, options: transformConfigRequest(args[configType.id]) });
    },
  });

  const handleCreateFromScratch = () => {
    formik.setFieldValue(configType.id, [{ title: '', description: '' }]);
  };

  const handleUseTemplate = (template: any) => {
    formik.setFieldValue(configType.id, template);
  };

  useEffect(() => {
    formik.resetForm({
      values: { [configType.id]: configs.find((item: any) => item.type === configType.key)?.options || [] },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configType.id, configType.key, configs]);

  return (
    <QFlex flexDirection="column" w="100%">
      {!formik.values?.[configType.id]?.length ? (
        <ConfigurationTemplate
          template={configType.template}
          name={configType.title}
          handleCreateFromScratch={handleCreateFromScratch}
          handleUseTemplate={() => handleUseTemplate(configType.template)}
        />
      ) : (
        <>
          <QHeading mb={6}>{configType.title}</QHeading>
          <FormikProvider value={formik}>
            <>
              <FieldArray
                name={configType.id}
                render={({ push, remove }) => (
                  <QStack spacing={4} height="80vh" overflow="auto" mb={4}>
                    {formik.values?.[configType.id]?.map((item: any, index: any) => (
                      <Field name={`${configType.id}.${index}`} key={index}>
                        {({ field, form: { setFieldValue, errors, touched, status } }: { field: any; form: any }) => (
                          <ConfigurationCard
                            field={field}
                            setFieldValue={setFieldValue}
                            errors={errors?.[configType.id]?.[index]}
                            handleDeleteItem={() => remove(index)}
                            touched={touched?.[configType.id]?.[index]}
                            status={status}
                          />
                        )}
                      </Field>
                    ))}
                    <QFlex borderColor="blackAlpha.200" borderWidth="1px" borderRadius="base" p={4} w="100%">
                      <QButton
                        data-cy="add-new-config-type"
                        variant="ghost"
                        leftIcon="PlusCircle"
                        onClick={() => push({ title: '', description: '' })}
                      >
                        Add a new
                      </QButton>
                    </QFlex>
                  </QStack>
                )}
              />
              <QFlex>
                <QSpacer />
                <QButtonGroup>
                  <QButton
                    variant="ghost"
                    onClick={() => navigate(`/${routes.policy.root}/${routes.policy.documents}`)}
                  >
                    Cancel
                  </QButton>
                  <QButton
                    data-cy="save-button"
                    onClick={() => {
                      formik.setStatus('touched');
                      formik.validateForm(formik.values).then((value: any) => {
                        setConfirmChangesModal(!!!Object.keys(value).length);
                      });
                    }}
                  >
                    Save changes
                  </QButton>
                </QButtonGroup>
              </QFlex>
            </>
          </FormikProvider>
        </>
      )}
      <QModal isOpen={confirmChangesModal} onClose={() => setConfirmChangesModal(false)}>
        <QModalHeader>
          <QText>Save changes</QText>
          <QCloseButton onClick={() => setConfirmChangesModal(false)} />
        </QModalHeader>
        <QModalBody>
          <QText fontSize={'sm'}>
            Changes you made to supplier types will affect all existing suppliers of those supplier types. Make sure to
            review all affected suppliers and reassess them if needed.
          </QText>
        </QModalBody>
        <QModalActions>
          <QButton onClick={() => setConfirmChangesModal(false)} variant="outline">
            Cancel
          </QButton>
          <QButton
            data-cy={'modal-save-button'}
            onClick={() => formik.handleSubmit()}
            isLoading={isConfigsMutateLoading}
            variant="solid"
          >
            Save changes
          </QButton>
        </QModalActions>
      </QModal>
    </QFlex>
  );
};
