import React from 'react';

import { Route, Routes } from 'react-router-dom';

import { EmergencyBreak } from '../v2views';

const EmergencyBreakRoutes: React.FC = () => (
  <Routes>
    <Route path="*" element={<EmergencyBreak />} />
  </Routes>
);

export default EmergencyBreakRoutes;
