import * as z from 'zod';

import { getFallbackMap } from '../lib';
import { Audit } from './audit';
import { ChangeControl } from './changeControl';
import { QualioUser } from './misc';

type LabeledValue = {
  value: string;
  label: string;
  fullName?: string;
  full_name?: string;
  email?: string;
  id?: number;
};

export type ConfigItem = {
  id?: string;
  title?: string;
  label?: string;
  value?: string;
  description?: string;
};

export type Supplier = {
  approvers?: QualioUser[];
  approvalDate?: string;
  archived?: boolean;
  company?: string;
  contactEmail?: string;
  contactName?: string;
  contactPhone?: number | string;
  contactAddress?: string;
  contactNotes?: string;
  created?: string;
  documents?: any[];
  intendedUse?: string;
  modified?: string;
  name?: string;
  riskType?: ConfigItem;
  sponsor?: Partial<QualioUser | LabeledValue>;
  status?: string;
  supplier?: string;
  preApprovalAuditId?: string | null;
  supplierType?: ConfigItem;
  website?: string;
  changeControl?: ChangeControl;
  version?: number;
  audits?: Audit[];
};

export type SupplierSettingsItem = {
  id?: string | null;
  title: string;
  description: string;
  edit?: boolean;
};

export type SupplierSettingOption = {
  label: string;
  value?: string | null;
  userGroup?: any;
};

export const SupplierStatus = z.enum(['REVIEW_PENDING', 'APPROVED', 'UNAPPROVED', 'REJECTED', 'DRAFT', 'ARCHIVED']);
export type SupplierStatus = z.infer<typeof SupplierStatus>;

export const ReviewResult = z.object({
  status: SupplierStatus,
});
export type ReviewResult = z.infer<typeof ReviewResult>;

const supplierTerminologyMap: Map<string, string> = new Map([
  ['approvers', 'Approvers'],
  ['approvalDate', 'Approval date'],
  ['archived', 'Archived'],
  ['contactEmail', 'Contact email'],
  ['contactName', 'Contact name'],
  ['contactPhone', 'Contact phone'],
  ['contactAddress', 'Contact address'],
  ['contactNotes', 'Contact notes'],
  ['documents', 'Documents'],
  ['intendedUse', 'Intended use'],
  ['modified', 'Modified'],
  ['name', 'Name'],
  ['riskType', 'Risk type'],
  ['sponsor', 'Sponsor'],
  ['status', 'Status'],
  ['supplier', 'Supplier'],
  ['preApprovalAuditId', 'Pre-approval audit ID'],
  ['supplierType', 'Supplier type'],
  ['website', 'Website'],
  ['version', 'Version'],
  ['title', 'Title'],
  ['description', 'Description'],
  ['value', 'Value'],
  ['label', 'Label'],
  ['fullName', 'Name'],
  ['full_name', 'Name'],
  ['email', 'Email'],
  ['value', 'Value'],
  ['file', 'File'],
  ['fileName', 'File Name'],
  ['type', 'Type'],
]);

export function getSupplierTerminologyTerm(key: string): string {
  return getFallbackMap(supplierTerminologyMap, key);
}

export type SupplierExportResponse = {
  url: string;
};
