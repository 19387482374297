import React, { useState } from 'react';

import { useToken } from '@chakra-ui/react';
import { QBox, QButton, QIcon, QIconButton, QStack, QText, QTextarea } from '@qualio/ui-components';
import { AsyncSelect } from 'chakra-react-select';
import { useQualioDocsQuery } from 'hooks';
import { Document, File } from 'providers';
import { NoQualioDocFoundSelect } from 'v2components';

type FileHeaderProps = {
  fileName: File['fileName'];
};

const FileHeader: React.FC<FileHeaderProps> = ({ fileName }) => (
  <>
    <QIcon iconName="File" {...{ color: 'gray.200' }} />
    <QText fontSize="sm" color="gray.500" fontWeight="semibold" pl={1}>
      {fileName}
    </QText>
  </>
);

type QualioDocHeaderProps = {
  document: Omit<Document, 'required' | 'name'>;
  handleQualioDocSelected: (file: Omit<Document, 'required' | 'name'>) => void;
};

const QualioDocHeader: React.FC<QualioDocHeaderProps> = ({ document, handleQualioDocSelected }) => {
  const { getDocumentOptions } = useQualioDocsQuery();

  const onQualioDocSelect = (doc: any) => {
    const newDocument: Omit<Document, 'required' | 'name'> = {
      ...document,
      file: {
        ...document.file,
        fileName: `${doc.code} ${doc.title}`,
        type: 'QUALIO_DOC',
        file_id: doc.id,
        code: doc.code,
      },
    };
    handleQualioDocSelected(newDocument);
  };

  const { file } = document;
  return (
    <>
      {file.file_id && file.fileName ? (
        <>
          <QIcon iconName="File" {...{ color: 'gray.200' }} />
          <QText fontSize="sm" color="gray.500" fontWeight="semibold" pl={1}>
            {file.fileName}
          </QText>
        </>
      ) : (
        <QBox w="300px">
          <AsyncSelect
            size="sm"
            className="document-select"
            loadOptions={getDocumentOptions}
            isClearable={true}
            onChange={onQualioDocSelect}
            noOptionsMessage={(e) => <NoQualioDocFoundSelect inputElement={e} />}
          />
        </QBox>
      )}
    </>
  );
};

type FileCardProps = {
  document: Omit<Document, 'required' | 'name'>;
  handleDeleteFile: () => void;
  handleUpdateFile: (document: Omit<Document, 'required' | 'name'>) => void;
};

const EditFileCard: React.FC<FileCardProps> = ({ document, handleDeleteFile, handleUpdateFile }) => {
  const [blackAlpha400] = useToken('colors', ['blackAlpha.400']);
  const [areActionsVisible, setAreActionsVisible] = useState(false);
  const { file } = document;

  const [isEditable, setIsEditable] = useState(!!file?.description);

  const hoverOn = () => {
    setAreActionsVisible(true);
  };
  const hoverOut = () => {
    setAreActionsVisible(false);
  };

  return (
    <QBox
      border="1px"
      borderColor="blackAlpha.200"
      borderRadius="base"
      p={2}
      _hover={{ outline: `1px solid ${blackAlpha400}` }}
      onMouseOver={hoverOn}
      onMouseOut={hoverOut}
    >
      <QBox display="flex" justifyContent="space-between" alignContent="center">
        <QBox display="flex" alignItems="center" h="34px">
          {file.type === 'FILE' ? (
            <FileHeader fileName={file.fileName} />
          ) : (
            <QualioDocHeader
              document={document}
              handleQualioDocSelected={(updatedFile) => handleUpdateFile(updatedFile)}
            />
          )}
        </QBox>
        <QStack direction="row" style={areActionsVisible ? { display: 'inline-block' } : { display: 'none' }}>
          {file.type === 'FILE' ? (
            <QButton variant="ghost" leftIcon="Download">
              Download
            </QButton>
          ) : (
            <a href={`${window.location.origin}/library/e/${file.file_id}`} target="_blank" rel="noopener noreferrer">
              <QButton variant="ghost" leftIcon="ExternalLink">
                Open
              </QButton>
            </a>
          )}
          <QButton variant="ghost" leftIcon="Trash" onClick={handleDeleteFile}>
            Delete
          </QButton>
        </QStack>
      </QBox>
      {!isEditable && (
        <QBox mt={2}>
          <QButton variant="link" leftIcon="Edit3" onClick={() => setIsEditable(true)}>
            Add description
          </QButton>
        </QBox>
      )}
      {isEditable && (
        <QBox display={'flex'} alignItems="center" mt={2}>
          <QTextarea
            value={file.description}
            onChange={(e) => handleUpdateFile({ file: { ...file, description: e.target.value } })}
          />
          <QIconButton
            aria-label="delete-description"
            iconName="Trash"
            {...{ mx: 1 }}
            onClick={() => {
              handleUpdateFile({ file: { ...file, description: '' } });
              setIsEditable(false);
            }}
          />
        </QBox>
      )}
    </QBox>
  );
};

export default EditFileCard;
