import { Payload } from 'types';
import { Config } from 'v2types';

import { makeResponse } from './generic';

export const configsResponse: Payload<Config[]> = makeResponse([
  {
    type: 'Audit',
    options: [
      {
        id: 'fd118ef4-22bf-4f4d-b32b-1de35976c3c7',
        title: 'on-site',
        description: 'All the internal audits can be tagged with this type.',
      },
      {
        id: 'c4b1ba99-4e1b-41ea-91e3-d07e972339fb',
        title: 'remote',
        description: 'All the external audits can be tagged with this type.',
      },
      {
        id: '50b32589-209e-4d63-9d15-1cf82e2c03e1',
        title: 'oncall',
        description: 'All on-call audits can be tagged with this type.',
      },
    ],
  },
  {
    type: 'Document',
    options: [
      {
        id: '2b52e13e-459c-4880-84b9-e40efe13468e',
        title: 'Quality agreement',
        description:
          'Quality agreements are essential for defining––in writing––who is responsible for the tasks and duties laid out in the quality system.',
      },
      {
        id: 'e900d009-268a-49e7-b6f5-3560f5a55502',
        title: 'SOC2 report',
        description:
          'SOC 2 defines criteria for managing customer data based on five "trust service principles" — security, availability, processing integrity, confidentiality and privacy.',
      },
      {
        id: 'fcfcb6df-b990-4486-8a3f-f1f2ef0a76c4',
        title: 'GDPR statement',
        description:
          'It is a public-facing document that sets out the steps your company is taking, or that it has already taken, to become GDPR compliant.',
      },
      {
        id: '61a15833-25f6-42a5-a077-861cce03291d',
        title: 'SOW agreement',
        description:
          'A Statement of Work is a contract between you and your freelance clients and is often used in place of a more formal agreement.',
      },
      {
        id: '3003fcd7-cc53-48aa-bc5b-17e1043359bd',
        title: 'Standard certification',
        description:
          'Evidence of quality system compliance related to the operations being performed (for example ISO).',
      },
    ],
  },
  {
    type: 'Risk',
    options: [
      {
        id: '527f4347-dcd8-43a7-8f8b-04495d8323d7',
        title: 'Critical',
        description:
          'Highest-risk suppliers with critical impact on the quality or availability of the product.Highest-risk suppliers with critical impact on the quality or availability of the product.Highest-risk suppliers with critical impact on the quality or availability of the product.Highest-risk suppliers with critical impact on the quality or availability of the product.Highest-risk suppliers with critical impact on the quality or availability of the product.Highest-risk suppliers with critical impact on the quality or availability of the product.',
      },
      {
        id: '3f50ddba-c2b3-45eb-860d-7cde2ac0951f',
        title: 'High',
        description:
          'Heavy-risk suppliers with direct impact on the product, but for which alternatives are available.',
      },
      {
        id: '4a068d85-8a0c-431e-b348-c3ce82c1d5ff',
        title: 'Medium',
        description: 'Moderate-risk suppliers have more of an indirect impact on the product.',
      },
      {
        id: '729937eb-4df0-49de-b18a-9e33cc3641ab',
        title: 'Low',
        description: 'Low risk suppliers have no significant impact on the final product.',
      },
    ],
  },
  {
    type: 'Type',
    options: [
      {
        id: 'ec3cce8b-b80a-42c0-996e-14b483883493',
        title: 'Manufacturer',
        description:
          'Is a person or company that produces finished goods from raw materials by using various tools, equipment, and processes, and then sells the goods to consumers.',
      },
      {
        id: '8b332ef7-20c9-452a-a4a4-2724a1b4eb39',
        title: 'Services',
        description: 'Describes work that supports a business but does not produce a tangible commodity.',
      },
      {
        id: 'ee8bec77-7bde-4fbb-80b5-a6e384d03a24',
        title: 'Distributor',
        description:
          'A distributor is a person, entity or selling agent who works independently to sell the products of a manufacturer, and is bound by a financial contract.',
      },
      {
        id: '0d8a0d85-68f4-4ec9-8d31-6ea2c67d57e2',
        title: 'Consultants',
        description:
          'A consultant is an person who provides professional or expert advice in a particular field of science or business to either an organization or individual.',
      },
    ],
  },
]);
