import React, { useCallback, useMemo, useState } from 'react';

import {
  createQColumnHelper,
  DataProvider,
  QAlert,
  QBox,
  QButton,
  QCloseButton,
  QDataTable,
  QDrawer,
  QEmptyState,
  QEmptyStateButtonGroup,
  QEmptyStatePrimaryButton,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QSpinner,
  QStack,
  QText,
  TableMenuItem,
} from '@qualio/ui-components';
import { useFormik } from 'formik';
import { useAuditDeleteMutate, useAuditMutation, useAuditsQuery, useConfigsQuery, usePoliciesQuery } from 'hooks';
import { useParams } from 'react-router-dom';
import { Audit } from 'types';
import { ViewFileCard, CreateAuditForm, NextAudit } from 'v2components';
import { ConfigItem } from 'v2types';
import { useRouterOutletSupplier } from 'v2views';
import * as z from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import { getAuditFormObject } from './SupplierAudits.const';

const columnHelper = createQColumnHelper<Audit>();
const BASE_COLUMNS = [
  columnHelper.text('name', { width: '30%' }),
  columnHelper.text('auditType.title', { header: 'Audit type' }),
  columnHelper.text('notes'),
  columnHelper.text('user.fullName', { header: 'Added by' }),
  columnHelper.date((audit) => (audit.endDate ? new Date(audit.endDate) : undefined), { header: 'Date completed' }),
  columnHelper.date((audit) => (audit.modified ? new Date(audit.modified) : undefined), { header: 'Date added' }),
  columnHelper.text((audit) => `${audit.documents?.length ?? 0} files`, { header: 'Documents' }),
] as const;

const SupplierAudits: React.FC = () => {
  const { supplierId } = useParams<{ supplierId: string }>();
  const [selectedAudit, setSelectedAudit] = useState<Audit | null>(null);
  const [showAddAuditModal, setShowAddAuditModal] = useState(false);
  const { supplier } = useRouterOutletSupplier();
  const { isConfigsLoading, auditTypeOptions } = useConfigsQuery();
  const { policyLinks } = usePoliciesQuery(isConfigsLoading);
  const [deleteAuditModalState, setDeleteAuditModalState] = useState<Audit | undefined>(undefined);
  const [addAuditSubmitButtonDisabled, setAddAuditSubmitButtonDisabled] = useState<boolean>(false);

  const { audits, isAuditsLoading, refetchAudits } = useAuditsQuery(supplierId!);

  const onSuccess = () => {
    setShowAddAuditModal(false);
    formik.resetForm();
    refetchAudits();
  };

  const onDeleteSuccess = () => {
    setDeleteAuditModalState(undefined);
    refetchAudits();
  };

  const { deleteAudit, isAuditDeleteLoading } = useAuditDeleteMutate(onDeleteSuccess);
  const { createAudit, isAuditLoading } = useAuditMutation(onSuccess);
  const formik = useFormik<any>({
    initialValues: getAuditFormObject('defaultValue'),
    validationSchema: toFormikValidationSchema(z.object(getAuditFormObject('validation'))),
    validateOnBlur: false,
    onSubmit: (values) => {
      createAudit({ auditState: values, supplierId: supplierId! });
    },
  });

  const handleOnRowClick = useCallback(
    (row: Audit) => {
      setSelectedAudit(audits?.find((item) => item.audit === row.audit) as Audit);
    },
    [audits, setSelectedAudit],
  );

  const columns = useMemo(
    () => [
      ...BASE_COLUMNS,
      columnHelper.menu({
        items: (
          <>
            <TableMenuItem onClick={handleOnRowClick}>View documents</TableMenuItem>
            <TableMenuItem isDisabled={() => !isAuditEditable(supplier.status)} onClick={setDeleteAuditModalState}>
              {isAuditDeleteLoading ? <QSpinner /> : 'Delete'}
            </TableMenuItem>
          </>
        ),
      }),
    ],
    [handleOnRowClick, setDeleteAuditModalState, isAuditDeleteLoading, supplier.status],
  );

  if (isAuditsLoading) {
    return (
      <QBox w="100%" textAlign="center" p={5}>
        <QSpinner />
      </QBox>
    );
  }

  return (
    <>
      {!audits?.filter((item) => item.status !== 'ARCHIVED')?.length ? (
        <QEmptyState
          title="Completed audits will appear here"
          subtitle="Record the outcome of prior audits with completion date, related files and notes."
        >
          <QEmptyStateButtonGroup>
            <QEmptyStatePrimaryButton>
              <QButton isDisabled={!isAuditEditable(supplier.status)} onClick={() => setShowAddAuditModal(true)}>
                Add an audit
              </QButton>
            </QEmptyStatePrimaryButton>
          </QEmptyStateButtonGroup>
        </QEmptyState>
      ) : (
        <QBox>
          <NextAudit
            key={supplier.audits?.length}
            audits={audits}
            policyLinks={policyLinks}
            supplierType={supplier?.supplierType as ConfigItem}
            riskLevel={supplier?.riskType as ConfigItem}
            auditTypes={auditTypeOptions as unknown as ConfigItem[]}
            supplier={supplier.supplier}
            isDetails={true}
          />
          <QBox textAlign="right" pb={3}>
            <QButton isDisabled={!isAuditEditable(supplier.status)} onClick={() => setShowAddAuditModal(true)}>
              Add Audit
            </QButton>
          </QBox>
          <DataProvider.Fixed data={audits}>
            <QDataTable columns={columns} />
          </DataProvider.Fixed>
          <QDrawer
            title={selectedAudit?.name as string}
            isOpen={!!selectedAudit}
            onClose={() => setSelectedAudit(null)}
          >
            {selectedAudit?.documents?.length ? (
              <QStack direction="column" spacing={4}>
                {selectedAudit.documents.map((document, index) => (
                  <ViewFileCard key={index} file={document.file} />
                ))}
              </QStack>
            ) : (
              <QAlert status="info" description="There are no documents available" />
            )}
          </QDrawer>
        </QBox>
      )}
      <QModal isOpen={!!deleteAuditModalState} onClose={() => setDeleteAuditModalState(undefined)}>
        <QModalHeader>
          <QText>Delete Audit</QText>
          <QCloseButton onClick={() => setDeleteAuditModalState(undefined)} />
        </QModalHeader>
        <QModalBody>Are you sure you would like to delete this audit?</QModalBody>
        <QModalActions>
          <QButton variant="outline" onClick={() => setDeleteAuditModalState(undefined)}>
            Cancel
          </QButton>
          <QButton
            onClick={() =>
              !!deleteAuditModalState && deleteAudit({ auditState: deleteAuditModalState, supplierId: supplierId! })
            }
            isLoading={isAuditDeleteLoading}
            data-cy="modal-delete-audit-button"
          >
            Delete audit
          </QButton>
        </QModalActions>
      </QModal>
      <QModal isOpen={showAddAuditModal} onClose={() => setShowAddAuditModal(false)} size="2xl">
        <QModalHeader>
          <QText>Add audit</QText>
          <QCloseButton
            onClick={() => {
              formik.resetForm();
              setShowAddAuditModal(false);
            }}
          />
        </QModalHeader>
        <QModalBody>
          <CreateAuditForm formik={formik} setAddAuditSubmitButtonDisabled={setAddAuditSubmitButtonDisabled} />
        </QModalBody>
        <QModalActions>
          <QButton
            variant="outline"
            onClick={() => {
              formik.resetForm();
              setShowAddAuditModal(false);
            }}
          >
            Cancel
          </QButton>
          <QButton
            onClick={() => formik.handleSubmit()}
            isDisabled={addAuditSubmitButtonDisabled}
            isLoading={isAuditLoading}
            data-cy="modal-add-audit-button"
          >
            Add audit
          </QButton>
        </QModalActions>
      </QModal>
    </>
  );
};

export default SupplierAudits;

const isAuditEditable = (status: any) => {
  // Adding audits are not allowed when supplier is in pending review status
  return status !== 'REVIEW_PENDING';
};
