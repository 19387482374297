import React from 'react';

import { QBox, QText, QTooltip } from '@qualio/ui-components';
import { DateTime } from 'luxon';
import { Audit } from 'types';
import { getLastAuditDueMillis } from 'utils';

type LastAuditV2Props = {
  audits: Audit[] | undefined;
};

const LastAudit: React.FC<LastAuditV2Props> = ({ audits }) => {
  const latestAuditDate = getLastAuditDueMillis(audits);
  if (!latestAuditDate) return <>--</>;
  const auditFilter = audits?.filter(
    (audit) =>
      (audit.endDate || audit.modified) ===
      DateTime.fromMillis(latestAuditDate).toUTC().toFormat("yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
  );

  const audit = auditFilter?.[0];

  const text = <QText>{DateTime.fromMillis(latestAuditDate).toFormat('MMM dd, yyyy')}</QText>;

  if (audit) {
    return (
      <QTooltip
        label={
          <QBox>
            Name: {audit.name} <br />
            Type: {audit.auditType?.title || '--'}
          </QBox>
        }
      >
        {text}
      </QTooltip>
    );
  }

  return text;
};
export default LastAudit;
