import React from 'react';

import { QEmptyState } from '@qualio/ui-components';
import { Outlet } from 'react-router-dom';
import { IncomingCompany } from 'types';

type PrivateRouteProps = {
  children?: React.ReactElement;
  userGroups: IncomingCompany['usergroups'];
  allowedUserGroups: IncomingCompany['usergroups'];
};

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, userGroups, allowedUserGroups }) => {
  const isUserNotAllowed = !userGroups?.some((r) => allowedUserGroups.includes(r));

  if (isUserNotAllowed) {
    return (
      <div data-cy="unauthorized-empty-state">
        <QEmptyState
          title="You don't have permission to access this"
          subtitle="You must be signed in as a Quality User to view and edit supplier policies. If you need access, contact your quality team."
        />
      </div>
    );
  }
  if (children) {
    return <>{children}</>;
  }

  return <Outlet />;
};

export default PrivateRoute;
