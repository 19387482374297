import MockAdapter from 'axios-mock-adapter';

import { api } from '../api';
import {
  usersResponse,
  supplierResponse,
  suppliersResponse,
  userResponse,
  configsResponse,
  policyLinksResponse,
  suppliersArchivedResponse,
} from './fixtures';

const mock = new MockAdapter(api, { delayResponse: 1000, onNoMatch: 'throwException' });

mock.onGet('/company/1/sqm/supplier').reply(200, suppliersResponse);

const supplierGetByStatusRegex = new RegExp(`/company/1/sqm/supplier/list/*`);
mock.onGet(supplierGetByStatusRegex).reply(200, suppliersArchivedResponse);

const supplierRegex = new RegExp(`/company/1/sqm/supplier/*`);
mock.onGet(supplierRegex).reply(200, supplierResponse);

mock.onPost('/company/1/sqm/supplier').reply(200, supplierResponse);

mock.onGet('/company/1/sqm/users').reply(200, usersResponse);

mock.onGet('/company/1/sqm/users/1').reply(200, userResponse);

mock.onGet('/company/1/sqm/config').reply(200, configsResponse);

mock.onGet('/company/1/sqm/config/policy/links').reply(200, policyLinksResponse);
