import * as z from 'zod';

export const SupplierStatus = z.enum(['REVIEW_PENDING', 'APPROVED', 'UNAPPROVED', 'REJECTED', 'DRAFT', 'ARCHIVED']);
export type SupplierStatus = z.infer<typeof SupplierStatus>;

const SupplierType = z.object({
  id: z.string().uuid().optional(),
  title: z.string().nonempty().optional(),
  description: z.string().nonempty().optional(),
  periodic_review: z.number().optional(),
});

export type SupplierType = z.infer<typeof SupplierType>;

//Formik Shapes
export const addSupplierFormikValidation = {
  supplier: z.string().optional(),
  supplierTypeOrRiskUpdated: z.boolean().optional(),
  name: z
    .string({ required_error: 'Please enter a supplier name' })
    .min(1, 'Supplier name can not be empty')
    .max(350, 'Supplier name can be no longer than 350 characters'),
  supplierType: z
    .object({
      label: z.string().optional(),
      value: z.string().optional(),
      payload: SupplierType.optional(),
    })
    .superRefine((args, ctx) => {
      if (!args.label) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please select a supplier type',
        });
      }
    }),
  website: z.union([z.string().optional(), z.literal('')]).optional(),
  intendedUse: z.string().max(4000, 'Field Intended use can be no longer than 4000 characters').optional(),
  sponsor: z
    .object({
      label: z.string().optional(),
      value: z.string().optional(),
      payload: z
        .object({
          id: z.number().optional(),
          fullName: z.string().nonempty().optional(),
          full_name: z.string().nonempty().optional(),
          email: z.string().email().optional(),
          userGroups: z.array(z.enum(['normal', 'quality', 'read_only'])).optional(),
        })
        .optional(),
    })
    .superRefine((args, ctx) => {
      if (!args.value) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please select a Sponsor',
        });
      }
    }),
  contactName: z.string().optional(),
  contactEmail: z.union([z.string().email().optional(), z.literal('')]).optional(),
  contactPhone: z.union([z.string().optional(), z.literal('')]).optional(),
  contactAddress: z.string().optional(),
  contactNotes: z.string().max(4500, 'Field Contact notes can be no longer than 4500 characters').optional(),
  riskType: z
    .object({
      id: z.string().optional(),
      title: z.string().optional(),
      description: z.string().optional(),
      periodic_review: z.number().optional(),
    })
    .superRefine((args, ctx) => {
      if (!args.id) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please select a risk type',
        });
      }
    }),
  documents: z
    .array(
      z.object({
        name: z.string(),
        required: z.boolean(),
        files: z.array(
          z.object({
            fileName: z.string().nonempty(),
            description: z.string().optional(),
            id: z.string().nonempty(),
            type: z.enum(['FILE', 'QUALIO_DOC']),
            file_id: z.number().optional(),
            code: z.string().optional(),
          }),
        ),
      }),
    )
    .superRefine((documents, ctx) => {
      const missingRequiredDocuments = documents.filter(
        (document: any) => document.required && document.files.length < 1,
      );
      if (missingRequiredDocuments?.length) {
        const missingDocumentNames = missingRequiredDocuments.map((doc) => doc.name).join(', ');

        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Atleast 1 supporting document is required for : ${missingDocumentNames}`,
        });
      }
    }),
  approvers: z
    .array(z.object({ label: z.string(), value: z.string(), userGroups: z.array(z.string()) }))
    .superRefine((approvers, ctx) => {
      if (!approvers?.length) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Approvers must be selected',
        });
      }
      const qualityUserExists = approvers.some((approver: any) => approver.userGroups.includes('quality'));
      if (!qualityUserExists) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Approves must contain at least one quality user',
        });
      }
    }),
  status: SupplierStatus.optional(),
};

export const addSupplierValidationShape = z.object(addSupplierFormikValidation);

export type AddSupplierFormikPayload = z.infer<typeof addSupplierValidationShape>;

// TODO: Simplify this
// Copied from sqm-be
const UpsertSupplierApiPayload = z.object({
  name: z.string().nonempty(),
  supplierType: SupplierType.optional(),
  riskType: z
    .object({
      id: z.string().uuid().optional(),
      title: z.string().nonempty().optional(),
      description: z.string().nonempty().optional(),
      periodic_review: z.number().optional(),
    })
    .optional(),
  documents: z
    .array(
      z.object({
        title: z.string().nonempty(),
        files: z.array(
          z.object({
            fileName: z.string().nonempty(),
            description: z.string().optional(),
            id: z.string().nonempty(),
            type: z.enum(['FILE', 'QUALIO_DOC']),
            file_id: z.number().optional(),
            code: z.string().optional(),
          }),
        ),
      }),
    )
    .optional(),
  website: z.string().nonempty().optional(),
  intendedUse: z.string().nonempty().optional(),
  sponsor: z
    .object({
      id: z.number(),
      fullName: z.string().nonempty(),
      full_name: z.string().nonempty().optional(),
      email: z.string().email(),
      userGroups: z.array(z.enum(['normal', 'quality', 'read_only'])),
    })
    .omit({ userGroups: true })
    .optional(),
  contactName: z.string().nonempty().optional(),
  contactEmail: z.string().email().optional(),
  contactPhone: z.string().nonempty().optional(),
  contactAddress: z.string().nonempty().optional(),
  contactNotes: z.string().nonempty().optional(),
  approvers: z
    .array(
      z
        .object({
          id: z.number(),
          fullName: z.string().nonempty(),
          full_name: z.string().nonempty().optional(),
          email: z.string().email(),
          userGroups: z.array(z.enum(['normal', 'quality', 'read_only'])),
        })
        .omit({ userGroups: true }),
    )
    .optional(),
  status: SupplierStatus.optional(),
  reason: z.string().nonempty().optional(),
  supplier: z.string().uuid().optional(),
  // preApprovalAuditId: z.string().nonempty().optional(),
});

export type UpsertSupplierApiPayload = z.infer<typeof UpsertSupplierApiPayload>;

export const addSupplierInitialValues: AddSupplierFormikPayload = {
  name: '',
  approvers: [],
  contactAddress: '',
  contactEmail: '',
  contactName: '',
  contactNotes: '',
  contactPhone: '',
  documents: [],
  intendedUse: '',
  riskType: {},
  sponsor: { value: '', label: '' },
  status: 'DRAFT',
  supplierType: { value: '', label: '', payload: { id: '', title: '', description: '', periodic_review: 0 } },
  website: '',
};

export const errorNameMapping: Record<string, any> = {
  name: {
    errorName: 'Name',
    step: 0,
  },
  approvers: {
    errorName: 'Approvers',
    step: 3,
  },
  contactAddress: {
    errorName: 'Contact address',
    step: 0,
  },
  contactEmail: {
    errorName: 'Contact email',
    step: 0,
  },
  contactName: {
    errorName: 'Contact name',
    step: 0,
  },
  contactNotes: {
    errorName: 'Contact notes',
    step: 0,
  },
  contactPhone: {
    errorName: 'Contact phone',
    step: 0,
  },
  documents: {
    errorName: 'Documents',
    step: 2,
  },
  intendedUse: {
    errorName: 'Intended use',
    step: 0,
  },
  riskType: {
    errorName: 'Risk type',
    step: 1,
  },
  sponsor: {
    errorName: 'Sponsor',
    step: 0,
  },
  supplierType: {
    errorName: 'Supplier type',
    step: 0,
  },
  website: {
    errorName: 'Website',
    step: 0,
  },
};
