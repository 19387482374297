export const routes = {
  supplierList: '',
  supplierDetails: {
    root: 'supplier',
    detailsTab: 'details',
    supportingDocumentsTab: 'documents',
    auditsTab: 'audits',
  },
  addSupplier: 'new',
  editSupplier: 'edit',
  policy: {
    root: 'supplier-policy',
    documents: 'documents',
    audits: 'audit',
  },
  policyConfiguration: {
    root: 'settings',
    supplierTypes: 'supplier',
    riskLevels: 'risk',
    documentTypes: 'document',
    auditTypes: 'audit',
  },
};
