import React, { useState } from 'react';

import { QButton, QCenter, QSpinner, QText } from '@qualio/ui-components';
import { useTitleResolver } from 'hooks';
import { maybePluralize } from 'lib';
import { ConfigTypeOptions } from 'v2types';

import { StyledSupplierPolicyCell } from '../styles/SupplierPolicy.styles';
import SupplierPolicyDrawer from './PolicyDrawer';

interface DataRow {
  title: string;
  id?: string;
}
interface SupplierPolicyCellProps {
  view: string;
  row: DataRow;
  column: any;
  policyConfigIndex: string;
  policyConfig: any;
  policyConfigFromAPI: any;
  documentTypes: ConfigTypeOptions[];
  auditTypes: ConfigTypeOptions[];
  setPolicyConfig: any;
  savePolicy: any;
}
const SupplierPolicyCell: React.FC<SupplierPolicyCellProps> = ({
  row,
  column,
  view,
  documentTypes,
  auditTypes,
  setPolicyConfig,
  policyConfigIndex,
  policyConfig,
  policyConfigFromAPI,
  savePolicy,
}) => {
  const [loading, setLoading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const viewData = policyConfigFromAPI?.linkData?.filter((l: any) => l.linkType === view.toLowerCase());

  const { resolveTitle } = useTitleResolver(documentTypes, auditTypes);

  if (loading)
    return (
      <QCenter>
        <QSpinner />
      </QCenter>
    );
  return (
    <StyledSupplierPolicyCell data-cy={`${row.title}-${column.data.title}-cell`} key={policyConfigIndex}>
      <div className="policy-cell-container">
        <QButton
          data-cy={`${row.title}-${column.data.title}-edit-button`}
          {...{ className: 'edit-button' }}
          variant="link"
          rightIcon={'Edit2'}
          onClick={() => setIsDrawerOpen(true)}
        >
          Edit
        </QButton>

        {!viewData?.length && (
          <div className="empty-cell">
            {view === 'AUDITS' ? (
              <QText fontSize="sm">No audits required</QText>
            ) : (
              <QText fontSize="sm">No documents required</QText>
            )}
          </div>
        )}

        {viewData?.map((config: any, index: number) => (
          <div key={index} className="type-list">
            <QText fontSize="sm">
              {resolveTitle(config.linkData.id, view === 'AUDITS' ? 'audits' : 'documents')}&nbsp;
              {view === 'AUDITS' && !!config.linkData.periodic_review && (
                <span> every {maybePluralize(config.linkData.periodic_review, 'month')}</span>
              )}
              {view === 'AUDITS' && config.linkData.periodic_review === 0 && <span> once</span>}
            </QText>
          </div>
        ))}
      </div>
      <SupplierPolicyDrawer
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
        key={`${row.id}-${column.data.id}`}
        row={row}
        column={column}
        documentTypes={documentTypes}
        auditTypes={auditTypes}
        policyConfig={policyConfig}
        policyConfigIndex={`${row.id}-${column.data.id}`}
        setPolicyConfig={setPolicyConfig}
        savePolicy={savePolicy}
        setLoading={setLoading}
        loading={loading}
      />
    </StyledSupplierPolicyCell>
  );
};

export default SupplierPolicyCell;
