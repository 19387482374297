import React from 'react';

import { QAlert, QButton, QText, QBox, QButtonGroup, useCurrentUser, QTooltip } from '@qualio/ui-components';
import { DateTime } from 'luxon';
import { QualioUser, ChangeControl, ChangeControlReviewStatus, SupplierStatus } from 'types';

type ChangeControlAlertProps = {
  changeControl?: ChangeControl;
  supplierStatus: SupplierStatus;
  handleStatusChangeModal: any;
  sponsor?: QualioUser;
};
const ChangeControlAlerts: React.FC<ChangeControlAlertProps> = ({
  changeControl,
  supplierStatus,
  handleStatusChangeModal,
  sponsor,
}) => {
  const { userId } = useCurrentUser();
  const showConfirmationAlert = (): boolean => {
    return !!changeControl?.reviews?.some(
      (review) => review.reviewer.id === userId && review.status === ChangeControlReviewStatus.enum.PENDING,
    );
  };

  const getRejectionDescription = (review: any) => {
    return (
      <QBox display="flex" flexDirection="row" alignItems={'center'} justifyContent={'left'}>
        <QText weight="semibold" pr={3}>
          {supplierStatus === 'DRAFT' ? 'Approval previously declined' : 'Approval declined'}
        </QText>{' '}
        <QBox>
          <QTooltip label={review.reviewer.email}>
            <QText as="u">
              {review.reviewer.fullName} {'  '}
            </QText>
          </QTooltip>
          declined to approve this supplier on{' '}
          {DateTime.fromISO(review.reviewedAt as string).toLocaleString(DateTime.DATETIME_MED)} with the following
          reason :<QText>"{review.comment || `--`}".</QText>
        </QBox>
      </QBox>
    );
  };
  const showRejectionReason = () => {
    return (
      <>
        {changeControl?.reviews
          ?.filter((review) => review.status === 'REJECTED')
          .map((review, index) => (
            <QAlert
              status={supplierStatus === 'DRAFT' ? 'info' : 'error'}
              key={index}
              description={getRejectionDescription(review)}
            />
          ))}
      </>
    );
  };

  const getReviewDescription = (sponsor: any) => {
    return (
      <QBox display="flex" flexDirection="row" justifyContent="space-between" alignItems={'center'}>
        <QBox display="flex" flexDirection="row" alignItems={'center'}>
          <QText weight="semibold" pr={3}>
            Approval Request
          </QText>
          <QBox>
            <QTooltip label={sponsor.email}>
              <QText as="u">
                {sponsor.fullName} {'  '}
              </QText>
            </QTooltip>
            requested to approve this supplier.
          </QBox>
        </QBox>
        <QButtonGroup>
          <QButton
            data-cy="supplier-reject-button"
            variant="solid"
            isDestructive
            onClick={() => handleStatusChangeModal('REJECTED')}
          >
            Reject
          </QButton>
          <QButton
            data-cy="supplier-approve-button"
            variant="solid"
            onClick={() => handleStatusChangeModal('APPROVED')}
          >
            Approve
          </QButton>
        </QButtonGroup>
      </QBox>
    );
  };
  const showReviewOptions = () => {
    return <QAlert status="warning" description={getReviewDescription(sponsor)} />;
  };

  return showConfirmationAlert() && supplierStatus === 'REVIEW_PENDING' ? showReviewOptions() : showRejectionReason();
};

export default ChangeControlAlerts;
