import { useCurrentUser } from '@qualio/ui-components';
import qualioApi from 'api/qualio.api';

type UseQualioDocsQueryType = {
  getDocumentOptions: (inputValue: string, callback: (options: any) => void) => void;
};

export const useQualioDocsQuery = (): UseQualioDocsQueryType => {
  const { companyId } = useCurrentUser();

  const getDocumentOptions = async (inputValue: string, callback: (options: any) => void) => {
    try {
      await qualioApi.fuzzySearchQualioDocs(companyId, inputValue).then((qualioDocs) => {
        return callback(
          qualioDocs
            ?.filter((doc: any) => doc.status_id === 'effective')
            ?.map((doc: any) => ({
              label: `${doc.code} ${doc.title}`,
              value: doc.uuid,
              ...doc,
            })),
        );
      });
    } catch (e) {
      console.error(e);
    }
  };
  return { getDocumentOptions };
};
