import axios from 'axios';

export const api = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

export const medtechApi = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
  baseURL: process.env.REACT_APP_MEDTECH_API_BASE_URL,
  withCredentials: true,
});

export const fileApi = axios.create({
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

export { default as supplierApi } from './supplier.api';
