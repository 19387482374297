import React from 'react';

import {
  QDrawer,
  QFlex,
  QBox,
  QText,
  QHeading,
  QDivider,
  QIconButton,
  QAlert,
  QButton,
  QIcon,
  QButtonGroup,
  QStack,
  useToastProvider,
} from '@qualio/ui-components';
import { ReactComponent as SupplierTypeIcon } from 'assets/Bank.svg';
import { ReactComponent as RiskLevelIcon } from 'assets/Dashboard.svg';
import { useTitleResolver } from 'hooks';
import { maybePluralize } from 'lib';
import { HistoryLink, LoadingSpinner, SettingsBody } from 'v2components';
import { ConfigTypeOptions } from 'v2types';

import { StyledSelect } from '../styles/SupplierPolicy.styles';

interface DataRow {
  title: string;
  id?: string;
}
interface SupplierPolicyDrawerProps {
  view?: string;
  row: DataRow;
  column: any;
  policyConfigIndex: string;
  policyConfig: any;
  documentTypes: ConfigTypeOptions[];
  auditTypes: ConfigTypeOptions[];
  isDrawerOpen: boolean;
  setIsDrawerOpen: any;
  setPolicyConfig: any;
  savePolicy: any;
  loading: boolean;
  setLoading: any;
}
const SupplierPolicyDrawer: React.FC<SupplierPolicyDrawerProps> = ({
  row,
  column,
  documentTypes,
  auditTypes,
  policyConfig,
  isDrawerOpen,
  setIsDrawerOpen,
  setPolicyConfig,
  policyConfigIndex,
  savePolicy,
  loading,
  setLoading,
}) => {
  const { showToast } = useToastProvider();

  const { resolveTitle } = useTitleResolver(documentTypes, auditTypes);

  const currentList = (type: string) =>
    policyConfig.linkData
      ? policyConfig.linkData
          ?.filter((l: any) => l.linkType === type)
          ?.map((c: any) => c?.linkData?.value || c?.linkData?.id)
      : [];

  const documentTypeOptions = documentTypes
    ?.filter((documentType) => !currentList('documents').includes(documentType.payload?.id))
    ?.map((documentType) => ({
      label: documentType.payload?.title,
      value: documentType.payload?.id,
    }));
  const auditTypeOptions = auditTypes
    ?.filter((auditType) => !currentList('audits').includes(auditType.payload?.id))
    ?.map((auditType) => ({
      label: auditType.payload?.title,
      value: auditType.payload?.id,
    }));

  if (loading)
    return (
      <SettingsBody isEmptyState={true}>
        <LoadingSpinner />
      </SettingsBody>
    );

  const auditPeriodMessage = (months: number) => {
    return <>{maybePluralize(months, 'month')} from last audit</>;
  };

  const NoTypesFound: React.FC<{ text: string }> = ({ text }) => (
    <>
      <QIcon iconName={'AlertTriangle'} color="yellow.600" /> {text} types are not yet defined in your{' '}
      <HistoryLink to={`/settings/${text.toLocaleLowerCase()}-types`} style={{ color: '#0069FF' }}>
        supplier policy{' '}
      </HistoryLink>
      or you have selected all available options.
    </>
  );
  return (
    <QDrawer title={`Edit Policy`} isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
      <QStack direction="row" mb={4}>
        <QFlex direction="row" bgColor="blue.100" borderRadius={4} w={'100%'} p={2}>
          <QFlex align="center" pr={2}>
            <SupplierTypeIcon />
          </QFlex>
          <QBox>
            <QText fontSize="sm" color={'gray.500'}>
              Supplier type
            </QText>
            <QText fontSize="sm">{row.title}</QText>
          </QBox>
        </QFlex>
        <QFlex direction="row" bgColor="blue.100" borderRadius={4} w={'100%'} p={2}>
          <QFlex align="center" pr={2}>
            <RiskLevelIcon />
          </QFlex>
          <QBox>
            <QText fontSize="sm" color={'gray.500'}>
              Risk level
            </QText>
            <QText fontSize="sm">{column?.data?.title}</QText>
          </QBox>
        </QFlex>
      </QStack>
      <QDivider />
      <QHeading size="sm" mt={4}>
        Required documents
      </QHeading>
      <QText fontSize="sm" color="gray.500">
        The policy will require these documents to be added for a supplier to be approved.
      </QText>
      <br />
      {policyConfig?.linkData
        ?.filter((l: any) => l.linkType === 'documents')
        ?.map((config: any, index: number) => (
          <QBox key={index} className="type-list" data-cy="policy-document-list">
            <QText fontSize="sm">
              {resolveTitle(config.linkData.id, 'documents') || config.linkData.label || config.linkData.title}{' '}
              <QIconButton
                size="sm"
                iconName={'Trash2'}
                aria-label="delete"
                onClick={async () => {
                  await setPolicyConfig(row, column.data, policyConfigIndex, undefined, 'documents', 'delete', config);
                }}
              />
            </QText>
          </QBox>
        ))}
      {/* TODO https://qualio.atlassian.net/browse/SUP-1134 Change selects to Component library selects */}
      <StyledSelect
        aria-label="edit-supplier-policy-document-select"
        options={documentTypeOptions}
        placeholder="Add document type"
        value={[]}
        menuPlacement="bottom"
        onChange={async (e: any) => {
          await setPolicyConfig(row, column.data, policyConfigIndex, e, 'documents', 'add');
        }}
        noOptionsMessage={() => <NoTypesFound text="Document" />}
      />
      <QDivider />
      <QHeading size="sm" mt={4}>
        Audits
      </QHeading>
      <QText fontSize="sm" color="gray.500">
        These audits will be required for all suppliers of this type and risk level.
      </QText>
      <QBox>
        {policyConfig?.linkData
          ?.filter((l: any) => l.linkType === 'audits')
          ?.map((config: any, index: number) => (
            <QBox key={index} className="type-list audits" data-cy="policy-audit-list" mt={4} mb={4}>
              <QFlex direction={'row'} align="center">
                <QText fontSize="sm" mr="2" w="70px">
                  {resolveTitle(config.linkData.id, 'audits') || config.linkData.label || config.linkData.title}{' '}
                </QText>
                <QFlex flex="1" w="100%" ml={2}>
                  <QBox w="100%">
                    <StyledSelect
                      aria-label="edit-supplier-policy-frequency-select"
                      placeholder="Due every"
                      className="due-select"
                      value={[
                        {
                          label: !!config.linkData.periodic_review
                            ? auditPeriodMessage(config.linkData.periodic_review)
                            : 'Once',
                          value: config.linkData.periodic_review,
                        },
                      ]}
                      options={[
                        { label: 'Once', value: 0 },
                        ...[...Array(120).keys()].map((i: number) => ({
                          label: auditPeriodMessage(i + 1),
                          value: i + 1,
                        })),
                      ]}
                      onChange={async (e: any) => {
                        await setPolicyConfig(row, column.data, policyConfigIndex, e, 'audits', 'update', config);
                      }}
                    />
                  </QBox>
                </QFlex>

                <QIconButton
                  size="sm"
                  iconName={'Trash2'}
                  aria-label="delete"
                  onClick={async () => {
                    await setPolicyConfig(row, column.data, policyConfigIndex, undefined, 'audits', 'delete', config);
                  }}
                />
              </QFlex>
            </QBox>
          ))}
      </QBox>
      <QBox>
        <StyledSelect
          options={auditTypeOptions}
          aria-label="edit-supplier-policy-audit-type-select"
          placeholder="Add audit type"
          value={[]}
          menuPlacement="bottom"
          onChange={async (e: any) => {
            await setPolicyConfig(row, column.data, policyConfigIndex, e, 'audits', 'add');
          }}
          noOptionsMessage={() => <NoTypesFound text="Audit" />}
        />
      </QBox>
      <QDivider />
      <QAlert
        status="warning"
        description={
          'Changes you make to the supplier policy will affect all existing suppliers of this type and risk level. Review all affected suppliers and re-evaluate them if needed.'
        }
      />
      <QBox mt={4}>
        <QButtonGroup>
          <QButton
            data-cy={`${row.title}-${column.data.title}-cancel`}
            variant="outline"
            onClick={() => setIsDrawerOpen(false)}
          >
            Cancel
          </QButton>
          <QButton
            data-cy={`${row.title}-${column.data.title}-save`}
            onClick={async () => {
              try {
                setLoading(true);
                await savePolicy(policyConfigIndex);
                setLoading(false);
                setIsDrawerOpen(false);
                showToast({
                  status: 'success',
                  description: 'You may need to re-evaluate affected suppliers.',
                  title: 'Policy saved',
                });
              } catch (e) {
                console.error(e);
                showToast({
                  status: 'error',
                  description: 'Cannot save your policy.',
                  title: 'Failure',
                });
              }
            }}
          >
            Save
          </QButton>
        </QButtonGroup>
      </QBox>
    </QDrawer>
  );
};

export default SupplierPolicyDrawer;
