import { routes } from 'utils';

export const TABS = [
  {
    id: routes.supplierDetails.detailsTab,
    title: 'Details',
  },
  {
    id: routes.supplierDetails.supportingDocumentsTab,
    title: 'Supporting documents',
  },
  {
    id: routes.supplierDetails.auditsTab,
    title: 'Audits',
  },
];

export const ALLOWED_STATUSES_FOR_EDIT = ['DRAFT'];
export const ALLOWED_STATUSES_FOR_UNARCHIVE = ['ARCHIVED'];
export const ALLOWED_STATUSES_FOR_ARCHIVE = ['APPROVED', 'REJECTED', 'REVIEW_PENDING', 'DRAFT'];
export const ALLOWED_STATUSES_FOR_REVERT_TO_DRAFT = ['APPROVED', 'REJECTED', 'REVIEW_PENDING'];
