import React, { useState } from 'react';

import {
  QBox,
  QFormControl,
  QHeading,
  QInput,
  QSelect,
  QStack,
  QTextarea,
  QModal,
  QModalBody,
  QModalHeader,
  QText,
  QCloseButton,
  QModalActions,
  QButton,
  QAlert,
} from '@qualio/ui-components';
import { FormikProps } from 'formik';
import { useCompanyUsersQuery, useConfigsQuery } from 'hooks';
import { moveFilesToAdditionalDocuments } from 'lib/utils';
import { AddSupplierFormikPayload, ConfigTypeOptions } from 'v2types';

import WarningMessage from '../WarningMessage/WarningMessage';

type GeneralInfoFormProps = {
  formik: FormikProps<AddSupplierFormikPayload>;
  isDraft: boolean;
  handleStepChange: (stepIndex: number) => void;
};

export const GeneralInfoForm: React.FC<GeneralInfoFormProps> = ({ formik, isDraft, handleStepChange }) => {
  const { supplierTypeOptions, isConfigsLoading } = useConfigsQuery();
  const { usersOptions, isCompanyUsersLoading } = useCompanyUsersQuery();
  const [currentSupplierType, setCurrentSupplierType] = useState<ConfigTypeOptions>();
  const [showModal, setShowModal] = useState(false);

  const { handleChange, status, touched, errors, values, setFieldValue } = formik;
  const isFieldInvalid = (field: keyof AddSupplierFormikPayload): boolean => {
    return !!touched && !!touched[field] && !!errors[field];
  };

  const updateSupplierType = () => {
    setFieldValue('supplierType', currentSupplierType);
    setFieldValue('documents', moveFilesToAdditionalDocuments(values.documents));
    setFieldValue('supplierTypeOrRiskUpdated', true);
    setShowModal(false);
  };

  return (
    <QBox maxWidth="640px" mb={2}>
      <QHeading mb={6}>General info</QHeading>
      <QBox>
        {isDraft && errors.contactEmail && !(status === 'touched' && !!touched) ? (
          <QAlert
            status="warning"
            title="You seem to have some fields with invalid values."
            description={
              <QBox pt={2}>
                <QStack spacing={2}>
                  {Object.entries(errors) // Adding this to make future validations easier (if any)
                    .filter(([name]) => name === 'contactEmail')
                    .map(([name, error], index) => (
                      <WarningMessage
                        key={index}
                        name={name}
                        error={error as string}
                        handleStepChange={handleStepChange}
                      />
                    ))}
                </QStack>
              </QBox>
            }
          />
        ) : null}
      </QBox>
      <QStack spacing={4}>
        <QFormControl
          label="Supplier name*"
          isInvalid={isFieldInvalid('name')}
          error={errors?.name}
          helper="This field is required when sending for approval."
        >
          <QInput data-cy="supplier-name" name="name" onChange={handleChange} value={values.name} />
        </QFormControl>
        <QFormControl
          data-cy="supplier-type"
          label="Supplier type"
          isInvalid={isFieldInvalid('supplierType')}
          error={errors.supplierType as string}
          helper="This field is required when sending for approval."
        >
          <QSelect
            name="supplierType"
            onChange={(e: ConfigTypeOptions | null) => {
              if (values.documents.length) {
                if (e === null) {
                  return;
                }
                if (values.documents.length > 1 && values.documents[0].files.length) {
                  setCurrentSupplierType(e);
                  setShowModal(true);
                } else {
                  setFieldValue('supplierType', e);
                }
              } else {
                setFieldValue('supplierType', e);
              }
            }}
            value={values.supplierType?.value}
            isLoading={isConfigsLoading}
            options={supplierTypeOptions}
          />
        </QFormControl>
        <QFormControl label="Website" isInvalid={isFieldInvalid('website')}>
          <QInput data-cy="supplier-website" name="website" onChange={handleChange} value={values.website} />
        </QFormControl>
        <QFormControl label="Intended use" isInvalid={isFieldInvalid('intendedUse')} error={errors?.intendedUse}>
          <QTextarea
            data-cy="intended-use"
            name="intendedUse"
            onChange={(e) => handleChange({ ...e, target: e.currentTarget })}
            value={values.intendedUse}
          />
        </QFormControl>
        <QFormControl
          data-cy="supplier-owner"
          label="Sponsor"
          isInvalid={isFieldInvalid('sponsor')}
          error={errors.sponsor as string}
          helper="This field is required when sending for approval."
        >
          <QSelect
            name="sponsor"
            onChange={(e: ConfigTypeOptions | null) => {
              if (e === null) {
                return;
              }
              setFieldValue('sponsor', e);
            }}
            value={values.sponsor?.value}
            options={usersOptions}
            isLoading={isCompanyUsersLoading}
          />
        </QFormControl>
      </QStack>
      <QHeading size="sm" mt={6} mb={4}>
        Supplier's contact information
      </QHeading>
      <QStack spacing={4}>
        <QFormControl label="Name" isInvalid={isFieldInvalid('contactName')}>
          <QInput
            data-cy="supplier-contact-name"
            name="contactName"
            onChange={handleChange}
            value={values.contactName}
          />
        </QFormControl>
        <QFormControl label="Email" isInvalid={isFieldInvalid('contactEmail')}>
          <QInput
            data-cy="supplier-contact-email"
            name="contactEmail"
            onChange={handleChange}
            value={values.contactEmail}
          />
        </QFormControl>
        <QFormControl label="Phone" isInvalid={isFieldInvalid('contactPhone')}>
          <QInput
            data-cy="supplier-contact-phone"
            name="contactPhone"
            onChange={handleChange}
            value={values.contactPhone}
          />
        </QFormControl>
        <QFormControl label="Address" isInvalid={isFieldInvalid('contactAddress')}>
          <QInput
            data-cy="supplier-contact-address"
            name="contactAddress"
            onChange={handleChange}
            value={values.contactAddress}
          />
        </QFormControl>
        <QFormControl label="Notes" isInvalid={isFieldInvalid('contactNotes')} error={errors?.contactNotes}>
          <QTextarea
            data-cy="supplier-contact-notes"
            name="contactNotes"
            onChange={(evt) => handleChange({ ...evt, target: evt.currentTarget })}
            value={values.contactNotes}
          />
        </QFormControl>
      </QStack>
      <QModal onClose={() => setShowModal(false)} size="md" isOpen={showModal}>
        <QModalHeader>
          <QText>Change supplier type?</QText>
          <QCloseButton onClick={() => setShowModal(false)} />
        </QModalHeader>
        <QModalBody>
          <div data-cy="change-supplier-level-modal-text">
            Each risk level may require different documents. All documents will be moved to “Additional documents” when
            you save the supplier.
          </div>
        </QModalBody>
        <QModalActions>
          <QButton
            isDestructive
            onClick={() => setShowModal(false)}
            variant="outline"
            data-cy="cancel-supplier-type-change"
          >
            Cancel
          </QButton>
          <QButton onClick={updateSupplierType}>Change supplier type</QButton>
        </QModalActions>
      </QModal>
    </QBox>
  );
};
