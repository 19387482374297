import React from 'react';

import { QButton, QDivider, QEmptyState, QFlex, QHeading, QIcon, QSpacer, QStack, QText } from '@qualio/ui-components';

type ConfigurationTemplateProps = {
  template: any;
  name: string;
  handleUseTemplate: () => void;
  handleCreateFromScratch: () => void;
};

export const ConfigurationTemplate: React.FC<ConfigurationTemplateProps> = ({
  template,
  name,
  handleUseTemplate,
  handleCreateFromScratch,
}) => {
  return (
    <>
      <QEmptyState
        title="You haven't defined any review types yet"
        subtitle="Specify what review types will be used in the Policy. Use the suggested template and edit them to suit your needs or create your own."
      />
      <QStack direction="column" alignItems="center" spacing={4} pb={4}>
        <QText>Use suggested {name.toLowerCase()} definitions</QText>
        <QFlex>
          <QSpacer />
          <QStack
            spacing={6}
            bg="blue.50"
            p={4}
            border="1px"
            borderColor="blue.400"
            borderRadius="base"
            maxWidth="640px"
          >
            {template.map((item: any, index: number) => (
              <QFlex key={index} direction="column">
                <QFlex align="center">
                  <QIcon iconName="CheckCircle" color="blue.500" />
                  <QHeading ml={2} fontSize="xs" fontWeight="semibold">
                    {item.title}
                  </QHeading>
                </QFlex>
                <QText color="gray.500" fontSize="xs">
                  {item.description}
                </QText>
              </QFlex>
            ))}
          </QStack>
          <QSpacer />
        </QFlex>
        <QButton onClick={handleUseTemplate}>Use this template</QButton>
        <QFlex direction="row">
          <QDivider />
          or
          <QDivider />
        </QFlex>
        <QButton variant="outline" onClick={handleCreateFromScratch}>
          Create from scratch
        </QButton>
      </QStack>
    </>
  );
};
