import styled from 'styled-components';

export const StyledAnchorTag = styled.a`
  color: #343a40;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  text-decoration: none;
  cursor: pointer;
  :hover {
    text-decoration: none;
  }
  :focus {
    text-decoration: none;
  }
`;
