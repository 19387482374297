import * as z from 'zod';

import { QualioUser } from './qualioIdentity';

export const ChangeControlReviewStatus = z.enum(['APPROVED', 'REJECTED', 'PENDING']);
export const ChangeControlStatus = z.enum(['OPEN', 'CLOSED']);
export const ChangeControlType = z.enum(['APPROVAL', 'REVIEW']);

export const ChangeControlReview = z.object({
  status: ChangeControlReviewStatus,
  reviewer: QualioUser.omit({ userGroups: true }),
  comment: z.string().optional().nullable(),
  reviewedAt: z.string().optional().nullable(),
});

export const ChangeControl = z.object({
  changeControl: z.string().uuid(),
  type: ChangeControlType,
  status: ChangeControlStatus,
  reviews: z.array(ChangeControlReview).optional().nullable(),
  created: z.string().optional(),
  closed: z.string().optional().nullable(),
});

export type ChangeControl = z.infer<typeof ChangeControl>;
export type ChangeControlReview = z.infer<typeof ChangeControlReview>;
