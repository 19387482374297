import React from 'react';

import { QBox, QTabs } from '@qualio/ui-components';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { routes } from 'utils';

const TABS = [
  { id: routes.supplierList, title: 'Supplier' },
  { id: routes.policy.root, title: 'Policy' },
];

const ViewWithNavigation: React.FC = () => {
  const { pathname } = useLocation();

  const activeRouteId = (): string => {
    switch (true) {
      case pathname.includes('/supplier-policy'):
        return TABS[1].id;
      default:
        return TABS[0].id;
    }
  };
  const navigate = useNavigate();
  return (
    <>
      <QTabs tabs={TABS} onClick={(tabItem) => navigate(tabItem.id)} activeTabId={activeRouteId()} />
      <QBox pt={3}>
        <Outlet />
      </QBox>
    </>
  );
};

export default ViewWithNavigation;
