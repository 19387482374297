import styled from 'styled-components';

export const SpinnerContainer = styled.div<{ hasMargin: boolean }>`
  display: flex;
  justify-content: center;
  ${({ hasMargin }) => hasMargin && `margin: 40px 0px;`}
  span {
    height: 24px;
    width: 24px;
  }
`;
