import React from 'react';

import {
  QButton,
  QEmptyState,
  QEmptyStateButtonGroup,
  QEmptyStatePrimaryButton,
  QHeading,
} from '@qualio/ui-components';

const SupplierUpSellView: React.FC = () => {
  return (
    <>
      <QHeading size="lg">Suppliers</QHeading>
      <div data-cy="ga-upsell">
        <QEmptyState
          title="Upgrade for access to Suppliers"
          subtitle="Your current Qualio plan does not include the new Suppliers feature. Contact your Customer Success representative to find out how to upgrade."
        >
          <QEmptyStateButtonGroup>
            <QEmptyStatePrimaryButton>
              <QButton
                onClick={() =>
                  (window.location.href = 'https://docs.qualio.com/en/articles/6010307-suppliers-overview')
                }
              >
                Learn more about Suppliers
              </QButton>
            </QEmptyStatePrimaryButton>
          </QEmptyStateButtonGroup>
        </QEmptyState>
      </div>
    </>
  );
};

export default SupplierUpSellView;
