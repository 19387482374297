import React from 'react';

import { AnalyticsProvider } from '@qualio/ui-components';

interface GlobalProviderProps {
  children?: React.ReactNode;
  analytics: SegmentAnalytics.AnalyticsJS;
}

const GlobalProvider: React.FC<GlobalProviderProps> = ({ children, analytics }) => {
  return <AnalyticsProvider analytics={analytics}>{children}</AnalyticsProvider>;
};

export default GlobalProvider;
