import { useState } from 'react';

import { useCurrentUser } from '@qualio/ui-components';
import documentApi from 'api/document.api';
import { File } from 'providers';

type UseUploadDocumentType = {
  isLoading: boolean;
  uploadDocument: (e: any) => void;
};

export const useUploadDocument = (handleUpdateDocument: (file: File) => void): UseUploadDocumentType => {
  const [isLoading, setIsLoading] = useState(false);
  const { companyId } = useCurrentUser();

  const uploadDocument = async (e: any) => {
    const file = e.target?.files[0];
    setIsLoading(true);
    try {
      const requestIdPayload: any = await documentApi.uploadDocument(companyId, file);
      handleUpdateDocument({ fileName: file.name, id: requestIdPayload.id, type: 'FILE' });
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  return { isLoading, uploadDocument };
};
