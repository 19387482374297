import React from 'react';

import { QSpinner, QCenter } from '@qualio/ui-components';
import { useConfigsQuery, usePoliciesQuery } from 'hooks';
import { preparePolicyLinksV2 } from 'lib/utils';
import { Outlet, useOutletContext } from 'react-router-dom';

import { PolicyHeader } from './components';

export const SupplierPolicyView: React.FC = () => {
  const {
    isConfigsLoading,
    supplierTypeOptions,
    riskTypeOptions,
    auditTypeOptions,
    documentTypeOptions,
    refetchConfigs,
  } = useConfigsQuery();

  const { isPolicyLoading, policyLinks, refetchPolicies } = usePoliciesQuery(isConfigsLoading);

  return (
    <>
      <PolicyHeader />
      {isConfigsLoading || isPolicyLoading ? (
        <QCenter p={5}>
          <QSpinner />
        </QCenter>
      ) : (
        <Outlet
          context={{
            isConfigsLoading,
            supplierTypeOptions,
            riskTypeOptions,
            auditTypeOptions,
            documentTypeOptions,
            refetchConfigs,
            policyConfigFromAPI: preparePolicyLinksV2(policyLinks),
            policyConfig: preparePolicyLinksV2(policyLinks),
            refetchPolicies,
          }}
        />
      )}
    </>
  );
};

export type OutletSupplierPolicyType = {
  isConfigsLoading: boolean;
  supplierTypeOptions: any;
  riskTypeOptions: any;
  auditTypeOptions: any;
  documentTypeOptions: any;
  configs: any;
  refetchConfigs: () => void;
  policyConfigFromAPI: any;
  policyConfig: any;
  refetchPolicies: () => void;
};

export const useRouterOutletSupplierPolicy = (): OutletSupplierPolicyType => {
  return useOutletContext<OutletSupplierPolicyType>();
};
