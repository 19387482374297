import * as React from 'react';

import { QSpinner } from '@qualio/ui-components';

import { SpinnerContainer } from './LoadingSpinner.styles';

type LoadingSpinnerProps = {
  hasMargin?: boolean;
};

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ hasMargin = true }) => {
  return (
    <SpinnerContainer hasMargin={hasMargin} data-cy="loading-spinner">
      <QSpinner />
    </SpinnerContainer>
  );
};

export default LoadingSpinner;
