import * as z from 'zod';

export const postConfigFormikValidation = z
  .array(
    z
      .object({
        title: z.string().optional(),
        description: z.string().optional(),
      })
      .refine(
        ({ title }) => {
          return title !== undefined;
        },
        {
          message: 'Please enter a name',
          path: ['title'],
        },
      )
      .refine(
        ({ title }) => {
          return title?.toLowerCase() !== 'additional documents';
        },
        {
          message: 'The title field cannot be additional documents',
          path: ['title'],
        },
      ),
  )
  .superRefine((docs, ctx) => {
    const titleIndexArrMap: { [key: string]: number[] } = {};
    docs.forEach((doc, index) => {
      if (doc.title) {
        const title = doc.title.toLowerCase();
        titleIndexArrMap[title] = titleIndexArrMap[title] || [];
        titleIndexArrMap[title].push(index);
      }
    });
    const indexWithDuplicates = Object.values(titleIndexArrMap)
      .filter((indexArr) => indexArr.length > 1)
      .flat();
    indexWithDuplicates.forEach((index) => {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Cannot use duplicate title',
        path: [index, 'title'],
      });
    });
  });
