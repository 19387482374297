import React, { useEffect, useState, Dispatch, useMemo, useCallback } from 'react';

import {
  QBox,
  QButton,
  QButtonGroup,
  QHeading,
  QInput,
  QMenuButton,
  QSelect,
  QStack,
  QDataTable,
  QSelectItem,
  useToastProvider,
  QSwitch,
  useAnalytics,
  QSelectPlaceholder,
  QText,
  useCurrentUser,
  createQColumnHelper,
  DataProvider,
  Filtering,
} from '@qualio/ui-components';
import { pdf } from '@react-pdf/renderer';
import supplierApi from 'api/supplier.api';
import { saveAs } from 'file-saver';
import { useConfigsQuery, useSupplierSpreadsheetExportMutation } from 'hooks';
import { downloadCSV, SUPPLIER_STATUS } from 'lib';
import { useQuery } from 'react-query';
import { useNavigate, Link } from 'react-router-dom';
import { Audit, Supplier, SupplierSettingOption } from 'types';
import { getNextAuditDue, NextAuditDueInfo, routes } from 'utils';
import { ASLExportDocument } from 'v2components';
import { ConfigItem } from 'v2types';

import { EmptyState } from './components/EmptyState';
import ImportModal from './components/ImportModal';
import LastAudit from './components/LastAudit';
import NextAuditDue from './components/NextAuditDue';

type SupplierListProps = {
  showOnlyArchived: boolean;
  setShowOnlyArchived: Dispatch<boolean>;
  approvedSuppliers: Supplier[] | undefined;
  refetchSuppliers: () => void;
};

const columnHelper = createQColumnHelper<Supplier>();

const SUPPLIER_STATUS_OPTIONS = Object.keys(SUPPLIER_STATUS)
  .filter((key) => key !== 'ARCHIVED')
  .map((key) => ({
    label: SUPPLIER_STATUS[key].text,
    value: key,
  }));

export const FILTER_DEFINITIONS = {
  supplierType: {
    label: 'Supplier Type',
    schema: Filtering.schemas.StringSchema(),
  },
  status: {
    label: 'Status',
    schema: Filtering.schemas.StringSchema(),
  },
  riskType: {
    label: 'Risk',
    schema: Filtering.schemas.StringSchema(),
  },
} as const;

const SupplierList: React.FC<SupplierListProps> = ({
  showOnlyArchived,
  setShowOnlyArchived,
  approvedSuppliers,
  refetchSuppliers,
}) => {
  const { data: suppliers } = DataProvider.useDataProvider<Supplier>();
  const navigate = useNavigate();
  const user = useCurrentUser();
  const { isConfigsLoading, configs } = useConfigsQuery();

  const { companyId } = useCurrentUser();
  const [supplierTypeOptions, setSupplierTypeOptions] = useState<SupplierSettingOption[] | []>([]);
  const [supplierRiskLevelOptions, setSupplierRiskLevelOptions] = useState<SupplierSettingOption[] | []>([]);
  const [auditTypes, setAuditTypes] = useState<ConfigItem[]>();
  const [isImportModalOpen, setIsImportModalOpen] = useState<boolean>(false);
  const { showToast } = useToastProvider();
  const { isExportSupplierSpreadsheetLoading, exportSupplierSpreadsheetMutate } =
    useSupplierSpreadsheetExportMutation();
  const analytics = useAnalytics();

  const { data: policyLinks } = useQuery('supplierPolicyLinks', () => supplierApi.getPolicyLinks(companyId), {
    enabled: isConfigsLoading,
    onError: () => {
      showToast({
        title: 'Error',
        description: 'Cannot retrieve policy links',
        status: 'error',
      });
    },
  });

  // Pre-calculate the next audit due info for each supplier. We do this in advance
  // so that we can support sorting by the next audit due date in the table without
  // having to double-calculcate this info for each row.
  const nextAuditDues: Record<string, NextAuditDueInfo | null> = useMemo(
    () =>
      suppliers?.reduce(
        (acc, s) => ({
          ...acc,
          [s.supplier ?? 'undefined']: getNextAuditDue(
            s.audits,
            s.supplierType as ConfigItem,
            policyLinks,
            s.riskType as ConfigItem,
            auditTypes,
            s.supplier,
            false,
          ),
        }),
        {} as Record<string, NextAuditDueInfo | null>,
      ) ?? {},
    [suppliers, policyLinks, auditTypes],
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        cell: ({ row: { original: s } }: { row: { original: Supplier } }) => {
          return (
            <Link to={`${routes.supplierDetails.root}/${s.supplier}/${routes.supplierDetails.detailsTab}`}>
              {s.name}
            </Link>
          );
        },
        meta: {
          minWidth: '20%',
          weight: 2,
        },
      },
      columnHelper.text((s) => s.supplierType?.title ?? '', { header: 'Type', minWidth: '130px' }),
      columnHelper.status((s) => SUPPLIER_STATUS[s.status ?? ''].text ?? '', {
        id: 'status',
        header: 'Status',
        width: '150px',
        statuses: {
          Approved: 'green',
          Pending: 'blue',
          Rejected: 'red',
          Archived: 'yellow',
          '*': 'gray',
        },
      }),
      columnHelper.text((s) => s.riskType?.title ?? '', { id: 'riskType', header: 'Risk', minWidth: '80px' }),
      columnHelper.text((s) => s.sponsor?.fullName ?? s.sponsor?.full_name ?? '', {
        header: 'Sponsor',
        minWidth: '160px',
      }),
      columnHelper.date((s) => new Date(s.modified ?? ''), { header: 'Last modified', minWidth: '140px' }),
      {
        header: 'Last audit',
        accessorFn: (s: Supplier) => s.audits?.filter((audit) => audit.status !== 'ARCHIVED'),
        minWidth: '130px',
        cell: ({ getValue }: { getValue: () => Audit[] }) => <LastAudit audits={getValue()} />,
      },
      {
        header: 'Due in',
        // NOTE: We ensure the accessorFn returns a date so that we can correctly sort by it.
        accessorFn: (s: Supplier): Date | undefined => {
          const info = nextAuditDues[s.supplier ?? 'undefined'];
          return info ? new Date(info.dueAtISO) : undefined;
        },
        minWidth: '130px',
        cell: ({ row: { original: s } }: { row: { original: Supplier } }) => {
          if (s.status === 'ARCHIVED') {
            return '--';
          }
          return <NextAuditDue nextDueInfo={nextAuditDues[s.supplier ?? 'undefined']} supplier={s.supplier} />;
        },
      },
    ],
    [nextAuditDues],
  );

  const MENU_ITEMS = [
    {
      id: 'import',
      label: 'Import',
      handler: () => {
        setIsImportModalOpen(true);
      },
    },
    {
      id: 'exportAsl',
      label: 'Export ASL',
      handler: async () => {
        if (!approvedSuppliers?.length) {
          showToast({
            title: 'Error',
            description: 'No approved suppliers to export',
            status: 'error',
          });
          return;
        }
        const document = await pdf(
          <ASLExportDocument
            suppliers={approvedSuppliers as Supplier[]}
            currentUser={user}
            policyLinks={policyLinks}
            auditTypes={auditTypes}
          />,
        ).toBlob();
        saveAs(document, 'Approved_Supplier_List.pdf');
      },
    },
  ];

  const GRID_MENU_ITEMS = useMemo(
    () => [
      {
        'data-cy': 'csv-export-button',
        id: 'exportCurrentView',
        label: 'Export current view to CSV',
        handler: () => {
          analytics.track('Suppliers | Export Supplier List as CSV');
          downloadCSV(
            suppliers?.map((supplier) => ({
              ...supplier,
              ...getNextAuditDue(
                supplier.audits,
                supplier?.supplierType as ConfigItem,
                policyLinks,
                supplier?.riskType as ConfigItem,
                auditTypes,
                supplier.supplier,
              ),
            })) ?? [],
          );
        },
      },
      {
        'data-cy': 'xlsx-export-button',
        id: 'exportSpreadsheet',
        label: 'Export full view to XLSX',
        handler: () =>
          suppliers &&
          suppliers.length > 0 &&
          exportSupplierSpreadsheetMutate(suppliers.filter((item) => !!item.supplier).map((item) => item.supplier!)),
      },
    ],
    [suppliers, exportSupplierSpreadsheetMutate, policyLinks, auditTypes, analytics],
  );

  useEffect(() => {
    if (isConfigsLoading && isExportSupplierSpreadsheetLoading) {
      return;
    }

    const supplierTypes = configs?.find((item) => item.type === 'Type')?.options || [];
    const riskLevels = configs?.find((item) => item.type === 'Risk')?.options || [];

    setAuditTypes(configs?.find((item) => item.type === 'Audit')?.options);
    setSupplierTypeOptions(
      supplierTypes?.map((supplierType) => ({ label: supplierType.title, value: supplierType.id })),
    );
    setSupplierRiskLevelOptions(riskLevels?.map((riskLevel) => ({ label: riskLevel.title, value: riskLevel.id })));
  }, [configs, isConfigsLoading, isExportSupplierSpreadsheetLoading]);

  const { filters, setFilters, searchTerm, setSearchTerm, removeFilter } =
    Filtering.useFiltering<typeof FILTER_DEFINITIONS>();

  const updateFilters = useCallback(
    (key: keyof typeof FILTER_DEFINITIONS, value: string | undefined) => {
      if (value) {
        setFilters({ [key]: value });
      } else {
        removeFilter(key);
      }
    },
    [removeFilter, setFilters],
  );

  const showEmptyState = useShowEmptyState();

  return (
    <>
      <QBox my={4}>
        <QBox display="flex" flexDirection="row" justifyContent="space-between">
          <QHeading size="lg" as="h1">
            Suppliers
          </QHeading>
          <QButtonGroup data-cy="import-export-add-supplier-button-group">
            <QMenuButton
              items={MENU_ITEMS}
              buttonLabel="options"
              variant="icon"
              onItemClick={(clickedItem) => MENU_ITEMS.find((item) => clickedItem.id === item.id)?.handler()}
            />
            <QButton variant="solid" onClick={() => navigate(routes.addSupplier)} data-cy="add-supplier-button">
              Add supplier
            </QButton>
          </QButtonGroup>
        </QBox>
        <QBox pt={1} mt={5}>
          <QBox w={['100%', '100%', 'calc(25% - 12px)']} display="flex" flexDirection="column" mb={4}>
            <QInput
              data-cy="search-suppliers"
              iconLeftName="Search"
              placeholder="Search name"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </QBox>
          <QStack spacing={4} direction={['column', 'column', 'row']} alignItems="flex-start">
            <QBox w="100%">
              <QSelect
                aria-label="type-select"
                value={filters.supplierType?.value}
                isClearable={true}
                options={supplierTypeOptions as QSelectItem[]}
                onChange={(e) => updateFilters('supplierType', e?.value ?? '')}
              >
                <QSelectPlaceholder>
                  <QText>Select supplier type</QText>
                </QSelectPlaceholder>
              </QSelect>
            </QBox>
            <QBox w="100%">
              <QSelect
                aria-label="status-select"
                value={filters.status?.value}
                isClearable={true}
                options={SUPPLIER_STATUS_OPTIONS}
                onChange={(e: any) => updateFilters('status', e?.value || undefined)}
              >
                <QSelectPlaceholder>
                  <QText>Select status</QText>
                </QSelectPlaceholder>
              </QSelect>
            </QBox>
            <QBox w="100%">
              <QSelect
                aria-label="risk-select"
                value={filters.riskType?.value}
                isClearable={true}
                options={supplierRiskLevelOptions as QSelectItem[]}
                onChange={(e: any) => updateFilters('riskType', e?.value || undefined)}
              >
                <QSelectPlaceholder>
                  <QText>Select risk type</QText>
                </QSelectPlaceholder>
              </QSelect>
            </QBox>
            <QBox w="100%" display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center">
              <QSwitch
                id="archived-only"
                isChecked={showOnlyArchived}
                onChange={() => setShowOnlyArchived(!showOnlyArchived)}
                mr={2}
                mt={0.5}
                leftLabel="Show only archived"
              />
              <div data-cy="grid-menu">
                <QMenuButton
                  items={GRID_MENU_ITEMS}
                  buttonLabel="options"
                  variant="icon"
                  onItemClick={(clickedItem) => GRID_MENU_ITEMS.find((item) => clickedItem.id === item.id)?.handler()}
                />
              </div>
            </QBox>
          </QStack>
        </QBox>
        <QBox mt={5}>
          {showEmptyState ? (
            <EmptyState refetchSuppliers={refetchSuppliers} />
          ) : (
            <QDataTable columns={columns}></QDataTable>
          )}
        </QBox>
      </QBox>
      <ImportModal isOpen={isImportModalOpen} setIsOpen={setIsImportModalOpen} refetchSuppliers={refetchSuppliers} />
      <div id="react-pfd" />
    </>
  );
};

export default SupplierList;

const useShowEmptyState = (): boolean => {
  const { data, isLoading } = DataProvider.useDataProvider();
  const { filters, searchTerm } = Filtering.useFiltering();
  // Don't show empty state if:
  // - we are loading
  // - we have some data
  // - we have active filters
  if (isLoading || (data && data.length > 0) || !!searchTerm || Object.values(filters).some((f) => !!f.value)) {
    return false;
  }
  return true;
};
