import React from 'react';

import { Route, Routes } from 'react-router-dom';
import { SuppliersInsufficientPermissionsView, VideoModal } from 'v2views';

const InsufficientPermissionRoutes: React.FC = () => {
  return (
    <Routes>
      <Route
        path="*"
        element={<SuppliersInsufficientPermissionsView />}
        children={
          <Route
            path="product-video"
            element={
              <VideoModal
                title="Optimizing supply chain quality with Qualio"
                videoUrl="https://www.youtube.com/embed/q2Kmug0o-vk"
                findOutMoreUrl="https://docs.qualio.com/en/articles/6010307-suppliers-overview"
              />
            }
          />
        }
      />
    </Routes>
  );
};

export default InsufficientPermissionRoutes;
