import { useEffect, useState } from 'react';

import { useCurrentUser, useToastProvider } from '@qualio/ui-components';
import { supplierApi } from 'api';
import { useQuery, useMutation, UseMutateFunction, UseQueryOptions } from 'react-query';
import { Config, ConfigTypeOptions } from 'v2types';
import { TABS } from 'v2views';

type UseConfigsQueryType = {
  configs?: Config[];
  isConfigsLoading: boolean;
  refetchConfigs: () => void;
  auditTypeOptions: ConfigTypeOptions[];
  supplierTypeOptions: ConfigTypeOptions[];
  riskTypeOptions: ConfigTypeOptions[];
  documentTypeOptions: ConfigTypeOptions[];
};

export const useConfigsQuery = (queryOptions?: Pick<UseQueryOptions, 'enabled'>): UseConfigsQueryType => {
  const { companyId } = useCurrentUser();
  const [auditTypeOptions, setAuditTypeOptions] = useState<ConfigTypeOptions[]>([{ label: '', value: '' }]);
  const [supplierTypeOptions, setSupplierTypeOptions] = useState<ConfigTypeOptions[]>([{ label: '', value: '' }]);
  const [riskTypeOptions, setRiskTypeOptions] = useState<ConfigTypeOptions[]>([{ label: '', value: '' }]);
  const [documentTypeOptions, setDocumentTypeOptions] = useState<ConfigTypeOptions[]>([{ label: '', value: '' }]);

  const {
    data: configs,
    isLoading: isConfigsLoading,
    refetch: refetchConfigs,
  } = useQuery(`supplier-configs-${companyId}`, () => supplierApi.listConfigs(companyId), queryOptions);

  useEffect(() => {
    const auditTypes = configs?.find((item) => item.type === 'Audit')?.options;
    if (auditTypes?.length) {
      setAuditTypeOptions(
        auditTypes.map(
          (auditType): ConfigTypeOptions => ({
            label: auditType.title || '',
            value: auditType.id || '',
            payload: auditType,
          }),
        ),
      );
    }

    const supplierTypes = configs?.find((item) => item.type === 'Type')?.options;
    if (supplierTypes?.length) {
      setSupplierTypeOptions(
        supplierTypes.map(
          (supplierType): ConfigTypeOptions => ({
            label: supplierType.title || '',
            value: supplierType.id || '',
            payload: supplierType,
          }),
        ),
      );
    }

    const riskTypes = configs?.find((item) => item.type === 'Risk')?.options;
    if (riskTypes?.length) {
      setRiskTypeOptions(
        riskTypes.map(
          (riskType): ConfigTypeOptions => ({
            label: riskType.title || '',
            value: riskType.id || '',
            payload: riskType,
          }),
        ),
      );
    }

    const documentTypes = configs?.find((item) => item.type === 'Document')?.options;
    if (documentTypes?.length) {
      setDocumentTypeOptions(
        documentTypes.map(
          (documentType): ConfigTypeOptions => ({
            label: documentType.title || '',
            value: documentType.id || '',
            payload: documentType,
          }),
        ),
      );
    }
  }, [configs]);

  return {
    configs,
    isConfigsLoading,
    refetchConfigs,
    auditTypeOptions,
    supplierTypeOptions,
    riskTypeOptions,
    documentTypeOptions,
  };
};

type UsePostConfigType = {
  mutateConfigs: UseMutateFunction<Config, unknown, Config, unknown>;
  isConfigsMutateLoading: boolean;
};

export const usePostConfig = (onSuccess: () => void): UsePostConfigType => {
  const { showToast } = useToastProvider();
  const { companyId } = useCurrentUser();

  const { mutate: mutateConfigs, isLoading: isConfigsMutateLoading } = useMutation(
    (config: Config) => supplierApi.postConfig(companyId, config),
    {
      onSuccess: async (data) => {
        const updatedConfig = TABS.find((item) => item.key === data.type)?.title || 'Configuration';
        showToast({ description: `${updatedConfig} was updated`, status: 'success' });
        onSuccess();
      },
      onError: (error: any) => {
        showToast({ description: error?.response?.data?.message ?? 'Configuration failed to update', status: 'error' });
      },
    },
  );

  return { mutateConfigs, isConfigsMutateLoading };
};
