import React from 'react';

import { Route, Routes } from 'react-router-dom';
import { routes } from 'utils';
import {
  PrivateRoute,
  SupplierAudits,
  SupplierDetails,
  SupplierDocuments,
  ViewWithNavigation,
  NotFound,
} from 'v2components';
import {
  AddSupplierView,
  SupplierDetailsView,
  SupplierListView,
  SupplierPolicyView,
  SupplierPolicyConfigurationView,
  Configuration,
  Policy,
} from 'v2views';

type DefaultRoutesProps = {
  userGroups: string[];
};

const ONLY_QUALITY_USERS = ['quality'];

const DefaultRoutes: React.FC<DefaultRoutesProps> = ({ userGroups }) => {
  return (
    <Routes>
      <Route path={routes.supplierList} element={<ViewWithNavigation />}>
        <Route path={routes.supplierList} element={<SupplierListView />} />
        <Route
          path={routes.policy.root}
          element={
            <PrivateRoute userGroups={userGroups} allowedUserGroups={ONLY_QUALITY_USERS}>
              <SupplierPolicyView />
            </PrivateRoute>
          }
        >
          <Route
            index
            element={
              <PrivateRoute userGroups={userGroups} allowedUserGroups={ONLY_QUALITY_USERS}>
                <Policy />
              </PrivateRoute>
            }
          />
          <Route
            path=":policyType"
            element={
              <PrivateRoute userGroups={userGroups} allowedUserGroups={ONLY_QUALITY_USERS}>
                <Policy />
              </PrivateRoute>
            }
          />
        </Route>
      </Route>
      <Route
        path={routes.policyConfiguration.root}
        element={
          <PrivateRoute userGroups={userGroups} allowedUserGroups={ONLY_QUALITY_USERS}>
            <SupplierPolicyConfigurationView />
          </PrivateRoute>
        }
      >
        <Route
          path=":configurationType"
          element={
            <PrivateRoute userGroups={userGroups} allowedUserGroups={ONLY_QUALITY_USERS}>
              <Configuration />
            </PrivateRoute>
          }
        />
      </Route>

      <Route path={routes.supplierList}>
        <Route path={routes.supplierDetails.root}>
          <Route path=":supplierId" element={<SupplierDetailsView />}>
            <Route path={routes.supplierDetails.detailsTab} element={<SupplierDetails />} />
            <Route path={routes.supplierDetails.supportingDocumentsTab} element={<SupplierDocuments />} />
            <Route path={routes.supplierDetails.auditsTab} element={<SupplierAudits />} />
          </Route>
        </Route>
        <Route path={routes.addSupplier} element={<AddSupplierView />} />
        <Route path={routes.editSupplier}>
          <Route path=":supplierId" element={<AddSupplierView />} />
        </Route>
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default DefaultRoutes;
