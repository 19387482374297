import React from 'react';

import { CurrentUser } from '@qualio/ui-components';
import { Document, Font, StyleSheet } from '@react-pdf/renderer';
import { Supplier } from 'types';
import { StaticAssetUrlFor } from 'utils';

import CoverPage from './components/CoverPage';
import SupplierListPage from './components/SupplierListPage';
import NotoSansMerged from './fonts/NotoSansMerged.ttf';

Font.register({
  family: 'NotoSansMerged',
  src: StaticAssetUrlFor(NotoSansMerged),
});

export const aslRootStyle = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
    padding: '30px 43px',
    fontFamily: 'NotoSansMerged',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  supplier: {
    margin: 12,
    fontSize: 14,
    flexDirection: 'row',
  },
});

interface ASLExportProps {
  currentUser: CurrentUser;
  suppliers: Supplier[];
  policyLinks: any;
  auditTypes: any;
}

const ASLExportDocument: React.FC<ASLExportProps> = ({ currentUser, suppliers, policyLinks, auditTypes }) => {
  return (
    <Document>
      <CoverPage currentUser={currentUser} />
      <SupplierListPage
        currentUser={currentUser}
        suppliers={suppliers}
        policyLinks={policyLinks}
        auditTypes={auditTypes}
      />
    </Document>
  );
};

export default ASLExportDocument;
