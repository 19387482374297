import React, { Dispatch, SetStateAction, useEffect } from 'react';

import { QBox, QMenuButton, QMenuItem, QSpinner } from '@qualio/ui-components';
import { useUploadDocument } from 'hooks';
import { File } from 'providers';

type AddDocumentCardProps = {
  handleDocumentAdded: (fileType: File['type'], file?: File) => void;
  fileInputIndex?: number;
  setAddAuditSubmitButtonDisabled?: Dispatch<SetStateAction<boolean>>;
};

const AddDocumentCard: React.FC<AddDocumentCardProps> = ({
  handleDocumentAdded,
  fileInputIndex = 0,
  setAddAuditSubmitButtonDisabled,
}) => {
  const { isLoading, uploadDocument } = useUploadDocument((file) => handleDocumentAdded('FILE', file));

  useEffect(() => {
    setAddAuditSubmitButtonDisabled && setAddAuditSubmitButtonDisabled(isLoading);
  }, [isLoading, setAddAuditSubmitButtonDisabled]);

  return (
    <QBox
      border="1px"
      borderColor="blackAlpha.200"
      borderRadius="base"
      pl={3}
      height="48px"
      display="flex"
      alignItems="center"
      data-cy="document-card"
    >
      {isLoading ? (
        <QSpinner />
      ) : (
        <QMenuButton variant="link" buttonLabel="Add document">
          <QMenuItem>
            <label htmlFor={`file-upload-${fileInputIndex}`}>Upload from computer</label>
            <input
              style={{ display: 'none' }}
              type="file"
              name={`file-upload-${fileInputIndex}`}
              id={`file-upload-${fileInputIndex}`}
              onChange={uploadDocument}
            />
          </QMenuItem>
          <QMenuItem onClick={() => handleDocumentAdded('QUALIO_DOC')}>Qualio doc</QMenuItem>
        </QMenuButton>
      )}
    </QBox>
  );
};

export default AddDocumentCard;
