import React from 'react';

import { CurrentUser } from '@qualio/ui-components';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { DateTime } from 'luxon';

type PageHeaderProps = {
  currentUser: CurrentUser;
};
export const pageHeaderStyle = StyleSheet.create({
  headerTitle: {
    fontSize: 12,
    fontWeight: 700,
    marginBottom: 4,
  },
  headerSubtitle: {
    fontSize: 7,
    fontWeight: 400,
  },
  bodyText: {
    fontSize: 6,
  },
});

const PageHeader: React.FC<PageHeaderProps> = ({ currentUser }) => {
  const date = DateTime.utc().toFormat('yyyy-LL-dd HH:mm (ZZZZ)');
  return (
    <View fixed>
      <Text style={pageHeaderStyle.headerTitle}>Approved Supplier List</Text>
      <Text style={pageHeaderStyle.headerSubtitle}>
        {currentUser.companyName} | Generated by {currentUser.fullName} on {date}
      </Text>
    </View>
  );
};

export default PageHeader;
