import React from 'react';

import { StyleSheet, Text, View } from '@react-pdf/renderer';

import { columnStyles } from './SupplierListPage';

const listHeaderStyle = StyleSheet.create({
  header: {
    marginTop: 24,
    paddingBottom: 8,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 8,
    fontWeight: 600,
    borderBottom: '1px solid black',
  },
});

const ListHeader: React.FC = () => {
  return (
    <View style={listHeaderStyle.header} fixed>
      <View style={columnStyles.column1}>
        <Text>Supplier Name</Text>
      </View>
      <View style={columnStyles.column2}>
        <Text>Type</Text>
      </View>
      <View style={columnStyles.column3}>
        <Text>Risk level</Text>
      </View>
      <View style={columnStyles.column4}>
        <Text>Intended use</Text>
      </View>
      <View style={columnStyles.column5}>
        <Text>Last audit</Text>
      </View>
      <View style={columnStyles.column6}>
        <Text>Audit due</Text>
      </View>
    </View>
  );
};

export default ListHeader;
