import { useState } from 'react';

import { useCurrentUser, useToastProvider } from '@qualio/ui-components';
import { supplierApi } from 'api';
import { Document } from 'providers';
import { QueryObserverResult, useQuery } from 'react-query';

import { useTitleResolver } from './useTitleResolver';

type UsePolicyLinksQuery = {
  policyDocuments: Document[];
  isLoading: boolean;
  isRefetching: boolean;
  refetchSupplierPolicyLinks: () => Promise<QueryObserverResult>;
};

export const usePolicyLinksQuery = (
  incDocuments: Document[],
  supplierTypeValue?: string,
  riskTypeValue?: string,
): UsePolicyLinksQuery => {
  const [documents, setDocuments] = useState<Document[]>([]);
  const { companyId } = useCurrentUser();
  const { showToast } = useToastProvider();

  const { resolveTitle, isLoading: isTitleResolverLoading } = useTitleResolver();

  const {
    isLoading,
    isRefetching,
    refetch: refetchSupplierPolicyLinks,
  } = useQuery('supplierPolicyLinks', () => supplierApi.getPolicyLinks(companyId), {
    enabled: !isTitleResolverLoading && !!supplierTypeValue && !!riskTypeValue,
    onSuccess: (data) => {
      const incomingDocuments = Array.isArray(incDocuments) ? [...incDocuments] : [];
      const filteredLink = data?.filter(
        (link: any) => link.supplierType.id === supplierTypeValue && link.riskLevel.id === riskTypeValue,
      );
      const requiredDocuments = filteredLink?.[0]?.links
        ?.filter(
          (link: any) =>
            link.supplierType.id === supplierTypeValue &&
            link.riskLevel.id === riskTypeValue &&
            link.linkType === 'documents',
        )
        .map((link: any) => ({
          name: resolveTitle(link.linkData.id, 'documents') || link.linkData.title,
          required: true,
          files: [],
        }));
      const combinedDocuments: Document[] = [];
      requiredDocuments?.forEach((requiredItem: any) => {
        const index = incomingDocuments.findIndex((incomingItem: any) => requiredItem.name === incomingItem.name);
        if (index >= 0) {
          combinedDocuments.push({
            ...incomingDocuments[index],
            required: true,
            files: incomingDocuments[index]?.files?.map((file) => ({
              ...file,
              fileName: file?.fileName,
              description: file?.description || '',
            })),
          });
          incomingDocuments.splice(index, 1);
        } else {
          combinedDocuments.push(requiredItem);
        }
      });
      if (incomingDocuments.length) {
        incomingDocuments.forEach((incomingItem) =>
          combinedDocuments.push({
            ...incomingItem,
            required: false,
            loading: false,
            files: incomingItem?.files?.map((file) => ({
              ...file,
              fileName: file?.fileName,
              description: file?.description || '',
            })),
          }),
        );
      }
      if (
        !combinedDocuments?.filter((item: Document) => item.name.toLowerCase().includes('additional document')).length
      ) {
        combinedDocuments.push({ name: 'Additional documents', required: false, files: [] });
      }
      setDocuments(combinedDocuments);
    },
    onError: () => {
      showToast({ description: 'Something went wrong fetching your required supporting documents', status: 'error' });
    },
  });

  return {
    policyDocuments: documents,
    isLoading: isLoading || isTitleResolverLoading,
    isRefetching,
    refetchSupplierPolicyLinks,
  };
};
