import React from 'react';

import { Text } from '@react-pdf/renderer';

const PageCounter: React.FC = () => {
  return (
    <Text
      style={{
        fontSize: 8,
      }}
      render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
    />
  );
};

export default PageCounter;
