import {
  AUDIT_TYPES_TEMPLATE,
  DOCUMENT_TYPES_TEMPLATE,
  RISK_TYPES_TEMPLATE,
  SUPPLIERS_TYPES_TEMPLATE,
} from './templates';

export const TABS: { id: string; title: string; dataObjectKey: string; key: string; template: any }[] = [
  {
    id: 'supplier',
    title: 'Supplier types',
    dataObjectKey: 'supplierTypeOptions',
    key: 'Type',
    template: SUPPLIERS_TYPES_TEMPLATE,
  },
  {
    id: 'risk',
    title: 'Risk levels',
    dataObjectKey: 'riskTypeOptions',
    key: 'Risk',
    template: RISK_TYPES_TEMPLATE,
  },
  {
    id: 'document',
    title: 'Document types',
    dataObjectKey: 'documentTypeOptions',
    key: 'Document',
    template: DOCUMENT_TYPES_TEMPLATE,
  },
  {
    id: 'audit',
    title: 'Audit types',
    dataObjectKey: 'auditTypeOptions',
    key: 'Audit',
    template: AUDIT_TYPES_TEMPLATE,
  },
];

export const activeRouteId = (pathname: string): string => {
  return TABS.find((tab) => pathname.includes(tab.id))?.id || 'supplier-types';
};

export const activeRouteDataKeyTitle = (
  pathname: string,
): { dataKey: string; title: string; id: string; key: string; template: any } => {
  const tab = TABS.find((tab) => pathname.includes(tab.id || 'supplier-types')) || TABS[0];
  return {
    dataKey: tab.dataObjectKey,
    title: tab.title,
    id: tab.id,
    key: tab.key,
    template: tab.template,
  };
};
