import React, { useState } from 'react';

import {
  QAlert,
  QBox,
  QButton,
  QCenter,
  QCloseButton,
  QFormControl,
  QHeading,
  QLink,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QRadio,
  QRadioGroup,
  QSpinner,
  QStack,
  QText,
} from '@qualio/ui-components';
import { FormikProps } from 'formik';
import { useConfigsQuery } from 'hooks';
import { moveFilesToAdditionalDocuments } from 'lib/utils';
import { routes } from 'utils';
import { AddSupplierFormikPayload, ConfigTypeOptions } from 'v2types';

type RiskAssessmentFormProps = {
  formik: FormikProps<AddSupplierFormikPayload>;
};

export const RiskAssessmentForm: React.FC<RiskAssessmentFormProps> = ({ formik }) => {
  const { values, setFieldValue, errors, touched } = formik;
  const { riskTypeOptions, isConfigsLoading } = useConfigsQuery();
  const [currentRiskType, setCurrentRiskType] = useState<ConfigTypeOptions>();
  const [showModal, setShowModal] = useState(false);

  const isFieldInvalid = (field: keyof AddSupplierFormikPayload) => {
    return !!touched && !!touched[field] && !!errors[field];
  };

  const updateRiskType = () => {
    setFieldValue('riskType', currentRiskType?.payload);
    setFieldValue('documents', moveFilesToAdditionalDocuments(values.documents));
    setFieldValue('supplierTypeOrRiskUpdated', true);
    setShowModal(false);
  };

  return (
    <QBox maxWidth="640px" mb={2}>
      <QHeading mb={2}>Risk assessment</QHeading>
      {isConfigsLoading ? (
        <QCenter p={5}>
          <QSpinner />
        </QCenter>
      ) : (
        <>
          {/* Risk levels are defined by the supplier policy. If you are unsure now you can come back to it later. Note that risk level is needed to assign the right supporting documents as required by the policy. */}
          <QText fontSize="sm" color="gray.500" pb={6}>
            Risk levels are defined by the{' '}
            <QLink isExternal href={`/sqm/${routes.policy.root}/${routes.policy.documents}`}>
              supplier policy
            </QLink>
            . If you are unsure now you can come back to it later. Note that risk level is needed to assign the right
            supporting documents as required by the policy.
          </QText>
          <QBox data-cy="risk-type-title">
            <QRadioGroup name="riskType" value={values.riskType?.id}>
              <QStack spacing={4}>
                {isFieldInvalid('riskType') ? <QAlert status="error" description={errors.riskType} /> : null}
                {!!riskTypeOptions &&
                  riskTypeOptions.map((riskType) => (
                    <QFormControl key={`riskType-${riskType.value}`} isInvalid={isFieldInvalid('riskType')}>
                      <QRadio
                        onChange={() => {
                          if (values.documents.length!) {
                            if (values.documents.length > 1 && values.documents[0].files.length) {
                              setCurrentRiskType(riskType);
                              setShowModal(true);
                            } else {
                              setFieldValue('riskType', riskType.payload);
                            }
                          } else {
                            setFieldValue('riskType', riskType.payload);
                          }
                        }}
                        value={riskType.value}
                      >
                        {riskType.label}
                      </QRadio>
                      <QText fontSize="sm" color="gray.500">
                        {riskType.payload?.description}
                      </QText>
                    </QFormControl>
                  ))}
              </QStack>
            </QRadioGroup>
          </QBox>
        </>
      )}

      <QModal onClose={() => setShowModal(false)} size="md" isOpen={showModal}>
        <QModalHeader>
          <QText>Change risk level?</QText>
          <QCloseButton onClick={() => setShowModal(false)} />
        </QModalHeader>
        <QModalBody>
          <div data-cy="change-risk-level-modal-text">
            Each risk level may require different documents. All documents will be moved to “Additional documents” when
            you save the supplier.
          </div>
        </QModalBody>
        <QModalActions>
          <QButton isDestructive onClick={() => setShowModal(false)} variant="outline">
            Cancel
          </QButton>
          <QButton onClick={updateRiskType} data-cy="change-risk-level">
            Change risk level
          </QButton>
        </QModalActions>
      </QModal>
    </QBox>
  );
};
