import { Payload, PolicyLink } from 'types';

import { makeResponse } from './generic';

export const policyLinksResponse: Payload<PolicyLink[]> = makeResponse([
  {
    company: '1',
    links: [
      {
        riskLevel: {
          id: '05a64fcf-ab32-45a3-93aa-8f5dc1fd3125',
          title: 'Critical',
        },
        supplierType: {
          id: 'f371aa59-f853-40bd-84b5-23e7024bfdb9',
          title: 'Manufacturer',
        },
        linkData: {
          id: '0524450d-0dee-44b3-b144-bd263eacd95f',
          title: 'Quality Agreement',
        },
        linkType: 'documents',
      },
      {
        riskLevel: {
          id: '05a64fcf-ab32-45a3-93aa-8f5dc1fd3125',
          title: 'Critical',
        },
        supplierType: {
          id: 'f371aa59-f853-40bd-84b5-23e7024bfdb9',
          title: 'Manufacturer',
        },
        linkData: {
          id: '8851e4ef-7bee-4416-a121-f98611db88ad',
          title: 'Remote',
          periodic_review: 1,
        },
        linkType: 'audits',
      },
      {
        riskLevel: {
          id: '05a64fcf-ab32-45a3-93aa-8f5dc1fd3125',
          title: 'Critical',
        },
        supplierType: {
          id: 'f371aa59-f853-40bd-84b5-23e7024bfdb9',
          title: 'Manufacturer',
        },
        linkData: {
          id: '8cc0caa5-ed8e-4597-a95a-62ccd13c4a68',
          title: 'NDA',
        },
        linkType: 'documents',
      },
      {
        riskLevel: {
          id: '05a64fcf-ab32-45a3-93aa-8f5dc1fd3125',
          title: 'Critical',
        },
        supplierType: {
          id: 'f371aa59-f853-40bd-84b5-23e7024bfdb9',
          title: 'Manufacturer',
        },
        linkData: {
          id: 'a09a3951-d06c-417d-bc31-4d71beffcd30',
          title: 'GDPR Statement',
        },
        linkType: 'documents',
      },
      {
        riskLevel: {
          id: '05a64fcf-ab32-45a3-93aa-8f5dc1fd3125',
          title: 'Critical',
        },
        supplierType: {
          id: 'f371aa59-f853-40bd-84b5-23e7024bfdb9',
          title: 'Manufacturer',
        },
        linkData: {
          id: '0a0cb0ea-75bc-456a-853a-7aaedb737fce',
          title: 'ISO 9001',
        },
        linkType: 'documents',
      },
    ],
    supplierType: {
      id: 'f371aa59-f853-40bd-84b5-23e7024bfdb9',
      title: 'Manufacturer',
    },
    riskLevel: {
      id: '05a64fcf-ab32-45a3-93aa-8f5dc1fd3125',
      title: 'Critical',
    },
  },
  {
    company: '1',
    links: [
      {
        riskLevel: {
          id: '0e7a448e-e29f-4587-a1fa-4e6bba6cb4b1',
          title: 'Critical',
          description: 'Suppliers with a critical impact on the quality or availability of the product.',
        },
        supplierType: {
          id: 'f3bfd324-9d85-44bd-ab38-c520e779bc45',
          title: 'Manufacturer',
          description:
            'Is a person or company that produces finished goods from raw materials by using various tools, equipment, and processes, and then sells the goods to consumers.',
        },
        linkData: {
          id: '77b41302-5983-431d-9010-67c95fbb3fa8',
          title: 'Quality Agreement',
        },
        linkType: 'documents',
      },
      {
        riskLevel: {
          id: '0e7a448e-e29f-4587-a1fa-4e6bba6cb4b1',
          title: 'Critical',
          description: 'Suppliers with a critical impact on the quality or availability of the product.',
        },
        supplierType: {
          id: 'f3bfd324-9d85-44bd-ab38-c520e779bc45',
          title: 'Manufacturer',
          description:
            'Is a person or company that produces finished goods from raw materials by using various tools, equipment, and processes, and then sells the goods to consumers.',
        },
        linkData: {
          id: '8a3307a3-af21-436f-8314-f9c2d99b8e0d',
          title: 'Remote',
          periodic_review: 0,
        },
        linkType: 'audits',
      },
      {
        riskLevel: {
          id: '0e7a448e-e29f-4587-a1fa-4e6bba6cb4b1',
          title: 'Critical',
          description: 'Suppliers with a critical impact on the quality or availability of the product.',
        },
        supplierType: {
          id: 'f3bfd324-9d85-44bd-ab38-c520e779bc45',
          title: 'Manufacturer',
          description:
            'Is a person or company that produces finished goods from raw materials by using various tools, equipment, and processes, and then sells the goods to consumers.',
        },
        linkData: {
          id: '66f11f36-0faf-41c5-8b91-2ec8d84b4801',
          title: 'On-Site',
          periodic_review: 24,
        },
        linkType: 'audits',
      },
    ],
    supplierType: {
      id: 'f3bfd324-9d85-44bd-ab38-c520e779bc45',
      title: 'Manufacturer',
      description:
        'Is a person or company that produces finished goods from raw materials by using various tools, equipment, and processes, and then sells the goods to consumers.',
    },
    riskLevel: {
      id: '0e7a448e-e29f-4587-a1fa-4e6bba6cb4b1',
      title: 'Critical',
      description: 'Suppliers with a critical impact on the quality or availability of the product.',
    },
  },
  {
    company: '1',
    links: [],
    supplierType: {
      id: '9c545d5f-32d3-4316-9537-bb14edc330e0',
      title: 'Distributor',
      description:
        'A distributor is a person, entity or selling agent who works independently to sell the products of a manufacturer, and is bound by a financial contract.',
    },
    riskLevel: {
      id: '0ffed44d-0ce5-413c-bad7-23efba51de2d',
      title: 'High',
      description: 'High Level',
    },
  },
  {
    company: '1',
    links: [
      {
        riskLevel: {
          id: '0ffed44d-0ce5-413c-bad7-23efba51de2d',
          title: 'High',
          description: 'High Level',
        },
        supplierType: {
          id: '9faa46c3-a6c3-4b79-b15a-feb1a12b883f',
          title: 'Consultant',
          description:
            'A consultant is an person who provides professional or expert advice in a particular field of science or business to either an organisation or individual.',
        },
        linkData: {
          id: 'd51c9e4c-8700-4c8b-bc41-e899285997f6',
          title: 'ISO 9001',
        },
        linkType: 'documents',
      },
    ],
    supplierType: {
      id: '9faa46c3-a6c3-4b79-b15a-feb1a12b883f',
      title: 'Consultant',
      description:
        'A consultant is an person who provides professional or expert advice in a particular field of science or business to either an organisation or individual.',
    },
    riskLevel: {
      id: '0ffed44d-0ce5-413c-bad7-23efba51de2d',
      title: 'High',
      description: 'High Level',
    },
  },
  {
    company: '1',
    links: [
      {
        riskLevel: {
          id: '3f50ddba-c2b3-45eb-860d-7cde2ac0951f',
          title: 'High',
          description:
            'Heavy-risk suppliers with direct impact on the product, but for which alternatives are available.',
        },
        supplierType: {
          id: 'ec3cce8b-b80a-42c0-996e-14b483883493',
          title: 'Manufacturer',
          description:
            'Is a person or company that produces finished goods from raw materials by using various tools, equipment, and processes, and then sells the goods to consumers.',
        },
        linkData: {
          id: 'e900d009-268a-49e7-b6f5-3560f5a55502',
          title: 'SOC2 report',
        },
        linkType: 'documents',
      },
      {
        riskLevel: {
          id: '3f50ddba-c2b3-45eb-860d-7cde2ac0951f',
          title: 'High',
          description:
            'Heavy-risk suppliers with direct impact on the product, but for which alternatives are available.',
        },
        supplierType: {
          id: 'ec3cce8b-b80a-42c0-996e-14b483883493',
          title: 'Manufacturer',
          description:
            'Is a person or company that produces finished goods from raw materials by using various tools, equipment, and processes, and then sells the goods to consumers.',
        },
        linkData: {
          id: 'fcfcb6df-b990-4486-8a3f-f1f2ef0a76c4',
          title: 'GDPR statement',
        },
        linkType: 'documents',
      },
      {
        riskLevel: {
          id: '3f50ddba-c2b3-45eb-860d-7cde2ac0951f',
          title: 'High',
          description:
            'Heavy-risk suppliers with direct impact on the product, but for which alternatives are available.',
        },
        supplierType: {
          id: 'ec3cce8b-b80a-42c0-996e-14b483883493',
          title: 'Manufacturer',
          description:
            'Is a person or company that produces finished goods from raw materials by using various tools, equipment, and processes, and then sells the goods to consumers.',
        },
        linkData: {
          id: '3003fcd7-cc53-48aa-bc5b-17e1043359bd',
          title: 'Standard certification',
        },
        linkType: 'documents',
      },
      {
        riskLevel: {
          id: '3f50ddba-c2b3-45eb-860d-7cde2ac0951f',
          title: 'High',
          description:
            'Heavy-risk suppliers with direct impact on the product, but for which alternatives are available.',
        },
        supplierType: {
          id: 'ec3cce8b-b80a-42c0-996e-14b483883493',
          title: 'Manufacturer',
          description:
            'Is a person or company that produces finished goods from raw materials by using various tools, equipment, and processes, and then sells the goods to consumers.',
        },
        linkData: {
          id: 'fd118ef4-22bf-4f4d-b32b-1de35976c3c7',
          title: 'on-site',
          periodic_review: 3,
        },
        linkType: 'audits',
      },
      {
        riskLevel: {
          id: '3f50ddba-c2b3-45eb-860d-7cde2ac0951f',
          title: 'High',
          description:
            'Heavy-risk suppliers with direct impact on the product, but for which alternatives are available.',
        },
        supplierType: {
          id: 'ec3cce8b-b80a-42c0-996e-14b483883493',
          title: 'Manufacturer',
          description:
            'Is a person or company that produces finished goods from raw materials by using various tools, equipment, and processes, and then sells the goods to consumers.',
        },
        linkData: {
          id: 'c4b1ba99-4e1b-41ea-91e3-d07e972339fb',
          title: 'remote',
          periodic_review: 3,
        },
        linkType: 'audits',
      },
      {
        riskLevel: {
          id: '3f50ddba-c2b3-45eb-860d-7cde2ac0951f',
          title: 'High',
          description:
            'Heavy-risk suppliers with direct impact on the product, but for which alternatives are available.',
        },
        supplierType: {
          id: 'ec3cce8b-b80a-42c0-996e-14b483883493',
          title: 'Manufacturer',
          description:
            'Is a person or company that produces finished goods from raw materials by using various tools, equipment, and processes, and then sells the goods to consumers.',
        },
        linkData: {
          id: '50b32589-209e-4d63-9d15-1cf82e2c03e1',
          title: 'oncall',
          periodic_review: 6,
        },
        linkType: 'audits',
      },
    ],
    supplierType: {
      id: 'ec3cce8b-b80a-42c0-996e-14b483883493',
      title: 'Manufacturer',
      description:
        'Is a person or company that produces finished goods from raw materials by using various tools, equipment, and processes, and then sells the goods to consumers.',
    },
    riskLevel: {
      id: '3f50ddba-c2b3-45eb-860d-7cde2ac0951f',
      title: 'High',
      description: 'Heavy-risk suppliers with direct impact on the product, but for which alternatives are available.',
    },
  },
  {
    company: '1',
    links: [
      {
        riskLevel: {
          id: '3f65e189-c458-4a04-a858-ffb5d73495c8',
          title: 'Critical',
        },
        supplierType: {
          id: '210d6f93-92bf-4da9-8d75-2675c1d4e0c3',
          title: 'Services',
        },
        linkData: {
          id: '87521860-7497-4cef-9c1e-388629737297',
          title: 'Remote',
          periodic_review: 1,
        },
        linkType: 'audits',
      },
      {
        riskLevel: {
          id: '3f65e189-c458-4a04-a858-ffb5d73495c8',
          title: 'Critical',
        },
        supplierType: {
          id: '210d6f93-92bf-4da9-8d75-2675c1d4e0c3',
          title: 'Services',
        },
        linkData: {
          id: 'a8af08c9-e35a-4317-b617-60e092df45c3',
          title: 'Quality Agreement',
        },
        linkType: 'documents',
      },
    ],
    supplierType: {
      id: '210d6f93-92bf-4da9-8d75-2675c1d4e0c3',
      title: 'Services',
    },
    riskLevel: {
      id: '3f65e189-c458-4a04-a858-ffb5d73495c8',
      title: 'Critical',
    },
  },
  {
    company: '1',
    links: [
      {
        riskLevel: {
          id: '3f65e189-c458-4a04-a858-ffb5d73495c8',
          title: 'Critical',
        },
        supplierType: {
          id: '9a00c1b2-9cb5-4ff5-a1a1-158ad8eca5dd',
          title: 'Manufacturer',
        },
        linkData: {
          id: 'bcc373ce-f93c-469e-a747-ddb96c1c9e06',
          title: 'NDA',
        },
        linkType: 'documents',
      },
      {
        riskLevel: {
          id: '3f65e189-c458-4a04-a858-ffb5d73495c8',
          title: 'Critical',
        },
        supplierType: {
          id: '9a00c1b2-9cb5-4ff5-a1a1-158ad8eca5dd',
          title: 'Manufacturer',
        },
        linkData: {
          id: '87521860-7497-4cef-9c1e-388629737297',
          title: 'Remote',
          periodic_review: 2,
        },
        linkType: 'audits',
      },
      {
        riskLevel: {
          id: '3f65e189-c458-4a04-a858-ffb5d73495c8',
          title: 'Critical',
          description: 'Suppliers with a critical impact on the quality or availability of the product.',
        },
        supplierType: {
          id: '9a00c1b2-9cb5-4ff5-a1a1-158ad8eca5dd',
          title: 'Manufacturer',
          description:
            'Is a person or company that produces finished goods from raw materials by using various tools, equipment, and processes, and then sells the goods to consumers. Really good stuff',
        },
        linkData: {
          id: 'a8af08c9-e35a-4317-b617-60e092df45c3',
          title: 'Quality Agreementsssssss',
        },
        linkType: 'documents',
      },
    ],
    supplierType: {
      id: '9a00c1b2-9cb5-4ff5-a1a1-158ad8eca5dd',
      title: 'Manufacturer',
    },
    riskLevel: {
      id: '3f65e189-c458-4a04-a858-ffb5d73495c8',
      title: 'Critical',
    },
  },
  {
    company: '1',
    links: [],
    supplierType: {
      id: '22b56835-7384-4c7d-b8ee-f10512d65960',
      title: 'Manufacturer',
    },
    riskLevel: {
      id: '454a9745-e864-4709-81fe-9962728f36f4',
      title: 'Medium',
    },
  },
  {
    company: '1',
    links: [
      {
        riskLevel: {
          id: '4ec5b4bf-fcc5-437e-adcf-576ada2f2d9c',
          title: 'Low',
        },
        supplierType: {
          id: '12ad56e6-22b5-4629-920a-2fc92c6e2b5a',
          title: 'Consultant',
        },
        linkData: {
          id: 'b81c3542-7a06-4854-b745-e3da029c1292',
          title: 'ISO 9001',
        },
        linkType: 'documents',
      },
    ],
    supplierType: {
      id: '12ad56e6-22b5-4629-920a-2fc92c6e2b5a',
      title: 'Consultant',
    },
    riskLevel: {
      id: '4ec5b4bf-fcc5-437e-adcf-576ada2f2d9c',
      title: 'Low',
    },
  },
  {
    company: '1',
    links: [
      {
        riskLevel: {
          id: '527f4347-dcd8-43a7-8f8b-04495d8323d7',
          title: 'Critical',
          description: 'Highest-risk suppliers with critical impact on the quality or availability of the product.',
        },
        supplierType: {
          id: 'ec3cce8b-b80a-42c0-996e-14b483883493',
          title: 'Manufacturer',
          description:
            'Is a person or company that produces finished goods from raw materials by using various tools, equipment, and processes, and then sells the goods to consumers.',
        },
        linkData: {
          id: '2b52e13e-459c-4880-84b9-e40efe13468e',
          title: 'Quality agreement',
        },
        linkType: 'documents',
      },
      {
        riskLevel: {
          id: '527f4347-dcd8-43a7-8f8b-04495d8323d7',
          title: 'Critical',
          description: 'Highest-risk suppliers with critical impact on the quality or availability of the product.',
        },
        supplierType: {
          id: 'ec3cce8b-b80a-42c0-996e-14b483883493',
          title: 'Manufacturer',
          description:
            'Is a person or company that produces finished goods from raw materials by using various tools, equipment, and processes, and then sells the goods to consumers.',
        },
        linkData: {
          id: 'c4b1ba99-4e1b-41ea-91e3-d07e972339fb',
          title: 'remote',
          periodic_review: 2,
        },
        linkType: 'audits',
      },
      {
        riskLevel: {
          id: '527f4347-dcd8-43a7-8f8b-04495d8323d7',
          title: 'Critical',
          description: 'Highest-risk suppliers with critical impact on the quality or availability of the product.',
        },
        supplierType: {
          id: 'ec3cce8b-b80a-42c0-996e-14b483883493',
          title: 'Manufacturer',
          description:
            'Is a person or company that produces finished goods from raw materials by using various tools, equipment, and processes, and then sells the goods to consumers.',
        },
        linkData: {
          id: 'fd118ef4-22bf-4f4d-b32b-1de35976c3c7',
          title: 'on-site',
          periodic_review: 12,
        },
        linkType: 'audits',
      },
      {
        riskLevel: {
          id: '527f4347-dcd8-43a7-8f8b-04495d8323d7',
          title: 'Critical',
          description: 'Highest-risk suppliers with critical impact on the quality or availability of the product.',
        },
        supplierType: {
          id: 'ec3cce8b-b80a-42c0-996e-14b483883493',
          title: 'Manufacturer',
          description:
            'Is a person or company that produces finished goods from raw materials by using various tools, equipment, and processes, and then sells the goods to consumers.',
        },
        linkData: {
          id: '50b32589-209e-4d63-9d15-1cf82e2c03e1',
          title: 'oncall',
          periodic_review: 1,
        },
        linkType: 'audits',
      },
    ],
    supplierType: {
      id: 'ec3cce8b-b80a-42c0-996e-14b483883493',
      title: 'Manufacturer',
      description:
        'Is a person or company that produces finished goods from raw materials by using various tools, equipment, and processes, and then sells the goods to consumers.',
    },
    riskLevel: {
      id: '527f4347-dcd8-43a7-8f8b-04495d8323d7',
      title: 'Critical',
      description: 'Highest-risk suppliers with critical impact on the quality or availability of the product.',
    },
  },
  {
    company: '1',
    links: [
      {
        riskLevel: {
          id: '8192f47f-05da-4196-9233-f5d52bc25e4f',
          title: 'Critical',
          description: 'Highest priority',
        },
        supplierType: {
          id: 'd8a0b6bc-4db2-4b54-80ca-1c4440ae4745',
          title: 'Services',
          description: 'Describes work that supports a business but does not produce a tangible commodity',
        },
        linkData: {
          id: '742f519a-cfed-4be3-b3a8-4b25fd83a7a0',
          title: 'GDPR Statement',
        },
        linkType: 'documents',
      },
    ],
    supplierType: {
      id: 'd8a0b6bc-4db2-4b54-80ca-1c4440ae4745',
      title: 'Services',
      description: 'Describes work that supports a business but does not produce a tangible commodity',
    },
    riskLevel: {
      id: '8192f47f-05da-4196-9233-f5d52bc25e4f',
      title: 'Critical',
      description: 'Highest priority',
    },
  },
  {
    company: '1',
    links: [
      {
        riskLevel: {
          id: '8192f47f-05da-4196-9233-f5d52bc25e4f',
          title: 'Critical',
          description: 'Highest priority',
        },
        supplierType: {
          id: 'f3bfd324-9d85-44bd-ab38-c520e779bc45',
          title: 'Manufacturer',
          description:
            'Is a person or company that produces finished goods from raw materials by using various tools, equipment, and processes, and then sells the goods to consumers.',
        },
        linkData: {
          id: '8a3307a3-af21-436f-8314-f9c2d99b8e0d',
          title: 'Remote',
          periodic_review: 1,
        },
        linkType: 'audits',
      },
      {
        riskLevel: {
          id: '8192f47f-05da-4196-9233-f5d52bc25e4f',
          title: 'Critical',
          description: 'Highest priority',
        },
        supplierType: {
          id: 'f3bfd324-9d85-44bd-ab38-c520e779bc45',
          title: 'Manufacturer',
          description:
            'Is a person or company that produces finished goods from raw materials by using various tools, equipment, and processes, and then sells the goods to consumers.',
        },
        linkData: {
          id: '66f11f36-0faf-41c5-8b91-2ec8d84b4801',
          title: 'On-Site',
          periodic_review: 1,
        },
        linkType: 'audits',
      },
      {
        riskLevel: {
          id: '8192f47f-05da-4196-9233-f5d52bc25e4f',
          title: 'Critical',
          description: 'Highest priority',
        },
        supplierType: {
          id: 'f3bfd324-9d85-44bd-ab38-c520e779bc45',
          title: 'Manufacturer',
          description:
            'Is a person or company that produces finished goods from raw materials by using various tools, equipment, and processes, and then sells the goods to consumers.',
        },
        linkData: {
          id: 'cb64b114-ff95-427e-99f5-72edb42e9129',
          title: 'Pre-approval',
          periodic_review: 0,
        },
        linkType: 'audits',
      },
    ],
    supplierType: {
      id: 'f3bfd324-9d85-44bd-ab38-c520e779bc45',
      title: 'Manufacturer',
      description:
        'Is a person or company that produces finished goods from raw materials by using various tools, equipment, and processes, and then sells the goods to consumers.',
    },
    riskLevel: {
      id: '8192f47f-05da-4196-9233-f5d52bc25e4f',
      title: 'Critical',
      description: 'Highest priority',
    },
  },
  {
    company: '1',
    links: [
      {
        riskLevel: {
          id: '8bacd825-58d6-482a-b6ce-001be7556993',
          title: 'High',
        },
        supplierType: {
          id: 'f371aa59-f853-40bd-84b5-23e7024bfdb9',
          title: 'Manufacturer',
        },
        linkData: {
          id: '399450b4-a53e-419c-b190-684e55a9e688',
          title: 'On-Site',
          periodic_review: 6,
        },
        linkType: 'audits',
      },
      {
        riskLevel: {
          id: '8bacd825-58d6-482a-b6ce-001be7556993',
          title: 'High',
        },
        supplierType: {
          id: 'f371aa59-f853-40bd-84b5-23e7024bfdb9',
          title: 'Manufacturer',
        },
        linkData: {
          id: '8cc0caa5-ed8e-4597-a95a-62ccd13c4a68',
          title: 'NDA',
        },
        linkType: 'documents',
      },
    ],
    supplierType: {
      id: 'f371aa59-f853-40bd-84b5-23e7024bfdb9',
      title: 'Manufacturer',
    },
    riskLevel: {
      id: '8bacd825-58d6-482a-b6ce-001be7556993',
      title: 'High',
    },
  },
  {
    company: '1',
    links: [
      {
        riskLevel: {
          id: '96a37266-5d3a-4951-a6e1-a486dbc9dede',
          title: 'Major',
          description: 'Some description',
        },
        supplierType: {
          id: 'd8a0b6bc-4db2-4b54-80ca-1c4440ae4745',
          title: 'Services',
          description: 'Describes work that supports a business but does not produce a tangible commodity',
        },
        linkData: {
          id: '77b41302-5983-431d-9010-67c95fbb3fa8',
          title: 'Quality Agreements',
        },
        linkType: 'documents',
      },
      {
        riskLevel: {
          id: '96a37266-5d3a-4951-a6e1-a486dbc9dede',
          title: 'Major',
          description: 'Some description',
        },
        supplierType: {
          id: 'd8a0b6bc-4db2-4b54-80ca-1c4440ae4745',
          title: 'Services',
          description: 'Describes work that supports a business but does not produce a tangible commodity',
        },
        linkData: {
          id: '742f519a-cfed-4be3-b3a8-4b25fd83a7a0',
          title: 'GDPR Statement',
        },
        linkType: 'documents',
      },
      {
        riskLevel: {
          id: '96a37266-5d3a-4951-a6e1-a486dbc9dede',
          title: 'Major',
          description: 'Some description',
        },
        supplierType: {
          id: 'd8a0b6bc-4db2-4b54-80ca-1c4440ae4745',
          title: 'Services',
          description: 'Describes work that supports a business but does not produce a tangible commodity',
        },
        linkData: {
          id: 'd51c9e4c-8700-4c8b-bc41-e899285997f6',
          title: 'ISO 9001',
        },
        linkType: 'documents',
      },
      {
        riskLevel: {
          id: '96a37266-5d3a-4951-a6e1-a486dbc9dede',
          title: 'Major',
          description: 'Some description',
        },
        supplierType: {
          id: 'd8a0b6bc-4db2-4b54-80ca-1c4440ae4745',
          title: 'Services',
          description: 'Describes work that supports a business but does not produce a tangible commodity',
        },
        linkData: {
          id: '74d4ca57-629d-4e43-951e-57747e01c6d0',
          title: 'Non Disclosure agreement',
        },
        linkType: 'documents',
      },
      {
        riskLevel: {
          id: '96a37266-5d3a-4951-a6e1-a486dbc9dede',
          title: 'Major',
          description: 'Some description',
        },
        supplierType: {
          id: 'd8a0b6bc-4db2-4b54-80ca-1c4440ae4745',
          title: 'Services',
          description: 'Describes work that supports a business but does not produce a tangible commodity',
        },
        linkData: {
          id: '72ae3a44-1e42-403c-a565-979645f9bb93',
          title: 'QMS Certification',
        },
        linkType: 'documents',
      },
      {
        riskLevel: {
          id: '96a37266-5d3a-4951-a6e1-a486dbc9dede',
          title: 'Major',
          description: 'Some description',
        },
        supplierType: {
          id: 'd8a0b6bc-4db2-4b54-80ca-1c4440ae4745',
          title: 'Services',
          description: 'Describes work that supports a business but does not produce a tangible commodity',
        },
        linkData: {
          id: '4378b60e-2dbc-4478-8a91-c42fdb64ace2',
          title: '123123',
        },
        linkType: 'documents',
      },
    ],
    supplierType: {
      id: 'd8a0b6bc-4db2-4b54-80ca-1c4440ae4745',
      title: 'Services',
      description: 'Describes work that supports a business but does not produce a tangible commodity',
    },
    riskLevel: {
      id: '96a37266-5d3a-4951-a6e1-a486dbc9dede',
      title: 'Major',
      description: 'Some description',
    },
  },
  {
    company: '1',
    links: [
      {
        riskLevel: {
          id: '96a37266-5d3a-4951-a6e1-a486dbc9dede',
          title: 'Major',
          description: 'Impact on the product',
        },
        supplierType: {
          id: 'f3bfd324-9d85-44bd-ab38-c520e779bc45',
          title: 'Manufacturer',
          description:
            'Is a person or company that produces finished goods from raw materials by using various tools, equipment, and processes, and then sells the goods to consumers.',
        },
        linkData: {
          id: '36fbac54-0035-413c-886a-0535f9df3c0e',
          title: 'NDA',
        },
        linkType: 'documents',
      },
    ],
    supplierType: {
      id: 'f3bfd324-9d85-44bd-ab38-c520e779bc45',
      title: 'Manufacturer',
      description:
        'Is a person or company that produces finished goods from raw materials by using various tools, equipment, and processes, and then sells the goods to consumers.',
    },
    riskLevel: {
      id: '96a37266-5d3a-4951-a6e1-a486dbc9dede',
      title: 'Major',
      description: 'Impact on the product',
    },
  },
  {
    company: '1',
    links: [
      {
        riskLevel: {
          id: 'a173de96-2483-49c0-b7dd-9996e085a667',
          title: 'Critical',
        },
        supplierType: {
          id: '22b56835-7384-4c7d-b8ee-f10512d65960',
          title: 'Manufacturer',
        },
        linkData: {
          id: '9ace1131-21fa-4fa5-a8ba-fb6415432192',
          title: 'Quality agreement',
        },
        linkType: 'documents',
      },
      {
        riskLevel: {
          id: 'a173de96-2483-49c0-b7dd-9996e085a667',
          title: 'Critical',
        },
        supplierType: {
          id: '22b56835-7384-4c7d-b8ee-f10512d65960',
          title: 'Manufacturer',
        },
        linkData: {
          id: 'f21bf3f2-30fe-4fa9-ba5a-76cecda4af93',
          title: 'on-site',
        },
        linkType: 'audits',
      },
    ],
    supplierType: {
      id: '22b56835-7384-4c7d-b8ee-f10512d65960',
      title: 'Manufacturer',
    },
    riskLevel: {
      id: 'a173de96-2483-49c0-b7dd-9996e085a667',
      title: 'Critical',
    },
  },
  {
    company: '1',
    links: [
      {
        riskLevel: {
          id: 'd02e8df6-1a16-4488-9cad-d81ee62dfcfd',
          title: 'High',
        },
        supplierType: {
          id: '22b56835-7384-4c7d-b8ee-f10512d65960',
          title: 'Manufacturer',
        },
        linkData: {
          id: 'eaed4d39-43c0-4911-bc5f-0b708f6c0d74',
          title: 'SOW agreement',
          periodic_review: 3,
        },
        linkType: 'documents',
      },
      {
        riskLevel: {
          id: 'd02e8df6-1a16-4488-9cad-d81ee62dfcfd',
          title: 'High',
        },
        supplierType: {
          id: '22b56835-7384-4c7d-b8ee-f10512d65960',
          title: 'Manufacturer',
        },
        linkData: {
          id: 'f21bf3f2-30fe-4fa9-ba5a-76cecda4af93',
          title: 'on-site',
          periodic_review: 3,
        },
        linkType: 'audits',
      },
      {
        riskLevel: {
          id: 'd02e8df6-1a16-4488-9cad-d81ee62dfcfd',
          title: 'High',
        },
        supplierType: {
          id: '22b56835-7384-4c7d-b8ee-f10512d65960',
          title: 'Manufacturer',
        },
        linkData: {
          id: '234ec9d3-faab-471e-ab86-64f925390b34',
          title: 'remote',
          periodic_review: 12,
        },
        linkType: 'audits',
      },
      {
        riskLevel: {
          id: 'd02e8df6-1a16-4488-9cad-d81ee62dfcfd',
          title: 'High',
        },
        supplierType: {
          id: '22b56835-7384-4c7d-b8ee-f10512d65960',
          title: 'Manufacturer',
        },
        linkData: {
          id: '44dc8745-2807-416d-a901-0e0c5de9d0d4',
          title: 'SOC2 report',
        },
        linkType: 'documents',
      },
      {
        riskLevel: {
          id: 'd02e8df6-1a16-4488-9cad-d81ee62dfcfd',
          title: 'High',
        },
        supplierType: {
          id: '22b56835-7384-4c7d-b8ee-f10512d65960',
          title: 'Manufacturer',
        },
        linkData: {
          id: '4fe70a99-8cb3-4126-a538-48d443de066d',
          title: 'Standard certification',
        },
        linkType: 'documents',
      },
    ],
    supplierType: {
      id: '22b56835-7384-4c7d-b8ee-f10512d65960',
      title: 'Manufacturer',
    },
    riskLevel: {
      id: 'd02e8df6-1a16-4488-9cad-d81ee62dfcfd',
      title: 'High',
    },
  },
  {
    company: '1',
    links: [
      {
        riskLevel: {
          id: 'd97c0a6e-a7bf-4d63-94ff-7a5e77b8a20b',
          title: 'Minor',
          description: 'Minor Risk',
        },
        supplierType: {
          id: '9faa46c3-a6c3-4b79-b15a-feb1a12b883f',
          title: 'Consultant',
          description:
            'A consultant is an person who provides professional or expert advice in a particular field of science or business to either an organisation or individual.',
        },
        linkData: {
          id: '8a3307a3-af21-436f-8314-f9c2d99b8e0d',
          title: 'Remote',
          periodic_review: 3,
        },
        linkType: 'audits',
      },
      {
        riskLevel: {
          id: 'd97c0a6e-a7bf-4d63-94ff-7a5e77b8a20b',
          title: 'Minor',
          description: 'Minor Risk',
        },
        supplierType: {
          id: '9faa46c3-a6c3-4b79-b15a-feb1a12b883f',
          title: 'Consultant',
          description:
            'A consultant is an person who provides professional or expert advice in a particular field of science or business to either an organisation or individual.',
        },
        linkData: {
          id: '66f11f36-0faf-41c5-8b91-2ec8d84b4801',
          title: 'On-Site',
          periodic_review: 12,
        },
        linkType: 'audits',
      },
    ],
    supplierType: {
      id: '9faa46c3-a6c3-4b79-b15a-feb1a12b883f',
      title: 'Consultant',
      description:
        'A consultant is an person who provides professional or expert advice in a particular field of science or business to either an organisation or individual.',
    },
    riskLevel: {
      id: 'd97c0a6e-a7bf-4d63-94ff-7a5e77b8a20b',
      title: 'Minor',
      description: 'Minor Risk',
    },
  },
  {
    company: '1',
    links: [
      {
        riskLevel: {
          id: 'd97c0a6e-a7bf-4d63-94ff-7a5e77b8a20b',
          title: 'Minor',
          description: 'Minor Risk',
        },
        supplierType: {
          id: 'd8a0b6bc-4db2-4b54-80ca-1c4440ae4745',
          title: 'Services',
          description: 'Describes work that supports a business but does not produce a tangible commodity',
        },
        linkData: {
          id: '77b41302-5983-431d-9010-67c95fbb3fa8',
          title: 'Quality Agreements',
        },
        linkType: 'documents',
      },
      {
        riskLevel: {
          id: 'd97c0a6e-a7bf-4d63-94ff-7a5e77b8a20b',
          title: 'Minor',
          description: 'Minor Risk',
        },
        supplierType: {
          id: 'd8a0b6bc-4db2-4b54-80ca-1c4440ae4745',
          title: 'Services',
          description: 'Describes work that supports a business but does not produce a tangible commodity',
        },
        linkData: {
          id: '742f519a-cfed-4be3-b3a8-4b25fd83a7a0',
          title: 'GDPR Statement',
        },
        linkType: 'documents',
      },
    ],
    supplierType: {
      id: 'd8a0b6bc-4db2-4b54-80ca-1c4440ae4745',
      title: 'Services',
      description: 'Describes work that supports a business but does not produce a tangible commodity',
    },
    riskLevel: {
      id: 'd97c0a6e-a7bf-4d63-94ff-7a5e77b8a20b',
      title: 'Minor',
      description: 'Minor Risk',
    },
  },
  {
    company: '1',
    links: [
      {
        riskLevel: {
          id: 'd97c0a6e-a7bf-4d63-94ff-7a5e77b8a20b',
          title: 'Minor',
        },
        supplierType: {
          id: 'f3bfd324-9d85-44bd-ab38-c520e779bc45',
          title: 'Manufacturer',
          description:
            'Is a person or company that produces finished goods from raw materials by using various tools, equipment, and processes, and then sells the goods to consumers.',
        },
        linkData: {
          id: '77b41302-5983-431d-9010-67c95fbb3fa8',
          title: 'Quality Agreement',
        },
        linkType: 'documents',
      },
      {
        riskLevel: {
          id: 'd97c0a6e-a7bf-4d63-94ff-7a5e77b8a20b',
          title: 'Minor',
        },
        supplierType: {
          id: 'f3bfd324-9d85-44bd-ab38-c520e779bc45',
          title: 'Manufacturer',
          description:
            'Is a person or company that produces finished goods from raw materials by using various tools, equipment, and processes, and then sells the goods to consumers.',
        },
        linkData: {
          id: '36fbac54-0035-413c-886a-0535f9df3c0e',
          title: 'NDA',
        },
        linkType: 'documents',
      },
      {
        riskLevel: {
          id: 'd97c0a6e-a7bf-4d63-94ff-7a5e77b8a20b',
          title: 'Minor',
        },
        supplierType: {
          id: 'f3bfd324-9d85-44bd-ab38-c520e779bc45',
          title: 'Manufacturer',
          description:
            'Is a person or company that produces finished goods from raw materials by using various tools, equipment, and processes, and then sells the goods to consumers.',
        },
        linkData: {
          id: '8a3307a3-af21-436f-8314-f9c2d99b8e0d',
          title: 'Remote',
          periodic_review: 12,
        },
        linkType: 'audits',
      },
      {
        riskLevel: {
          id: 'd97c0a6e-a7bf-4d63-94ff-7a5e77b8a20b',
          title: 'Minor',
        },
        supplierType: {
          id: 'f3bfd324-9d85-44bd-ab38-c520e779bc45',
          title: 'Manufacturer',
          description:
            'Is a person or company that produces finished goods from raw materials by using various tools, equipment, and processes, and then sells the goods to consumers.',
        },
        linkData: {
          id: '8fa84f2f-4422-4040-8da7-d7c9d04bcd94',
          title: 'Technical Files',
        },
        linkType: 'documents',
      },
      {
        riskLevel: {
          id: 'd97c0a6e-a7bf-4d63-94ff-7a5e77b8a20b',
          title: 'Minor',
        },
        supplierType: {
          id: 'f3bfd324-9d85-44bd-ab38-c520e779bc45',
          title: 'Manufacturer',
          description:
            'Is a person or company that produces finished goods from raw materials by using various tools, equipment, and processes, and then sells the goods to consumers.',
        },
        linkData: {
          id: '742f519a-cfed-4be3-b3a8-4b25fd83a7a0',
          title: 'GDPR Statement',
        },
        linkType: 'documents',
      },
      {
        riskLevel: {
          id: 'd97c0a6e-a7bf-4d63-94ff-7a5e77b8a20b',
          title: 'Minor',
        },
        supplierType: {
          id: 'f3bfd324-9d85-44bd-ab38-c520e779bc45',
          title: 'Manufacturer',
          description:
            'Is a person or company that produces finished goods from raw materials by using various tools, equipment, and processes, and then sells the goods to consumers.',
        },
        linkData: {
          id: 'd51c9e4c-8700-4c8b-bc41-e899285997f6',
          title: 'ISO 9001',
        },
        linkType: 'documents',
      },
      {
        riskLevel: {
          id: 'd97c0a6e-a7bf-4d63-94ff-7a5e77b8a20b',
          title: 'Minor',
        },
        supplierType: {
          id: 'f3bfd324-9d85-44bd-ab38-c520e779bc45',
          title: 'Manufacturer',
          description:
            'Is a person or company that produces finished goods from raw materials by using various tools, equipment, and processes, and then sells the goods to consumers.',
        },
        linkData: {
          id: '66f11f36-0faf-41c5-8b91-2ec8d84b4801',
          title: 'On-Site',
        },
        linkType: 'audits',
      },
      {
        riskLevel: {
          id: 'd97c0a6e-a7bf-4d63-94ff-7a5e77b8a20b',
          title: 'Minor',
        },
        supplierType: {
          id: 'f3bfd324-9d85-44bd-ab38-c520e779bc45',
          title: 'Manufacturer',
          description:
            'Is a person or company that produces finished goods from raw materials by using various tools, equipment, and processes, and then sells the goods to consumers.',
        },
        linkData: {
          id: 'cb64b114-ff95-427e-99f5-72edb42e9129',
          title: 'Pre-approval',
        },
        linkType: 'audits',
      },
      {
        riskLevel: {
          id: 'd97c0a6e-a7bf-4d63-94ff-7a5e77b8a20b',
          title: 'Minor',
          description: 'Minor Risk',
        },
        supplierType: {
          id: 'f3bfd324-9d85-44bd-ab38-c520e779bc45',
          title: 'Manufacturer',
          description:
            'Is a person or company that produces finished goods from raw materials by using various tools, equipment, and processes, and then sells the goods to consumers.',
        },
        linkData: {
          id: 'd670eab4-e60f-4119-8ace-e8ea8a9b535e',
          title: 'Statement of works',
        },
        linkType: 'documents',
      },
      {
        riskLevel: {
          id: 'd97c0a6e-a7bf-4d63-94ff-7a5e77b8a20b',
          title: 'Minor',
          description: 'Minor Risk',
        },
        supplierType: {
          id: 'f3bfd324-9d85-44bd-ab38-c520e779bc45',
          title: 'Manufacturer',
          description:
            'Is a person or company that produces finished goods from raw materials by using various tools, equipment, and processes, and then sells the goods to consumers.',
        },
        linkData: {
          id: '74d4ca57-629d-4e43-951e-57747e01c6d0',
          title: 'Non Disclosure agreement',
        },
        linkType: 'documents',
      },
      {
        riskLevel: {
          id: 'd97c0a6e-a7bf-4d63-94ff-7a5e77b8a20b',
          title: 'Minor',
          description: 'Minor Risk',
        },
        supplierType: {
          id: 'f3bfd324-9d85-44bd-ab38-c520e779bc45',
          title: 'Manufacturer',
          description:
            'Is a person or company that produces finished goods from raw materials by using various tools, equipment, and processes, and then sells the goods to consumers.',
        },
        linkData: {
          id: '72ae3a44-1e42-403c-a565-979645f9bb93',
          title: 'QMS Certification',
        },
        linkType: 'documents',
      },
      {
        riskLevel: {
          id: 'd97c0a6e-a7bf-4d63-94ff-7a5e77b8a20b',
          title: 'Minor',
          description: 'Minor Risk',
        },
        supplierType: {
          id: 'f3bfd324-9d85-44bd-ab38-c520e779bc45',
          title: 'Manufacturer',
          description:
            'Is a person or company that produces finished goods from raw materials by using various tools, equipment, and processes, and then sells the goods to consumers.',
        },
        linkData: {
          id: '4378b60e-2dbc-4478-8a91-c42fdb64ace2',
          title: '123123',
        },
        linkType: 'documents',
      },
    ],
    supplierType: {
      id: 'f3bfd324-9d85-44bd-ab38-c520e779bc45',
      title: 'Manufacturer',
      description:
        'Is a person or company that produces finished goods from raw materials by using various tools, equipment, and processes, and then sells the goods to consumers.',
    },
    riskLevel: {
      id: 'd97c0a6e-a7bf-4d63-94ff-7a5e77b8a20b',
      title: 'Minor',
    },
  },
]);
