import { Supplier, Payload } from 'types';

import { makeResponse } from './generic';

export const supplierResponse: Payload<Supplier> = makeResponse({
  name: 'Test 4',
  supplierType: {
    id: 'ec3cce8b-b80a-42c0-996e-14b483883493',
    title: 'Manufacturer',
  },
  riskType: {
    id: '527f4347-dcd8-43a7-8f8b-04495d8323d7',
    title: 'Critical',
    description: 'Highest-risk suppliers with critical impact on the quality or availability of the product.',
  },
  documents: [
    {
      id: '2b52e13e-459c-4880-84b9-e40efe13468e',
      title: 'Quality agreement',
      file: {
        fileName: 'Screenshot 2022-02-09 at 6.14.14 PM (3).png',
        id: '7cba457d-4d11-44a0-9cd2-1472e2866fe5',
        type: 'FILE',
        file_id: 0,
      },
      files: [
        {
          fileName: 'Screenshot 2022-02-09 at 6.14.14 PM (3).png',
          id: '7cba457d-4d11-44a0-9cd2-1472e2866fe5',
          type: 'FILE',
          file_id: 0,
        },
        {
          fileName: 'Screenshot 2022-02-09 at 6.14.14 PM (2).png',
          id: 'b24ff532-ce6c-45cf-8284-a782ee417a9d',
          type: 'FILE',
          file_id: 0,
        },
      ],
    },
    {
      id: 'e900d009-268a-49e7-b6f5-3560f5a55502',
      title: 'SOC2 report',
      file: {
        fileName: 'suppliers (4).csv',
        id: 'f4118224-ebe9-4b6a-a193-02c9ca20be29',
        type: 'FILE',
        file_id: 0,
      },
      files: [
        {
          fileName: 'suppliers (4).csv',
          id: 'f4118224-ebe9-4b6a-a193-02c9ca20be29',
          type: 'FILE',
          file_id: 0,
        },
      ],
    },
  ],
  sponsor: {
    id: 2,
    fullName: 'Alice Mills',
    email: 'testuser2@example.com',
  },
  approvers: [
    {
      id: 2,
      fullName: 'Alice Mills',
      email: 'testuser2@example.com',
    },
  ],
  status: 'APPROVED',
  supplier: '45b3e77c-d99d-42b0-aaa3-795bd61e129a',
  company: '1',
  archived: false,
  created: '2022-02-24T14:18:50.153Z',
  modified: '2022-03-30T11:17:25.501Z',
  approvalDate: 'Wed Mar 30 2022 11:17:25 GMT+0000 (Coordinated Universal Time)',
  version: 7,
  changeControl: {
    changeControl: '0cb57782-2bda-491f-9852-bf13cad7bff6',
    type: 'APPROVAL',
    status: 'CLOSED',
    reviews: [
      {
        status: 'APPROVED',
        reviewer: {
          id: 2,
          fullName: 'Alice Mills',
          email: 'testuser2@example.com',
        },
        reviewedAt: '2022-03-30T11:17:24.632Z',
      },
    ],
    created: '2022-02-24T14:18:50.014Z',
  },
  audits: [],
});

export const draftSupplierResponse: Payload<Supplier> = makeResponse({
  supplier: '42024adb-c0d5-4152-8afd-60fca44a7106',
  name: 'ACME Limited' + new Date().getTime(),
  supplierType: {
    id: '0d8a0d85-68f4-4ec9-8d31-6ea2c67d57e2',
    title: 'Consultants',
    description:
      'A consultant is an person who provides professional or expert advice in a particular field of science or business to either an organization or individual.',
  },
  riskType: {
    id: '729937eb-4df0-49de-b18a-9e33cc3641ab',
    title: 'Low',
    description: 'Low risk suppliers have no significant impact on the final product.',
  },
  sponsor: {
    id: 2,
    fullName: 'Alice Mills',
    email: 'testuser2@example.com',
  },
  use: 'Quality products',
  risk: 'Critical',
  status: 'DRAFT',
  documents: [
    {
      title: 'Additional Document',
      files: [
        {
          fileName: 'sample.pdf',
          id: 'fae208fb-548a-457b-abb9-8a308014de79',
          type: 'FILE',
          file_id: 0,
        },
      ],
    },
  ],
});

export const suppliersResponse: Payload<Supplier[]> = makeResponse([
  {
    version: 5,
    status: 'DRAFT',
    riskType: {
      title: 'Critical',
      description: 'Highest-risk suppliers with critical impact on the quality or availability of the product.',
      id: '527f4347-dcd8-43a7-8f8b-04495d8323d7',
    },
    supplier: '045baf0b-2e7f-4c5f-bca7-5a860a1513ac',
    name: 'Test 1',
    created: '2022-03-22T20:38:43.135Z',
    documents: [
      {
        title: 'Additional Document',
        files: [
          {
            fileName: 'babel.config.js',
            id: 'fae208fb-548a-457b-abb9-8a308014de79',
            type: 'FILE',
            file_id: 0,
          },
          {
            fileName: 'babel.config.js',
            id: 'fae208fb-548a-457b-abb9-8a308014de79',
            type: 'FILE',
            file_id: 0,
          },
        ],
        file: {
          fileName: 'babel.config.js',
          id: 'fae208fb-548a-457b-abb9-8a308014de79',
          type: 'FILE',
          file_id: 0,
        },
      },
    ],
    archived: false,
    modified: '2022-03-22T20:38:43.135Z',
    company: '1',
    supplierType: {
      title: 'Manufacturer',
      id: 'ec3cce8b-b80a-42c0-996e-14b483883493',
    },
    audits: [],
  },
  {
    version: 3,
    status: 'REVIEW_PENDING',
    riskType: {
      title: 'Critical',
      description:
        'Highest-risk suppliers with critical impact on the quality or availability of the product.Highest-risk suppliers with critical impact on the quality or availability of the product.Highest-risk suppliers with critical impact on the quality or availability of the product.Highest-risk suppliers with critical impact on the quality or availability of the product.Highest-risk suppliers with critical impact on the quality or availability of the product.Highest-risk suppliers with critical impact on the quality or availability of the product.',
      id: '527f4347-dcd8-43a7-8f8b-04495d8323d7',
    },
    supplier: '0be8524e-b948-4aee-a633-c112c62e71e6',
    name: 'Test 2',
    created: '2022-03-25T11:07:09.863Z',
    archived: false,
    modified: '2022-03-25T11:07:09.863Z',
    company: '1',
    documents: [],
    audits: [],
  },
  {
    version: 2,
    sponsor: {
      fullName: 'Nicholas Jordan',
      email: 'testuser3@example.com',
      id: 3,
    },
    status: 'APPROVED',
    riskType: {
      title: 'Minor',
      description: 'Minor Risk',
      id: 'd97c0a6e-a7bf-4d63-94ff-7a5e77b8a20b',
    },
    supplier: '269591af-e7d3-4ac9-bc51-e6e1966f07dd',
    name: 'Test 3',
    created: '2022-02-07T15:56:53.306Z',
    archived: false,
    modified: '2022-02-07T15:56:53.306Z',
    company: '1',
    supplierType: {
      title: 'Services',
      id: 'd8a0b6bc-4db2-4b54-80ca-1c4440ae4745',
    },
    documents: [],
    audits: [],
  },
]);

export const suppliersArchivedResponse: Payload<Supplier[]> = makeResponse([
  {
    version: 5,
    status: 'ARCHIVED',
    riskType: {
      title: 'Critical',
      description: 'Highest-risk suppliers with critical impact on the quality or availability of the product.',
      id: '527f4347-dcd8-43a7-8f8b-04495d8323d7',
    },
    supplier: '045baf0b-2e7f-4c5f-bca7-5a860a1513ac',
    name: 'Test',
    created: '2022-03-22T20:38:43.135Z',
    documents: [
      {
        title: 'Additional Document',
        files: [
          {
            fileName: 'babel.config.js',
            id: 'fae208fb-548a-457b-abb9-8a308014de79',
            type: 'FILE',
            file_id: 0,
          },
          {
            fileName: 'babel.config.js',
            id: 'fae208fb-548a-457b-abb9-8a308014de79',
            type: 'FILE',
            file_id: 0,
          },
        ],
        file: {
          fileName: 'babel.config.js',
          id: 'fae208fb-548a-457b-abb9-8a308014de79',
          type: 'FILE',
          file_id: 0,
        },
      },
    ],
    archived: false,
    modified: '2022-03-22T20:38:43.135Z',
    company: '1',
    supplierType: {
      title: 'Manufacturer',
      id: 'ec3cce8b-b80a-42c0-996e-14b483883493',
    },
    audits: [],
  },
  {
    version: 3,
    status: 'ARCHIVED',
    riskType: {
      title: 'Critical',
      description:
        'Highest-risk suppliers with critical impact on the quality or availability of the product.Highest-risk suppliers with critical impact on the quality or availability of the product.Highest-risk suppliers with critical impact on the quality or availability of the product.Highest-risk suppliers with critical impact on the quality or availability of the product.Highest-risk suppliers with critical impact on the quality or availability of the product.Highest-risk suppliers with critical impact on the quality or availability of the product.',
      id: '527f4347-dcd8-43a7-8f8b-04495d8323d7',
    },
    supplier: '0be8524e-b948-4aee-a633-c112c62e71e6',
    name: 'DRAFT SUPPLIER',
    created: '2022-03-25T11:07:09.863Z',
    archived: false,
    modified: '2022-03-25T11:07:09.863Z',
    company: '1',
    documents: [],
    audits: [],
  },
  {
    version: 2,
    sponsor: {
      fullName: 'Nicholas Jordan',
      email: 'testuser3@example.com',
      id: 3,
    },
    status: 'ARCHIVED',
    riskType: {
      title: 'Minor',
      description: 'Minor Risk',
      id: 'd97c0a6e-a7bf-4d63-94ff-7a5e77b8a20b',
    },
    supplier: '269591af-e7d3-4ac9-bc51-e6e1966f07dd',
    name: 'Test new doc upload',
    created: '2022-02-07T15:56:53.306Z',
    archived: false,
    modified: '2022-02-07T15:56:53.306Z',
    company: '1',
    supplierType: {
      title: 'Services',
      id: 'd8a0b6bc-4db2-4b54-80ca-1c4440ae4745',
    },
    documents: [],
    audits: [],
  },
]);
