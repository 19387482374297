import React from 'react';

import { QText } from '@qualio/ui-components';

interface NoQualioDocFoundSelectProps {
  inputElement: { inputValue: string };
}

const NoQualioDocFoundSelect: React.FC<NoQualioDocFoundSelectProps> = ({ inputElement }) => {
  if (!!inputElement.inputValue)
    return (
      <>
        <QText>No document found with the prefix:</QText>
        <QText fontWeight={'semibold'}>{inputElement.inputValue}</QText>
      </>
    );

  return (
    <QText fontSize="sm">
      Please type a valid prefix of a Qualio document. E.g.: "<b>POL</b>"
    </QText>
  );
};

export default NoQualioDocFoundSelect;
