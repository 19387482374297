import React, { useState } from 'react';

import { useToken } from '@chakra-ui/react';
import {
  QBox,
  QButton,
  QIcon,
  QIconButton,
  QStack,
  QText,
  QTextarea,
  useCurrentUser,
  useToastProvider,
} from '@qualio/ui-components';
import documentApi from 'api/document.api';
import { AsyncSelect } from 'chakra-react-select';
import { useQualioDocsQuery } from 'hooks';
import { File } from 'providers';
import { NoQualioDocFoundSelect } from 'v2components';

type FileHeaderProps = {
  fileName: File['fileName'];
};

const FileHeader: React.FC<FileHeaderProps> = ({ fileName }) => (
  <>
    <QIcon iconName="File" {...{ color: 'gray.100' }} />
    <QText fontSize="sm" color="gray.500" fontWeight="semibold" pl={1}>
      {fileName}
    </QText>
  </>
);

type QualioDocHeaderProps = {
  file: File;
  handleQualioDocSelected: (file: File) => void;
};

const QualioDocHeader: React.FC<QualioDocHeaderProps> = ({ file, handleQualioDocSelected }) => {
  const { getDocumentOptions } = useQualioDocsQuery();

  const onQualioDocSelect = (doc: any) => {
    const newFile: File = {
      ...file,
      fileName: `${doc.code} ${doc.title}`,
      type: 'QUALIO_DOC',
      file_id: doc.id,
      code: doc.code,
    };
    handleQualioDocSelected(newFile);
  };

  return (
    <>
      {file.file_id && file.fileName ? (
        <>
          <QIcon iconName="File" {...{ color: 'gray.200' }} />
          <QText fontSize="sm" color="gray.500" fontWeight="semibold" pl={1}>
            {file.fileName}
          </QText>
        </>
      ) : (
        <QBox w="300px">
          <AsyncSelect
            size="sm"
            className="document-select"
            loadOptions={getDocumentOptions}
            isClearable={true}
            onChange={onQualioDocSelect}
            noOptionsMessage={(e) => <NoQualioDocFoundSelect inputElement={e} />}
          />
        </QBox>
      )}
    </>
  );
};

type SupportingDocumentFileProps = {
  file: File;
  handleDeleteFile: () => void;
  handleUpdateFile: (file: File) => void;
};

export const SupportingDocumentFile: React.FC<SupportingDocumentFileProps> = ({
  file,
  handleDeleteFile,
  handleUpdateFile,
}) => {
  const { companyId } = useCurrentUser();
  const { showToast } = useToastProvider();
  const [blackAlpha400] = useToken('colors', ['blackAlpha.400']);

  const [areActionsVisible, setAreActionsVisible] = useState(false);

  const [isEditable, setIsEditable] = useState(!!file?.description);

  const hoverOn = () => {
    setAreActionsVisible(true);
  };
  const hoverOut = () => {
    setAreActionsVisible(false);
  };

  const handleDownloadDocument = async () => {
    if (!file?.id) return;
    try {
      const response = await documentApi.getDocumentFile(companyId, file?.id);
      if (response) {
        const data = new Blob([response.data]);
        const object = window.URL.createObjectURL(data);
        const tempLink = document.createElement('a');
        tempLink.href = object;
        tempLink.setAttribute('download', file.fileName!);
        tempLink.click();
      }
    } catch (error) {
      console.error(error);
      showToast({
        title: 'Error',
        description: 'Something wrong happened downloading this document',
        status: 'error',
      });
    }
  };

  return (
    <QBox
      border="1px"
      borderColor="blackAlpha.200"
      borderRadius="base"
      p={2}
      _hover={{ outline: `1px solid ${blackAlpha400}` }}
      onMouseOver={hoverOn}
      onMouseOut={hoverOut}
    >
      <QBox display="flex" justifyContent="space-between" alignContent="center">
        <QBox display="flex" alignItems="center" h="34px">
          {file.type === 'FILE' ? (
            <FileHeader fileName={file.fileName} />
          ) : (
            <QualioDocHeader file={file} handleQualioDocSelected={(updatedFile) => handleUpdateFile(updatedFile)} />
          )}
        </QBox>
        <QStack direction="row" style={areActionsVisible ? { display: 'inline-block' } : { display: 'none' }}>
          {file.type === 'FILE' ? (
            <QIconButton aria-label="Download file" iconName="Download" onClick={handleDownloadDocument} />
          ) : (
            <a href={`${window.location.origin}/library/e/${file.file_id}`} target="_blank" rel="noopener noreferrer">
              <QIconButton aria-label="Open qualio doc" iconName="ExternalLink" />
            </a>
          )}
          <QIconButton aria-label="Delete file" iconName="Trash" onClick={handleDeleteFile} />
        </QStack>
      </QBox>
      {!isEditable && (
        <QBox mt={2}>
          <QButton variant="link" leftIcon="Edit3" onClick={() => setIsEditable(true)}>
            Add description
          </QButton>
        </QBox>
      )}
      {isEditable && (
        <QBox
          display="flex"
          alignItems="center"
          mt={2}
          sx={{
            // FIXME: This is a backport for CL version 26.0.2
            div: {
              'flex-grow': '1',
            },
          }}
        >
          <QTextarea
            value={file.description}
            onChange={(e) => handleUpdateFile({ ...file, description: e.target.value })}
          />
          <QIconButton
            aria-label="delete-description"
            iconName="Trash"
            {...{ mx: 1 }}
            onClick={() => {
              handleUpdateFile({ ...file, description: '' });
              setIsEditable(false);
            }}
          />
        </QBox>
      )}
    </QBox>
  );
};
