import React from 'react';

import { useToken } from '@chakra-ui/react';
import { QFlex, QInput, QStack, QFormControl, QTextarea, QIconButton, QSpacer } from '@qualio/ui-components';

interface ConfigurationCardProps {
  field: any;
  setFieldValue: any;
  handleDeleteItem: () => void;
  errors: any;
  touched: any;
  status: any;
}

const ConfigurationCard: React.FC<ConfigurationCardProps> = ({
  field,
  setFieldValue,
  handleDeleteItem,
  errors,
  touched,
  status,
}) => {
  const [blackAlpha400] = useToken('colors', ['blackAlpha.400']);
  const isFieldInvalid = (field: string) => {
    return ((!!touched && !!touched?.[field]) || status === 'touched') && !!errors?.[field];
  };
  return (
    <QFlex
      direction="column"
      borderColor="blackAlpha.200"
      borderWidth="1px"
      borderRadius="base"
      p={4}
      w="100%"
      data-cy="settings-card-item"
      _hover={{ border: `1px solid ${blackAlpha400}` }}
    >
      <QStack>
        <QFlex>
          <QFormControl w="50%" isInvalid={isFieldInvalid('title')} error={errors?.title}>
            <QInput
              data-cy="config-item-title"
              placeholder="Name"
              value={field.value.title}
              onChange={(e) => setFieldValue(field.name, { ...field.value, title: e.target.value })}
            />
          </QFormControl>
          <QSpacer />

          <QIconButton
            data-cy="config-item-remove-button"
            aria-label="Delete policy row"
            iconName="Trash"
            onClick={handleDeleteItem}
          />
        </QFlex>
        <QFormControl isInvalid={isFieldInvalid('description')} error={errors?.description}>
          <QTextarea
            data-cy="config-item-description"
            value={field.value.description}
            placeholder="Add description"
            onChange={(e) => setFieldValue(field.name, { ...field.value, description: e.target.value })}
          />
        </QFormControl>
      </QStack>
    </QFlex>
  );
};

export default ConfigurationCard;
