import { getRequiredAudits, maybePluralize } from 'lib/utils';
import { DateTime } from 'luxon';
import { Audit } from 'types';
import _ from 'underscore';
import { ConfigItem } from 'v2types';

export const getLastAuditDueMillis = (audits: Audit[] | undefined): number | undefined => {
  if (!audits?.length) return undefined;

  const auditDates = audits.map((audit) => {
    const date = new Date(audit.endDate || audit.modified || '').getTime();
    return Math.round(date);
  });

  const latestAuditDate = Math.max(...auditDates);
  return latestAuditDate;
};

export type NextAuditDueInfo = {
  dueInRelative: string;
  dueAtISO: string;
  dueType: 'default' | 'error' | 'warning';
  auditType: string;
};

export const getNextAuditDue = (
  audits: Audit[] | undefined,
  supplierType: ConfigItem,
  policyLinks?: any, // eslint-disable-line @typescript-eslint/explicit-module-boundary-types
  riskLevel?: ConfigItem,
  auditTypes?: ConfigItem[],
  supplier?: string,
  isDetails?: boolean,
): NextAuditDueInfo | null => {
  if (!audits?.length && !isDetails) return null;

  const requiredAuditsToBePerformed = getRequiredAudits(policyLinks, auditTypes, supplierType, riskLevel).reduce(
    (acc, audit) => ({ ...acc, [audit.id]: { name: audit.name, frequency: parseInt(audit.frequency) } }),
    {} as Record<string, { name: string; frequency: number }>,
  );

  if (!Object.keys(requiredAuditsToBePerformed).length && !isDetails) return null;

  const latestPerformedAuditTimeById: Record<string, number> = {};

  const performedAuditsByType = _.groupBy(audits ?? [], (item) =>
    item instanceof Array ? item[0].auditType?.id : item.auditType?.id,
  );

  Object.entries(performedAuditsByType).forEach(([id, audits]) => {
    if (id === 'undefined') return;

    const auditDates = audits.map((audit) => {
      const date = new Date(audit.endDate ?? audit.modified ?? '').getTime();
      return Math.round(date);
    });

    latestPerformedAuditTimeById[id] = Math.max(...auditDates);
  });

  type NextAuditDisplayDueInfo = {
    lastAuditDate: string;
    frequency: number;
    frequencyInMillis: number;
    nextAuditMillis: number;
    nextAuditISO: string;
    interval: string;
    supplier: string | undefined;
    intervalRaw: Duration;
    dueType: 'default' | 'error' | 'warning';
    auditType: string;
    intervalMonths: number;
    intervalWeeks: number;
    intervalDays: number;
  };

  const nextAuditsToBePerformed: Record<string, NextAuditDisplayDueInfo> = {};

  Object.entries(latestPerformedAuditTimeById).forEach(([id, lastAuditTimeMillis]) => {
    const audit = requiredAuditsToBePerformed[id];
    if (!audit) {
      console.warn('Did not find a required audit for id', { id });
      return;
    }
    const frequency = audit.frequency;
    if (!frequency) {
      console.warn('Expected a valid frequency as a number of months', { id, frequency });
      return;
    }
    if (!lastAuditTimeMillis) {
      console.warn('Expected a valid last audit date', { id, lastAuditTimeMillis });
      return;
    }
    const lastAuditDate = DateTime.fromMillis(lastAuditTimeMillis);
    const nextAuditDate = lastAuditDate.plus({ months: frequency }).toUTC();

    const interval = nextAuditDate.diff(DateTime.now(), ['months', 'weeks', 'days']);

    const intervalMonths = Math.round(interval.months);
    const intervalWeeks = Math.round(interval.weeks);
    const intervalDays = Math.round(interval.days);

    const frequencyInMillis = frequency * 30 * 24 * 60 * 60 * 1000;
    nextAuditsToBePerformed[id] = {
      lastAuditDate: lastAuditDate.toLocaleString(DateTime.DATE_MED),
      frequency,
      frequencyInMillis,
      nextAuditMillis: nextAuditDate.toMillis(),
      nextAuditISO: nextAuditDate.toISO(),
      interval: `${intervalMonths} months ${intervalWeeks} weeks ${intervalDays} days`,
      supplier: supplier,
      intervalRaw: interval,
      dueType: getDueType(intervalMonths, intervalWeeks, intervalDays),
      auditType: audit.name,
      intervalMonths: Math.abs(intervalMonths),
      intervalWeeks: Math.abs(intervalWeeks),
      intervalDays: Math.abs(intervalDays),
    };
  });

  if (!Object.keys(nextAuditsToBePerformed)?.length && !isDetails) return null;

  const sortedNextAuditsToBePerformed = _.sortBy(nextAuditsToBePerformed, ['nextAuditMillis']);
  const displayDueInfo = sortedNextAuditsToBePerformed?.[0];

  if (!displayDueInfo) {
    if (!isDetails) {
      return null;
    }
    return {
      dueInRelative: '',
      dueAtISO: '',
      dueType: 'default',
      auditType: '',
    };
  }
  const { intervalMonths, intervalWeeks, intervalDays, dueType, auditType, nextAuditISO } = displayDueInfo;

  const dueAtRelative = intervalMonths
    ? maybePluralize(intervalMonths, 'month')
    : intervalWeeks
    ? maybePluralize(intervalWeeks, 'week')
    : maybePluralize(intervalDays, 'day');

  return {
    dueInRelative: dueType === 'error' ? `${dueAtRelative} overdue` : dueAtRelative,
    dueAtISO: nextAuditISO,
    dueType,
    auditType,
  };
};

export const getDueType = (months: number, weeks: number, days: number): 'default' | 'error' | 'warning' => {
  switch (true) {
    case months >= 1:
      return 'default';
    case weeks <= 0 && days <= 0:
      return 'error';
    case weeks <= 3:
      return 'warning';
    default:
      return 'default';
  }
};
