import React, { Dispatch, SetStateAction } from 'react';

import { QAlert, QDatepicker, QFormControl, QInput, QSelect, QStack, QText, QTextarea } from '@qualio/ui-components';
import { useConfigsQuery } from 'hooks';
import { Document, File } from 'providers';
import { v4 as uuidv4 } from 'uuid';
import { EditFileCard, AddDocumentCard } from 'v2components';

type CreateAuditFormProps = { formik: any; setAddAuditSubmitButtonDisabled: Dispatch<SetStateAction<boolean>> };

const CreateAuditForm: React.FC<CreateAuditFormProps> = ({ formik, setAddAuditSubmitButtonDisabled }) => {
  const { auditTypeOptions } = useConfigsQuery();

  const handleAppendFile = (fileType: File['type'], file?: File) => {
    if (fileType === 'QUALIO_DOC') {
      const documents = formik.values.documents || [];
      formik.setFieldValue('documents', [...documents, { file: { type: fileType, id: uuidv4() } }]);
    }
    if (fileType === 'FILE') {
      const documents = formik.values.documents || [];
      const newDocument: Omit<Document, 'required' | 'name'> = {
        file: {
          fileName: file?.fileName,
          id: file?.id as string,
          type: 'FILE',
        },
      };
      formik.setFieldValue('documents', [...documents, newDocument]);
    }
  };

  const handleUpdateFile = (updatedAuditFile: Omit<Document, 'required' | 'name'>) => {
    const documents = [...(formik.values.documents as Omit<Document, 'required' | 'name'>[])];
    const index = documents.findIndex((file) => file.file.id === updatedAuditFile.file.id);
    documents[index] = updatedAuditFile;
    formik.setFieldValue('documents', documents);
  };

  const handleDeleteFile = (auditFile: Omit<Document, 'required' | 'name'>) => {
    const documents = [...(formik.values.documents as Omit<Document, 'required' | 'name'>[])];
    const index = documents.findIndex((file) => file.file.id === auditFile.file.id);
    documents.splice(index, 1);
    formik.setFieldValue('documents', documents);
  };

  const isFieldError = (field: string) => {
    return formik.touched[field] && formik.errors[field];
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <QStack spacing={4}>
        {Object.keys(formik.touched).length && Object.keys(formik.errors).length ? (
          <QAlert status="error" description="Please fil out required fields" />
        ) : null}
        <QText weight="semibold">Audit information</QText>
        <QFormControl label="Audit name*" isInvalid={isFieldError('auditName')} error="Please fill out audit name">
          <QInput name="auditName" onChange={formik.handleChange} value={formik.values.auditName} />
        </QFormControl>
        <QFormControl
          label="Audit type*"
          isInvalid={isFieldError('auditType')}
          error="Please fill out audit type"
          data-cy="audit-type"
        >
          <QSelect
            name="auditType"
            onChange={(e) => formik.setFieldValue('auditType', e)}
            value={formik.values.auditType.value}
            options={auditTypeOptions}
          />
        </QFormControl>
        <QFormControl label="Notes">
          <QTextarea
            {...{ name: 'notes' }}
            onChange={(evt) => formik.handleChange({ ...evt, target: evt.currentTarget })}
            value={formik.values.notes}
          />
        </QFormControl>

        <QFormControl
          label="Completion date"
          data-cy="end-date"
          helper="The completion date determines the next scheduled audit of this type based on the supplier policy."
        >
          <QDatepicker
            onChange={(date: Date) => formik.setFieldValue('endDate', date)}
            value={formik.values.endDate}
            placeholder="dd/mm/yyyy"
            maxDate={new Date()}
          />
        </QFormControl>

        <QText weight="semibold" pt={3}>
          Audit documents
        </QText>
        {formik?.values?.documents?.map((document: Omit<Document, 'required' | 'name'>, index: number) => (
          <EditFileCard
            key={index}
            document={document}
            handleDeleteFile={() => handleDeleteFile(document)}
            handleUpdateFile={(updatedAuditFile) => handleUpdateFile(updatedAuditFile)}
          />
        ))}
        <AddDocumentCard
          handleDocumentAdded={handleAppendFile}
          setAddAuditSubmitButtonDisabled={setAddAuditSubmitButtonDisabled}
        />
      </QStack>
    </form>
  );
};

export default CreateAuditForm;
