import { Payload } from 'types';

export function makeResponse<T extends {}>(body: T = {} as T): Payload<T> {
  return {
    data: body,
  };
}

export function makeEmptyResponse(): Payload<{}> {
  return makeResponse();
}
