import React from 'react';

import { QButton, QHeading, QFlex, QTabs, QSpacer, QBox } from '@qualio/ui-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { routes } from 'utils';

import { POLICY_TABS, activePolicyRouteId } from '../constants/const';

export const PolicyHeader: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <QBox my={4}>
      <QFlex direction="row" justifyContent="space-between">
        <QHeading size={'lg'}>Supplier Policy</QHeading>
        <QButton
          variant="outline"
          onClick={() => navigate(`/${routes.policyConfiguration.root}/${routes.policyConfiguration.supplierTypes}`)}
        >
          Configuration
        </QButton>
      </QFlex>
      <QFlex w="100%" justifyContent="space-between">
        <QSpacer />
        <QTabs
          activeTabId={activePolicyRouteId(pathname)}
          tabs={POLICY_TABS}
          onClick={(tabItem) => navigate(`/${routes.policy.root}/${tabItem.id}`)}
        />
        <QSpacer />
      </QFlex>
    </QBox>
  );
};
