import React from 'react';

import { Container } from './SettingsBody.styles';

interface SettingsBodyProps {
  children: React.ReactNode;
  isEmptyState: boolean;
}

const SettingsBody: React.FC<SettingsBodyProps> = ({ children, isEmptyState }) => {
  return (
    <Container data-cy="settings-body" isEmptyState={isEmptyState}>
      {children}
    </Container>
  );
};

export default SettingsBody;
