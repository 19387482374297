import React from 'react';

import { Route, Routes } from 'react-router-dom';
import { SupplierUpSellStarterView, VideoModal } from 'v2views';

const UPGRADES_CONTACT_EMAIL = 'upgrades@qualio.com';

const UpSellStarterRoutes: React.FC = () => {
  return (
    <Routes>
      <Route
        path="*"
        element={<SupplierUpSellStarterView contactEmail={UPGRADES_CONTACT_EMAIL} />}
        children={
          <Route
            path="product-video"
            element={
              <VideoModal
                title="Optimizing supply chain quality with Qualio"
                videoUrl="https://www.youtube.com/embed/q2Kmug0o-vk"
                findOutMoreUrl="https://docs.qualio.com/en/articles/6010307-suppliers-overview"
                contactEmail={UPGRADES_CONTACT_EMAIL}
              />
            }
          />
        }
      />
    </Routes>
  );
};

export default UpSellStarterRoutes;
