import { CurrentUser, useCurrentUser, useToastProvider } from '@qualio/ui-components';
import supplierApi from 'api/supplier.api';
import { UseMutateFunction, useMutation, useQuery } from 'react-query';
import { Audit } from 'types';

const prepareAuditData = (audit: Audit, currentUser: CurrentUser, isDelete: boolean): Audit => {
  const newAudit: any = { ...audit };

  newAudit.name = newAudit.auditName || newAudit.name;
  newAudit.user = { ...currentUser, id: currentUser.userId };
  newAudit.endDate = newAudit.endDate || undefined;
  newAudit.notes = newAudit.notes || undefined;

  if (isDelete) {
    newAudit.status = 'ARCHIVED';
  }

  if (newAudit.documents?.filter((document: any) => document.file).length) {
    newAudit.documents?.forEach((document: any) => {
      document.file.fileName = document.file.fileName || document.file.name;
    });
  } else {
    newAudit.documents = [];
  }

  newAudit.auditType = {
    title: newAudit?.auditType?.label,
    id: newAudit?.auditType?.value,
  };

  return newAudit;
};

type UseAuditQueryType = {
  audits?: Audit[];
  isAuditsLoading: boolean;
  refetchAudits: () => void;
};

export const useAuditsQuery = (supplierId: string): UseAuditQueryType => {
  const { companyId } = useCurrentUser();

  const {
    data: audits,
    isLoading: isAuditsLoading,
    refetch: refetchAudits,
  } = useQuery(`supplier-audits-${supplierId}`, () => supplierApi.getAuditsBySupplierId(companyId, supplierId));

  return { audits, isAuditsLoading, refetchAudits };
};

type UseAuditMutationType = {
  createAudit: UseMutateFunction<
    Audit,
    unknown,
    {
      auditState: Audit;
      supplierId: string;
    },
    unknown
  >;
  isAuditLoading: boolean;
};

export const useAuditMutation = (onSuccess: () => void): UseAuditMutationType => {
  const { companyId } = useCurrentUser();
  const { showToast } = useToastProvider();
  const user = useCurrentUser();

  const { mutate: createAudit, isLoading: isAuditLoading } = useMutation(
    ({ auditState, supplierId }: { auditState: Audit; supplierId: string }) => {
      const preparedAuditData = prepareAuditData(auditState, user, false);
      return supplierApi.createAudit(companyId, supplierId, preparedAuditData);
    },
    {
      onSuccess: () => {
        showToast({ title: 'Audit added', status: 'success' });
        onSuccess();
      },
      onError: () => {
        showToast({
          title: 'Audit creation Failed',
          description: 'Failed to attach audit to the supplier',
          status: 'error',
        });
      },
    },
  );

  return { createAudit, isAuditLoading };
};

type UseAuditDeleteMutateType = {
  deleteAudit: UseMutateFunction<
    Audit,
    unknown,
    {
      auditState: Audit;
      supplierId: string;
    },
    unknown
  >;
  isAuditDeleteLoading: boolean;
};

export const useAuditDeleteMutate = (onSuccess: () => void): UseAuditDeleteMutateType => {
  const { companyId } = useCurrentUser();
  const { showToast } = useToastProvider();
  const user = useCurrentUser();

  const { mutateAsync: deleteAudit, isLoading: isAuditDeleteLoading } = useMutation(
    ({ auditState, supplierId }: { auditState: Audit; supplierId: string }) => {
      const preparedAuditData = prepareAuditData(auditState, user, true);
      return supplierApi.updateAudit(companyId, supplierId as string, preparedAuditData);
    },
    {
      onSuccess: () => {
        onSuccess();
        showToast({
          title: 'Audit deleted',
          status: 'success',
        });
      },
      onError: () => {
        showToast({
          title: 'Audit deletion failed',
          description: 'Failed to delete audit from the supplier',
          status: 'error',
        });
      },
    },
  );
  return { deleteAudit, isAuditDeleteLoading };
};
