import { IncomingUser, Payload, User } from 'types';

import { makeResponse } from './generic';

export const usersResponse: Payload<User[]> = makeResponse([
  {
    id: 10,
    fullName: 'Victoria Rogers',
    email: 'testuser10@example.com',
    userGroups: ['normal'],
  },
  {
    id: 4,
    fullName: 'Katherine Joyce',
    email: 'testuser4@example.com',
    userGroups: ['normal'],
  },
  {
    id: 1,
    fullName: 'José Bermúdez',
    email: 'testuser1@example.com',
    userGroups: ['normal'],
  },
  {
    id: 2,
    fullName: 'Alice Mills',
    email: 'testuser2@example.com',
    userGroups: ['quality'],
  },
]);

export const userResponse: IncomingUser = {
  id: 2,
  email: 'testuser2@example.com',
  full_name: 'Alice Mills',
  staff: false,
  created_time: 1399554235,
  tz: 'Australia/Adelaide',
  companies: [
    {
      id: 1,
      name: 'Darwin Med Tech',
      created_time: 1399554235,
      status: 'active',
      trial_ends_at: null,
      logo: 'https://api.staging.beta.qualio.com/1/logos/tmp78um8zcv.png',
      sso_enabled: true,
      sso_domain: 'qualio.onmicrosoft.com',
      sso_turned_on: true,
      pricing_plan: 'awesome plan',
      currently_selected: true,
      active: true,
      ghost: false,
      permissions: [
        'can_access_api',
        'can_access_billing',
        'can_activate_users',
        'can_approve_doc',
        'can_author_doc',
        'can_change_doc_owner',
        'can_create_issue',
        'can_deactivate_users',
        'can_edit_products',
        'can_edit_self_account',
        'can_invite_users',
        'can_make_doc_effective',
        'can_manage_cc_templates',
        'can_manage_company_settings',
        'can_manage_company_users',
        'can_manage_idp',
        'can_manage_quality_event_attributes',
        'can_manage_quality_event_workflow',
        'can_manage_review_date',
        'can_manage_tags',
        'can_manage_templates',
        'can_manage_trainees',
        'can_manage_users',
        'can_modify_doc_tags',
        'can_retire_doc',
        'can_review_doc',
        'can_train_on_doc',
        'can_unlock_users',
        'can_view_all_docs',
        'can_view_dashboard',
        'can_view_library',
        'can_view_products',
        'can_view_report',
        'can_view_training_company_dashboard',
        'can_view_workspace',
        'can_work_on_issue',
        'feature_enabled_product_development',
        'feature_enabled_products',
        'feature_enabled_quality_events',
        'feature_enabled_supplier_quality_management',
        'feature_enabled_suppliers',
      ],
      usergroups: ['quality'],
      invite_time: null,
      invite_status: 'accepted',
      feature_flags: [
        {
          flag_name: 'DOCUMENT_EDITOR',
          company_id: 1,
          feature_flag_id: 2,
          active: true,
        },
        {
          flag_name: 'QE_INTEGRATION',
          company_id: 1,
          feature_flag_id: 7,
          active: true,
        },
        {
          flag_name: 'TRAINING_PLANS',
          company_id: 1,
          feature_flag_id: 16,
          active: true,
        },
        {
          flag_name: 'TEMPLATE_RESTORE',
          company_id: 1,
          feature_flag_id: 10,
          active: true,
        },
        {
          flag_name: 'RETRAINING',
          company_id: 1,
          feature_flag_id: 1,
          active: true,
        },
        {
          flag_name: 'QE_REPORTING',
          company_id: 1,
          feature_flag_id: 6,
          active: true,
        },
        {
          flag_name: 'USER_MANAGEMENT_SERVICE',
          company_id: 1,
          feature_flag_id: 17,
          active: true,
        },
        {
          flag_name: 'QE_MICROFRONTEND',
          company_id: 1,
          feature_flag_id: 4,
          active: true,
        },
        {
          flag_name: 'REMOVE_TRAINING',
          company_id: 1,
          feature_flag_id: 12,
          active: true,
        },
        {
          flag_name: 'ENHANCED_DOCUMENT_DOWNLOAD',
          company_id: 1,
          feature_flag_id: 11,
          active: true,
        },
        {
          flag_name: 'HA_DOCUMENT_SEARCH',
          company_id: 1,
          feature_flag_id: 9,
          active: true,
        },
      ],
    },
    {
      id: 4,
      name: 'Gold Flakes Inc.',
      created_time: 1425551584,
      status: 'suspended_trial_ended',
      trial_ends_at: 1426764784,
      logo: null,
      sso_enabled: null,
      sso_domain: null,
      sso_turned_on: false,
      pricing_plan: null,
      currently_selected: false,
      active: true,
      ghost: false,
      permissions: [
        'can_approve_doc',
        'can_author_doc',
        'can_create_issue',
        'can_edit_self_account',
        'can_review_doc',
        'can_train_on_doc',
        'can_view_dashboard',
        'can_view_library',
        'can_view_workspace',
        'can_work_on_issue',
      ],
      usergroups: ['normal'],
      invite_time: 1634921294,
      invite_status: 'accepted',
      feature_flags: [
        {
          flag_name: 'ENHANCED_DOCUMENT_DOWNLOAD',
          company_id: 4,
          feature_flag_id: 11,
          active: true,
        },
        {
          flag_name: 'REMOVE_TRAINING',
          company_id: 4,
          feature_flag_id: 12,
          active: true,
        },
        {
          flag_name: 'QE_REPORTING',
          company_id: 4,
          feature_flag_id: 6,
          active: true,
        },
        {
          flag_name: 'RETRAINING',
          company_id: 4,
          feature_flag_id: 1,
          active: true,
        },
        {
          flag_name: 'TEMPLATE_RESTORE',
          company_id: 4,
          feature_flag_id: 10,
          active: true,
        },
        {
          flag_name: 'HA_DOCUMENT_SEARCH',
          company_id: 4,
          feature_flag_id: 9,
          active: true,
        },
      ],
    },
  ],
  password_status: 'set',
};

export const userResponseGrowthNoPermissions: IncomingUser = {
  id: 2,
  email: 'testuser2@example.com',
  full_name: 'Alice Mills',
  staff: false,
  created_time: 1399554235,
  tz: 'Australia/Adelaide',
  companies: [
    {
      id: 1,
      name: 'Darwin Med Tech',
      created_time: 1399554235,
      status: 'active',
      trial_ends_at: null,
      logo: 'https://api.staging.beta.qualio.com/1/logos/tmp78um8zcv.png',
      sso_enabled: true,
      sso_domain: 'qualio.onmicrosoft.com',
      sso_turned_on: true,
      pricing_plan: 'growth',
      currently_selected: true,
      active: true,
      ghost: false,
      permissions: [
        'can_access_api',
        'can_access_billing',
        'can_activate_users',
        'can_approve_doc',
        'can_author_doc',
        'can_change_doc_owner',
        'can_create_issue',
        'can_deactivate_users',
        'can_edit_products',
        'can_edit_self_account',
        'can_invite_users',
        'can_make_doc_effective',
        'can_manage_cc_templates',
        'can_manage_company_settings',
        'can_manage_company_users',
        'can_manage_idp',
        'can_manage_quality_event_attributes',
        'can_manage_quality_event_workflow',
        'can_manage_review_date',
        'can_manage_tags',
        'can_manage_templates',
        'can_manage_trainees',
        'can_manage_users',
        'can_modify_doc_tags',
        'can_retire_doc',
        'can_review_doc',
        'can_train_on_doc',
        'can_unlock_users',
        'can_view_all_docs',
        'can_view_dashboard',
        'can_view_library',
        'can_view_products',
        'can_view_report',
        'can_view_training_company_dashboard',
        'can_view_workspace',
        'can_work_on_issue',
      ],
      usergroups: ['quality'],
      invite_time: null,
      invite_status: 'accepted',
      feature_flags: [],
    },
  ],
  password_status: 'set',
};

export const userResponseStarter: IncomingUser = {
  id: 2,
  email: 'testuser2@example.com',
  full_name: 'Alice Mills',
  staff: false,
  created_time: 1399554235,
  tz: 'Australia/Adelaide',
  companies: [
    {
      id: 1,
      name: 'Darwin Med Tech',
      created_time: 1399554235,
      status: 'active',
      trial_ends_at: null,
      logo: 'https://api.staging.beta.qualio.com/1/logos/tmp78um8zcv.png',
      sso_enabled: true,
      sso_domain: 'qualio.onmicrosoft.com',
      sso_turned_on: true,
      pricing_plan: 'starter',
      currently_selected: true,
      active: true,
      ghost: false,
      permissions: [
        'can_access_api',
        'can_access_billing',
        'can_activate_users',
        'can_approve_doc',
        'can_author_doc',
        'can_change_doc_owner',
        'can_create_issue',
        'can_deactivate_users',
        'can_edit_products',
        'can_edit_self_account',
        'can_invite_users',
        'can_make_doc_effective',
        'can_manage_cc_templates',
        'can_manage_company_settings',
        'can_manage_company_users',
        'can_manage_idp',
        'can_manage_quality_event_attributes',
        'can_manage_quality_event_workflow',
        'can_manage_review_date',
        'can_manage_tags',
        'can_manage_templates',
        'can_manage_trainees',
        'can_manage_users',
        'can_modify_doc_tags',
        'can_retire_doc',
        'can_review_doc',
        'can_train_on_doc',
        'can_unlock_users',
        'can_view_all_docs',
        'can_view_dashboard',
        'can_view_library',
        'can_view_products',
        'can_view_report',
        'can_view_training_company_dashboard',
        'can_view_workspace',
        'can_work_on_issue',
        'feature_enabled_product_development',
        'feature_enabled_products',
        'feature_enabled_quality_events',
        'feature_enabled_supplier_quality_management',
        'feature_enabled_suppliers',
      ],
      usergroups: ['quality'],
      invite_time: null,
      invite_status: 'accepted',
      feature_flags: [],
    },
  ],
  password_status: 'set',
};
