import React, { useEffect, useState } from 'react';

import { QBox, QCenter, QFlex, QIcon, QSpinner, QText, QTooltip, useCurrentUser } from '@qualio/ui-components';
import supplierApi from 'api/supplier.api';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useParams } from 'react-router-dom';
import { ConfigTypeOptions } from 'v2types';

import { StyledDataTable, StyledSupplier, StyledSupplierPolicyContainer } from '../styles/SupplierPolicy.styles';
import { useRouterOutletSupplierPolicy } from '../SupplierPolicyView';
import SupplierPolicyCell from './PolicyCell';

interface DataRow {
  title: string;
  id?: string;
  description?: string;
}

export const Policy: React.FC = () => {
  const {
    isConfigsLoading,
    supplierTypeOptions,
    riskTypeOptions,
    auditTypeOptions,
    documentTypeOptions,
    policyConfigFromAPI,
    policyConfig: outletPolicyConfig,
    refetchPolicies,
  } = useRouterOutletSupplierPolicy();

  const { policyType } = useParams();
  const [policyConfig, setPolicyConfig] = useState<any>(outletPolicyConfig);
  const { companyId } = useCurrentUser();

  useEffect(() => {
    setPolicyConfig(policyConfigFromAPI);
  }, [policyConfigFromAPI]);

  if (isConfigsLoading) {
    return (
      <QCenter>
        <QSpinner />
      </QCenter>
    );
  }

  const updatePolicy = async (
    supplierType: string,
    riskLevel: string,
    policyConfigIndex: string,
    newPolicyItemData: any,
    type = 'documents',
    method = 'add',
    config: any,
  ) => {
    const newPolicyConfig = { ...policyConfig };

    const itemIndex = newPolicyConfig[policyConfigIndex]?.linkData.findIndex(
      (item: any) =>
        (item?.linkData?.value || item?.linkData?.id) === (config?.linkData?.id || config?.linkData?.value),
    );

    if (method === 'add') {
      if (newPolicyConfig[policyConfigIndex]) {
        newPolicyConfig[policyConfigIndex].linkData.push({
          linkData: {
            id: newPolicyItemData.value,
            title: newPolicyItemData.label,
            periodic_review: newPolicyItemData.periodic_review,
          },
          linkType: type,
          supplierType,
          riskLevel,
        });
      } else {
        newPolicyConfig[policyConfigIndex] = {
          supplierType,
          riskLevel,
          linkData: [],
        };

        newPolicyConfig[policyConfigIndex].linkData = [
          {
            linkData: {
              id: newPolicyItemData.value,
              title: newPolicyItemData.label,
              periodic_review: newPolicyItemData.periodic_review,
            },
            linkType: type,
            supplierType,
            riskLevel,
          },
        ];
      }
    } else if (method === 'update') {
      const linkData = newPolicyConfig[policyConfigIndex].linkData[itemIndex].linkData;
      newPolicyConfig[policyConfigIndex].linkData[itemIndex].linkData = {
        id: linkData.value || linkData.id,
        title: linkData.label || linkData.title,
        periodic_review: newPolicyItemData.value,
      };
    } else {
      newPolicyConfig[policyConfigIndex].linkData.splice(itemIndex, 1);
    }

    setPolicyConfig(newPolicyConfig);
  };

  const savePolicy = async (policyConfigIndex: string) => {
    if (!policyConfigFromAPI[policyConfigIndex] && !policyConfig[policyConfigIndex]) {
      return new Promise((resolve) => resolve(true));
    }
    await supplierApi.addPolicyLink(companyId, policyConfig[policyConfigIndex]);

    await refetchPolicies();
  };

  const generateColumns = (): TableColumn<DataRow>[] => {
    const additionalColumns = riskTypeOptions.map((option: ConfigTypeOptions) => ({
      name: (
        <QFlex direction="row" alignItems="center" gap={'1'}>
          <QText fontSize="sm" weight="semibold" className="column-header">
            {option.payload?.title}
          </QText>
          <QTooltip label={option.payload?.description ?? ''}>
            <QFlex alignItems="center">
              <QIcon iconName="Info" />
            </QFlex>
          </QTooltip>
        </QFlex>
      ),
      data: {
        title: option.payload?.title,
        id: option.payload?.id,
        description: option.payload?.description,
      },
      cell: (row: DataRow, index: number, column: any) => (
        <SupplierPolicyCell
          view={policyType === 'audit' ? 'AUDITS' : 'DOCUMENTS'}
          key={`${row.id}-${column.data.id}`}
          row={row}
          column={column}
          documentTypes={documentTypeOptions}
          auditTypes={auditTypeOptions}
          policyConfigFromAPI={
            policyConfigFromAPI[`${row.id}-${column.data.id}`] && policyConfigFromAPI
              ? policyConfigFromAPI[`${row.id}-${column.data.id}`]
              : []
          }
          policyConfig={
            policyConfig[`${row.id}-${column.data.id}`] && policyConfig
              ? policyConfig[`${row.id}-${column.data.id}`]
              : []
          }
          policyConfigIndex={`${row.id}-${column.data.id}`}
          setPolicyConfig={updatePolicy}
          savePolicy={savePolicy}
        />
      ),
    }));
    return [
      {
        name: (
          <StyledSupplier size="sm" weight="bold">
            SUPPLIER TYPE
          </StyledSupplier>
        ),
        cell: (row) => (
          <QFlex direction="row" alignItems="center" gap={'1'}>
            <QText fontSize="sm" weight="semibold">
              {row.title}
            </QText>
            <QTooltip label={row.description ?? ''}>
              <QFlex alignItems="center">
                <QIcon iconName="Info" />
              </QFlex>
            </QTooltip>
          </QFlex>
        ),
      },
      ...additionalColumns,
    ];
  };

  const generateData = () => {
    return supplierTypeOptions.map((supplierType: ConfigTypeOptions) => ({
      title: supplierType.payload?.title,
      id: supplierType.payload?.id || undefined,
      description: supplierType.payload?.description,
    }));
  };

  const columns = generateColumns();
  const data = generateData();

  return (
    <QBox mt={4}>
      <StyledSupplierPolicyContainer>
        <div className="policy-lead-title">
          <div className="empty" />
          <div className="column-header">
            <QText fontSize="sm" weight="semibold">
              RISK LEVEL
            </QText>
          </div>
        </div>
        <StyledDataTable>
          <DataTable columns={columns} data={data} />
        </StyledDataTable>
      </StyledSupplierPolicyContainer>
    </QBox>
  );
};
