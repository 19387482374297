import * as z from 'zod';

type AuditFormType = 'auditName' | 'auditType' | 'notes' | 'endDate' | 'documents';
type AuditFormValue = {
  defaultValue: any;
  validation: any;
};

const NEW_AUDIT_FORM: Record<AuditFormType, AuditFormValue> = {
  auditName: {
    defaultValue: '',
    validation: z.string().min(5),
  },
  auditType: {
    defaultValue: '',
    validation: z.object({ label: z.string(), value: z.string() }),
  },
  notes: { defaultValue: '', validation: z.string().optional().nullable() },
  endDate: { defaultValue: '', validation: z.date().optional() },
  documents: {
    defaultValue: [],
    validation: z.array(
      z.object({
        file: z.object({ fileName: z.string(), description: z.string().optional(), id: z.string().optional() }),
      }),
    ),
  },
};

export const getAuditFormObject = (formItem: keyof AuditFormValue): any => {
  const returnedObject: any = {};
  Object.entries(NEW_AUDIT_FORM).forEach(([key, value]) => {
    returnedObject[key] = value[formItem];
  });
  return returnedObject;
};
