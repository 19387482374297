import React from 'react';

import {
  QAlert,
  QStack,
  QInput,
  QModal,
  QFormControl,
  QText,
  QTextarea,
  QModalHeader,
  QModalBody,
  QModalActions,
  QButton,
  useToastProvider,
  useCurrentUser,
} from '@qualio/ui-components';
import supplierApi from 'api/supplier.api';
import { FormikProps, useFormik } from 'formik';
import { useMutation } from 'react-query';
import { Supplier, SupplierStatus } from 'types';
import { showConfetti } from 'utils/confetti';
import * as z from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';

type StatusChangeModalProps = {
  isVisible: boolean;
  onClose: () => void;
  onSuccess: () => void;
  current: Supplier | undefined;
  status: string | undefined;
};

export type UpdateSupplierStatusValues = {
  status: string;
};

const ApprovalSchema: z.ZodObject<{
  email: z.ZodString;
  password: z.ZodString;
}> = z.object({
  email: z.string().min(1).email(),
  password: z.string().min(1),
});

const RejectionSchema: z.ZodObject<{
  email: z.ZodString;
  password: z.ZodString;
  reason: z.ZodString;
}> = z
  .object({
    reason: z.string().min(1),
  })
  .merge(ApprovalSchema);

type Credentials = {
  email: string;
  password: string;
  reason?: string;
};

const StatusChangeModal: React.FC<StatusChangeModalProps> = ({ isVisible, onClose, onSuccess, current, status }) => {
  const { companyId } = useCurrentUser();
  const { showToast } = useToastProvider();
  const schema = status === 'APPROVED' ? ApprovalSchema : RejectionSchema;
  const formik: FormikProps<Credentials> = useFormik<Credentials>({
    initialValues: {
      email: '',
      password: '',
      reason: '',
    },
    validationSchema: toFormikValidationSchema(schema),
    onSubmit: (values) => submitForm(companyId, values),
  });

  const { isError, mutate, isLoading } = useMutation(
    ({ company, payload }: { company: number; payload: UpdateSupplierStatusValues }) =>
      supplierApi.updateSupplierStatus(company, current?.supplier, payload),
    {
      onSuccess: (result: { status: SupplierStatus }) => {
        showToast({ status: 'success', title: 'Approval action completed' });
        if (result.status === SupplierStatus.enum.APPROVED) {
          showConfetti();
        }
        onSuccess();
        closeModal();
      },
    },
  );

  const submitForm = async (company: number, e: any) => {
    if (!status) return;
    const payload = { status: status, email: e.email, password: e.password, reason: e.reason };
    if (status === 'APPROVED') delete payload.reason;
    mutate({ company, payload });
  };

  function closeModal() {
    formik.resetForm();
    onClose();
  }

  const text = status === 'APPROVED' ? 'Approve' : 'Reject';

  const { email, password, reason } = formik.values;

  const isFieldError = (field: any) => {
    return field && formik.touched[field as keyof Credentials] && formik.errors[field as keyof Credentials];
  };

  return (
    <QModal isOpen={isVisible} onClose={closeModal}>
      <QModalHeader>
        <QText>{`${text} supplier`}</QText>
      </QModalHeader>
      <QModalBody>
        <form onSubmit={formik.handleSubmit}>
          <QStack spacing={4}>
            {Object.keys(formik.touched).length && Object.keys(formik.errors).length ? (
              <QAlert status="error" description="Please fil out required fields" />
            ) : null}
            {isError ? <QAlert status="error" description="Something went wrong" /> : null}
            {status !== 'APPROVED' ? (
              <QText pb={2}>Enter your password to reject this supplier.</QText>
            ) : (
              <QText pb={2}>Enter your password to approve this supplier.</QText>
            )}
            <QFormControl label="Email" isInvalid={isFieldError('email')} error="Please enter an email">
              <QInput name="email" onChange={formik.handleChange} value={email} />
            </QFormControl>
            <QFormControl label="Password" isInvalid={isFieldError('password')} error="Please enter a password">
              <QInput name="password" type="password" onChange={formik.handleChange} value={password} />
            </QFormControl>
            {status !== 'APPROVED' && (
              <QFormControl
                label="Reason for rejection"
                isInvalid={isFieldError('reason')}
                error="Please enter a reason to reject this supplier"
              >
                <QTextarea
                  name="reason"
                  onChange={(evt) => formik.handleChange({ ...evt, target: evt.currentTarget })}
                  value={reason}
                />
              </QFormControl>
            )}
          </QStack>
        </form>
      </QModalBody>
      <QModalActions>
        <QButton onClick={closeModal} variant="outline">
          Cancel
        </QButton>
        <QButton
          onClick={() => formik.handleSubmit()}
          isDestructive={text === 'Reject' ? true : false}
          isLoading={isLoading}
        >
          {text}
        </QButton>
      </QModalActions>
    </QModal>
  );
};

export default StatusChangeModal;
