export const SUPPLIER_STATUS: any = {
  APPROVED: {
    type: 'success',
    text: 'Approved',
    tagColor: 'green',
  },
  REVIEW_PENDING: {
    type: 'info',
    text: 'Pending',
    tagColor: 'blue',
  },
  REJECTED: {
    type: 'error',
    text: 'Rejected',
    tagColor: 'red',
  },
  DRAFT: {
    type: 'default',
    text: 'Draft',
    tagColor: 'default',
  },
  ARCHIVED: {
    type: 'warning',
    text: 'Archived',
  },
};

export const REVIEW_STATUS = {
  APPROVED: {
    type: 'success',
    text: 'Approved',
    tagColor: 'green',
  },
  PENDING: {
    type: 'info',
    text: 'Pending',
    tagColor: 'blue',
  },
  REJECTED: {
    type: 'error',
    text: 'Rejected',
    tagColor: 'red',
  },
  ARCHIVED: {
    type: 'warning',
    text: 'Archived',
  },
};

export const STATUS_CELL_MAPPING = { ...SUPPLIER_STATUS, ...REVIEW_STATUS };
