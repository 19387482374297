import React from 'react';

import { Route, Routes } from 'react-router-dom';

import { SupplierUpSellView } from '../v2views';

const UpSellRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="*" element={<SupplierUpSellView />} />
    </Routes>
  );
};

export default UpSellRoutes;
