import React from 'react';

import { QAlert, QBox, QLink, QStack, QText } from '@qualio/ui-components';
import { getRequiredAudits, maybePluralize } from 'lib/utils';
import { DateTime } from 'luxon';
import { Audit } from 'types';
import _ from 'underscore';
import { routes } from 'utils';
import { ConfigItem } from 'v2types';

type NextAuditProps = {
  audits: Audit[] | undefined;
  policyLinks?: any;
  supplierType: ConfigItem;
  riskLevel?: ConfigItem;
  auditTypes?: ConfigItem[];
  supplier?: string;
  isDetails?: boolean;
};

const NextAudit: React.FC<NextAuditProps> = ({
  audits,
  policyLinks,
  supplierType,
  riskLevel,
  auditTypes,
  supplier,
  isDetails,
}) => {
  if (!audits?.length && !isDetails) return <>--</>;

  const groupedPerformedAudits = _.groupBy(audits || [], function (item) {
    return item instanceof Array ? item[0].auditType?.id : item.auditType?.id;
  });

  const groupedLatestPerformedAudit: Record<string, number> = {};
  const requiredAuditsToBePerformed: Record<string, { name: string; frequency: number }> = {};
  const nextAuditsToBePerformed: any = {};

  const requiredAudits = getRequiredAudits(policyLinks, auditTypes, supplierType, riskLevel);

  if (!requiredAudits.length) return isDetails ? null : <>--</>;

  requiredAudits.forEach(
    ({ id, name, frequency }) =>
      (requiredAuditsToBePerformed[id] = {
        name,
        frequency: parseInt(frequency),
      }),
  );

  Object.keys(groupedPerformedAudits).forEach((performedAuditType) => {
    if (performedAuditType === 'undefined') return;

    const auditDates = groupedPerformedAudits[performedAuditType].map((audit) => {
      const date = new Date(audit.endDate || audit.modified || '').getTime();
      return Math.round(date);
    });

    groupedLatestPerformedAudit[performedAuditType] = Math.max(...auditDates);
  });

  Object.entries(groupedLatestPerformedAudit).forEach(([id, lastAuditTimeMillis]) => {
    const audit = requiredAuditsToBePerformed[id];
    if (!audit) {
      console.warn('Did not find a required audit for id', { id });
      return;
    }
    const frequency = audit.frequency;
    if (!frequency) {
      console.warn('Expected a valid frequency as a number of months', { id, frequency });
      return;
    }
    if (!lastAuditTimeMillis) {
      console.warn('Expected a valid last audit date', { id, lastAuditTimeMillis });
      return;
    }
    const lastAuditDate = DateTime.fromMillis(lastAuditTimeMillis);
    const nextAuditDate = lastAuditDate.plus({ months: frequency }).toUTC();

    const interval = nextAuditDate.diff(DateTime.now(), ['months', 'weeks', 'days']);

    const intervalMonths = Math.round(interval.toObject().months || 0);
    const intervalWeeks = Math.round(interval.toObject().weeks || 0);
    const intervalDays = Math.round(interval.toObject().days || 0);

    const frequencyInMillis = frequency * 30 * 24 * 60 * 60 * 1000 || 0;
    nextAuditsToBePerformed[id] = {
      lastAuditDate: lastAuditDate.toLocaleString(DateTime.DATE_MED),
      frequency,
      frequencyInMillis,
      nextAuditMillis: nextAuditDate.toMillis(),
      nextAuditISO: nextAuditDate.toISO(),
      interval: `${intervalMonths} months ${intervalWeeks} weeks ${intervalDays} days`,
      supplier: supplier,
      intervalRaw: interval,
      dueType: getDueType(intervalMonths, intervalWeeks, intervalDays),
      auditType: audit.name,
      intervalMonths: Math.abs(intervalMonths),
      intervalWeeks: Math.abs(intervalWeeks),
      intervalDays: Math.abs(intervalDays),
    };
  });

  if (!Object.keys(nextAuditsToBePerformed)?.length && !isDetails) return <>--</>;

  const sortedNextAuditsToBePerformed = _.sortBy(nextAuditsToBePerformed, ['nextAuditMillis']);
  const displayDueInfo = sortedNextAuditsToBePerformed?.[0];

  if (!displayDueInfo && !isDetails) return <>--</>;
  if (!sortedNextAuditsToBePerformed.length && isDetails) return <></>;

  return (
    <QBox pb={2}>
      <QStack>
        {sortedNextAuditsToBePerformed?.map(
          ({ intervalMonths, intervalWeeks, intervalDays, dueType, auditType, lastAuditDate, frequency }, index) => (
            <QAlert
              key={index}
              description={getDescription(auditType, frequency, lastAuditDate)}
              title={getTitle(intervalMonths, intervalWeeks, intervalDays, dueType, auditType)}
              status={dueType ? dueType : 'info'}
            />
          ),
        )}
      </QStack>
    </QBox>
  );
};
export default NextAudit;

const getDueType = (months: number, weeks: number, days: number) => {
  let type = 'info';

  switch (true) {
    case months >= 1:
      type = 'info';
      break;
    case weeks <= 0 && days <= 0:
      type = 'error';
      break;
    case weeks <= 3:
      type = 'warning';
      break;
    default:
      type = 'info';
      break;
  }

  return type;
};

const getTitle = (
  intervalMonths: number,
  intervalWeeks: number,
  intervalDays: number,
  dueType: string,
  auditType: string,
) => {
  return `${dueType !== 'error' ? 'Next ' : ''}
    ${auditType} Audit is ${dueType !== 'error' ? 'due in' : ''}
    ${
      intervalMonths
        ? maybePluralize(intervalMonths, 'month')
        : intervalWeeks
        ? maybePluralize(intervalWeeks, 'week')
        : maybePluralize(intervalDays, 'day')
    }
    ${dueType === 'error' ? ' overdue' : ''}.`;
};

const getDescription = (auditType: string, frequency: number, lastAuditDate: string) => {
  return (
    <QText>
      The{' '}
      <QLink href={`/sqm/${routes.policy.root}/${routes.policy.audits}`} isExternal>
        supplier policy
      </QLink>{' '}
      requires this supplier to be audited with a {auditType} audit every {maybePluralize(frequency, 'month')} from the
      date of last audit ({lastAuditDate})
    </QText>
  );
};
