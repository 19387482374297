import * as z from 'zod';

export const QualioUserGroup = z.enum(['normal', 'quality', 'read_only']);

export const QualioCompany = z.object({
  id: z.number(),
  name: z.string(),
});
export type QualioCompany = z.infer<typeof QualioCompany>;

export const QualioUser = z.object({
  id: z.number(),
  fullName: z.string(),
  full_name: z.string().optional(),
  email: z.string().email(),
  userGroups: z.array(QualioUserGroup).optional(),
  companies: z.array(QualioCompany).optional(),
});
export type QualioUser = z.infer<typeof QualioUser>;
