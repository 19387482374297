import React from 'react';

import type { ConfigTypeOptions } from 'v2types';

import { useConfigsQuery } from './useConfigs';

type ResolverFunc = (id: string, type: 'documents' | 'audits') => string | undefined;

export type UseTitleResolverResult = { resolveTitle: ResolverFunc; isLoading: boolean };

// The function signatures that follow here are hints for typescript to help you use this hook.
// For more info, see https://www.typescriptlang.org/docs/handbook/2/functions.html#function-overloads
//
// You probably don't need to care though, and should scroll down to the implementation.

/**
 * Returns a function that can be used to resolve the title of a document or audit.
 * The function will return `undefined` if the title cannot be resolved or if the
 * document or audit types are still being loaded.
 */

export function useTitleResolver(): UseTitleResolverResult;

/**
 * Returns a function that can be used to resolve the title of a document or audit.
 * `documentTypes` will be used (and titles for documents may be resolved immediately),
 * however, `auditTypes` will be fetch and may not be resolved until `isLoading` is false.
 */
export function useTitleResolver(documentTypes: readonly ConfigTypeOptions[]): UseTitleResolverResult;

/**
 * Returns a function that can be used to resolve the title of a document or audit.
 * `auditTypes` will be used (and titles for audits may be resolved immediately),
 * however, `documentTypes` will be fetch and may not be resolved until `isLoading` is false.
 */
export function useTitleResolver(
  documentTypes: undefined,
  auditTypes: readonly ConfigTypeOptions[],
): UseTitleResolverResult;

/**
 * Returns a function that can be used to resolve the title of a document or audit.
 * Because both `documentTypes` and `auditTypes` are provided, titles may be resolved immediately.
 * `isLoading` is guarenteed to be `false`.
 */
export function useTitleResolver(
  documentTypes: readonly ConfigTypeOptions[],
  auditTypes: readonly ConfigTypeOptions[],
): { resolveTitle: ResolverFunc; isLoading: false };

// --- Implementation ---
// Hello future developer: yes, you are welcome to extend this to handle suppliers and anything
// else that comes from the `useConfigsQuery`.
export function useTitleResolver(
  documentTypes?: readonly ConfigTypeOptions[],
  auditTypes?: readonly ConfigTypeOptions[],
): UseTitleResolverResult {
  const queryEnabled = !documentTypes || !auditTypes;

  const queryResult = useConfigsQuery({
    enabled: queryEnabled,
  });

  // If documentTypes or auditTypes are provided, use them instead of the query result.
  const documentTypeOptions = React.useMemo(
    () => documentTypes || queryResult.documentTypeOptions || [],
    [documentTypes, queryResult.documentTypeOptions],
  );
  const auditTypeOptions = React.useMemo(
    () => auditTypes || queryResult.auditTypeOptions || [],
    [auditTypes, queryResult.auditTypeOptions],
  );

  const resolveTitle = React.useCallback(
    (id: string, type: 'documents' | 'audits'): string | undefined => {
      const documentOrAudits = type === 'documents' ? documentTypeOptions : auditTypeOptions;
      const documentOrAudit = documentOrAudits.filter((d) => d.value === id);

      return documentOrAudit?.[0]?.payload?.title;
    },
    [documentTypeOptions, auditTypeOptions],
  );

  return { resolveTitle, isLoading: queryEnabled && queryResult.isConfigsLoading };
}
