import React, { useCallback } from 'react';

import {
  QButton,
  QEmptyState,
  QEmptyStateButtonGroup,
  QEmptyStatePrimaryButton,
  QEmptyStateSecondaryButtonGroup,
} from '@qualio/ui-components';
import { Outlet, useNavigate } from 'react-router-dom';

export type SupplierUpSellStarterViewProps = {
  contactEmail: string;
};

const SupplierUpSellStarterView: React.FC<SupplierUpSellStarterViewProps> = ({ contactEmail }) => {
  const navigate = useNavigate();
  const showProductVideo = useCallback(() => {
    navigate('/product-video');
  }, [navigate]);
  const openContact = useCallback(() => {
    window.open(`mailto:${contactEmail}`);
  }, [contactEmail]);
  return (
    <>
      <div data-cy={'upsell'}>
        <QEmptyState
          title="Trying to do some supply chain work?"
          subtitle={[
            'Unfortunately, you currently don’t have access to the Suppliers module.',
            'Contact our support team so you can easily onboard, manage',
            'and continuously improve your supplier base.',
          ]}
        >
          <QEmptyStateButtonGroup>
            <QEmptyStatePrimaryButton>
              <QButton onClick={openContact}>Contact Qualio support</QButton>
            </QEmptyStatePrimaryButton>
            <QEmptyStateSecondaryButtonGroup>
              <QButton onClick={showProductVideo} variant="outline">
                Watch product video
              </QButton>
            </QEmptyStateSecondaryButtonGroup>
          </QEmptyStateButtonGroup>
        </QEmptyState>
      </div>
      <Outlet />
    </>
  );
};

export default SupplierUpSellStarterView;
