import React from 'react';

import { QFlex, QIcon, QStack, QText } from '@qualio/ui-components';
import { Document, File } from 'providers/types';
import { v4 as uuidv4 } from 'uuid';
import { AddDocumentCard } from 'v2components';

import { SupportingDocumentFile } from '../SupportingDocumentFile/SupportingDocumentFile';

type SupportingDocumentProps = {
  index: number;
  fileInputIndex: number;
  document: Document;
  documentUpdated: (document: Document) => void;
};

export const SupportingDocument: React.FC<SupportingDocumentProps> = ({
  document,
  documentUpdated,
  fileInputIndex,
}) => {
  const handleFileCardRemoved = async (fileId: string) => {
    if (document.files) {
      const updatedFilesArray: File[] = [...document.files];
      const fileIndex = updatedFilesArray.findIndex((file) => file.id === fileId);
      updatedFilesArray.splice(fileIndex, 1);
      const updatedDocument: Document = {
        ...document,
        files: updatedFilesArray,
        // PS-2901: If the last file is removed, the file property should be removed from the document.
        file: updatedFilesArray.length ? document.file : undefined,
      };
      documentUpdated(updatedDocument);
    }
  };

  const handleFileUpdated = (incomingFile: File) => {
    if (document.files) {
      const updatedFilesArray: File[] = [...document.files];
      const fileIndex = updatedFilesArray.findIndex((file) => file.id === incomingFile.id);
      updatedFilesArray[fileIndex] = incomingFile;
      const updatedDocument: Document = { ...document, files: updatedFilesArray };
      documentUpdated(updatedDocument);
    }
  };

  const handleFileAppended = (fileType: File['type'], file?: File) => {
    if (fileType === 'QUALIO_DOC') {
      const newFiles = document?.files ? [...document.files, { type: fileType, id: uuidv4() }] : [];
      const newDocument = { ...document, files: newFiles };
      documentUpdated(newDocument);
    }
    if (fileType === 'FILE') {
      const newFile: File = {
        fileName: file?.fileName,
        id: file?.id as string,
        type: 'FILE',
      };
      const newFiles = document?.files ? [...document.files, newFile] : [];
      const newDocument = { ...document, files: newFiles };
      documentUpdated(newDocument);
    }
  };

  return (
    <QStack data-cy="document-card" spacing={4}>
      <QText weight="semibold" color="gray.800">
        {document.name}
      </QText>
      {!document.required ? (
        <QFlex align="center">
          <QIcon iconName="Info" color="gray.500" />
          <QText fontSize="sm" color="gray.500" pl={2}>
            Include any additional documentation that is relevant for this supplier.
          </QText>
        </QFlex>
      ) : null}
      {document.files?.map((item: File, index: number) => (
        <SupportingDocumentFile
          key={index}
          file={item}
          handleUpdateFile={(file: File) => handleFileUpdated(file)}
          handleDeleteFile={() => handleFileCardRemoved(item.id)}
        />
      ))}
      {<AddDocumentCard handleDocumentAdded={handleFileAppended} fileInputIndex={fileInputIndex} />}
    </QStack>
  );
};
