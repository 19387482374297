import React, { MouseEvent } from 'react';

import { useNavigate } from 'react-router-dom';

import { StyledAnchorTag } from './HistoryLink.styles';

type Props = {
  to: string;
  useReplace?: boolean;
} & React.HTMLAttributes<HTMLElement>;

const HistoryLink: React.FC<Props> = ({ to, useReplace, children, ...otherProps }) => {
  const navigate = useNavigate();
  function handleClick(ev: MouseEvent<HTMLAnchorElement>) {
    const origin = window.location.origin;
    const locationUrl = `${origin}/sqm${to}`;
    ev.preventDefault();
    ev.stopPropagation();
    useReplace ? (window.location.href = locationUrl) : navigate(to);
  }

  return (
    <StyledAnchorTag data-cy="history-link" onClick={handleClick} {...otherProps}>
      {children}
    </StyledAnchorTag>
  );
};

export default HistoryLink;
