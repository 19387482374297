import { api, medtechApi } from './index';

const qualioApi = {
  async fuzzySearchQualioDocs(company: number, searchString: string): Promise<any[] | undefined> {
    if (company) {
      const res = await api.get(`/company/${company}/sqm/smartlink?search=${searchString}`);
      return res.data;
    }
  },

  async getUserById(userId: number): Promise<any | undefined> {
    return (await medtechApi.get(`/users/${userId}`)).data;
  },
};

export default qualioApi;
