export const isProduction = (): boolean => {
  return process.env.REACT_APP_ENV === 'production';
};

export const isLocal = (): boolean => {
  return process.env.REACT_APP_ENV === 'local';
};

/**
 * Returns the URL for a static asset (the cloudfront URL).
 * Intended for use with directly imported assets (e.g. fonts).
 *
 * @param path The path to the asset, as given by a direct import.
 */
export const StaticAssetUrlFor = (path: string): string => {
  return (process.env.REACT_APP_ASSETS_BASE_URL ?? '') + path;
};
