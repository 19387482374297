import React, { useEffect, useState } from 'react';

import { QSpinner } from '@qualio/ui-components';
import { useCompanyUsersQuery, useConfigsQuery, useSupplierQuery } from 'hooks';
import { transformApiPayloadToFormik } from 'lib';
import { useParams } from 'react-router-dom';
import { AddSupplierFormikPayload, addSupplierInitialValues, UpsertSupplierApiPayload } from 'v2types';
import { AddSupplierPresentational } from 'v2views';

export const AddSupplierView: React.FC = () => {
  const { companyUsers } = useCompanyUsersQuery();
  const { riskTypeOptions } = useConfigsQuery();
  const { supplierId } = useParams<{ supplierId: string }>();
  const { supplier, isSupplierLoading } = useSupplierQuery(supplierId);
  const [supplierFormikValues, setSupplierFormikValues] = useState<AddSupplierFormikPayload>(addSupplierInitialValues);

  useEffect(() => {
    if (companyUsers && companyUsers.length > 0 && supplier && supplierId) {
      setSupplierFormikValues((prev) => ({
        ...prev,
        ...transformApiPayloadToFormik(supplier as UpsertSupplierApiPayload, companyUsers, riskTypeOptions),
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(companyUsers), JSON.stringify(supplier), supplierId, riskTypeOptions]);

  return (
    <>
      {isSupplierLoading || (supplierId && !supplierFormikValues.supplier) ? (
        <QSpinner />
      ) : (
        <AddSupplierPresentational formikValues={supplierFormikValues} isSupplierLoading={isSupplierLoading} />
      )}
    </>
  );
};
