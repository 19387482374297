import { SUPPLIER_STATUS } from 'lib';
import { File, Document } from 'providers/types';
import { Supplier, Audit, User } from 'types';
import { NextAuditDueInfo } from 'utils';
import { ConfigItem, AddSupplierFormikPayload, UpsertSupplierApiPayload, ConfigTypeOptions } from 'v2types';

export const transformApiPayloadToFormik = (
  incomingSupplier: UpsertSupplierApiPayload,
  users: User[],
  riskTypeOptions: ConfigTypeOptions[],
): AddSupplierFormikPayload => {
  const riskConfigTypeOptions = riskTypeOptions.find((option) => option.value === incomingSupplier.riskType?.id);

  const editSupplier: AddSupplierFormikPayload = {
    ...incomingSupplier,
    name: incomingSupplier.name,
    approvers:
      incomingSupplier?.approvers?.map((item) => ({
        ...item,
        label: item.fullName,
        value: item.id.toString(),
        userGroups: getUser(users, '' + item.id)?.userGroups || [],
      })) || [],
    sponsor: {
      ...incomingSupplier.sponsor,
      label: incomingSupplier.sponsor?.fullName,
      value: incomingSupplier.sponsor?.id?.toString(),
    },
    supplierType: {
      label: incomingSupplier?.supplierType?.title || '',
      value: incomingSupplier?.supplierType?.id || '',
      payload: { ...incomingSupplier?.supplierType },
    },
    riskType: {
      id: riskConfigTypeOptions?.payload?.id ?? incomingSupplier.riskType?.id,
      title: riskConfigTypeOptions?.payload?.title ?? incomingSupplier.riskType?.title,
      description: riskConfigTypeOptions?.payload?.description ?? incomingSupplier.riskType?.description,
      periodic_review: incomingSupplier.riskType?.periodic_review,
    },
    documents: [],
  };

  const incomingRequiredDocuments =
    incomingSupplier?.documents?.filter((document) => !(document?.title?.toLowerCase() === 'additional documents')) ||
    [];

  incomingRequiredDocuments.forEach((item) => {
    editSupplier.documents.push({ ...item, name: item.title, required: true });
  });

  const incomingAdditionalDocuments =
    incomingSupplier?.documents?.filter((document) => document?.title?.toLowerCase() === 'additional documents') || [];

  if (!incomingAdditionalDocuments.length) {
    editSupplier.documents.push({ files: [], name: 'Additional documents', required: false });
  } else {
    const combinedAdditionalDocumentsFiles: any[] = [];

    incomingAdditionalDocuments.forEach((document) => {
      combinedAdditionalDocumentsFiles.push(...document.files);
    });

    editSupplier.documents.push({
      files: combinedAdditionalDocumentsFiles,
      name: 'Additional documents',
      required: false,
    });
  }

  return editSupplier;
};

export const transformFormikToApiPayload = (payload: AddSupplierFormikPayload): UpsertSupplierApiPayload => {
  let modifiedPayload: any = { ...payload };

  const removableKeys = [
    'contactAddress',
    'contactName',
    'contactNotes',
    'contactPhone',
    'contactEmail',
    'intendedUse',
    'website',
    'approvalDate',
    'contactEmail',
    'preApprovalAuditId',
  ];

  Object.keys(modifiedPayload).forEach((key) => {
    if (removableKeys.includes(key) && !modifiedPayload[key])
      delete modifiedPayload[key as keyof typeof modifiedPayload];
  });

  modifiedPayload.name = modifiedPayload?.name || 'DRAFT SUPPLIER';

  modifiedPayload.contactPhone = modifiedPayload?.contactPhone?.toString(); // until BE validation is changed
  if (modifiedPayload?.supplierType?.value || modifiedPayload?.supplierType?.title) {
    modifiedPayload.supplierType = payload.supplierType?.payload;
  } else {
    const { supplierType, ...newModifiedPayload } = modifiedPayload;
    modifiedPayload = newModifiedPayload;
  }

  if (modifiedPayload?.riskType?.title) {
    modifiedPayload.riskType = payload.riskType;
  } else {
    const { riskType, ...newModifiedPayload } = modifiedPayload;
    modifiedPayload = newModifiedPayload;
  }

  if (modifiedPayload?.sponsor?.value || modifiedPayload?.sponsor?.id) {
    modifiedPayload.sponsor = payload.sponsor;
  } else {
    const { sponsor, ...newModifiedPayload } = modifiedPayload;
    modifiedPayload = newModifiedPayload;
  }

  if (modifiedPayload?.approvers?.length) {
    modifiedPayload.approvers = payload.approvers;
  } else {
    const { approvers, ...newModifiedPayload } = modifiedPayload;
    modifiedPayload = newModifiedPayload;
  }

  if (modifiedPayload?.documents?.length) {
    modifiedPayload.documents = modifiedPayload?.documents
      .filter((document: any) => document.file || document.files?.length)
      .map((document: any) => {
        if (document.files?.length) {
          const output = {
            id: document.id,
            title: document?.name || document?.title,
            file: {
              // This  block has to be removed once file{} is retired in BE
              id: document.files[0]?.id,
              description: document.files[0]?.description,
              fileName: document.files[0]?.name || document.files[0]?.fileName,
              file_id: document.files[0]?.file_id || 0,
              type: document?.files[0]?.type ? document.files[0].type : 'FILE',
            },
            files: document.files.map((f: File) => ({
              id: f.id,
              description: f.description,
              fileName: f.fileName,
              file_id: f.file_id || 0,
              type: f.type,
            })),
          };

          if (!output.file.description) {
            delete output.file.description;
          }
          output.files.map((f: any) => (f.description ? f.description : delete f.description));
          return output;
        } else {
          // This  block has to be removed once file{} is retired in BE
          const output = {
            id: document.id,
            title: document?.name || document?.title,
            fileName: document.file?.name || document.file?.fileName,
            file: {
              id: document.file?.id,
              description: document.file?.description,
              fileName: document.file?.name || document.file?.fileName,
              file_id: document.file?.file_id || 0,
              type: document?.file?.type ? document.file.type : 'FILE',
            },
            files: [
              {
                id: document.file?.id,
                description: document.file?.description,
                fileName: document.file?.name || document.file?.fileName,
                file_id: document.file?.file_id || 0,
                type: document?.file?.type ? document.file.type : 'FILE',
              },
            ],
          };

          if (!output.file.description) {
            delete output.file.description;
            delete output.files[0].description;
          }

          return output;
        }
      });
  } else {
    delete modifiedPayload.documents;
  }

  return modifiedPayload;
};

export const prepareSupplierPayload = (payload: Supplier, users: User[]): any => {
  const modifiedPayload: any = { ...payload };

  const removableKeys = [
    'contactAddress',
    'contactName',
    'contactNotes',
    'contactPhone',
    'contactEmail',
    'intendedUse',
    'website',
    'approvalDate',
    'contactEmail',
    'preApprovalAuditId',
  ];

  Object.keys(modifiedPayload).forEach((key) => {
    if (removableKeys.includes(key) && !modifiedPayload[key])
      delete modifiedPayload[key as keyof typeof modifiedPayload];
  });

  modifiedPayload.contactPhone = modifiedPayload?.contactPhone?.toString(); // until BE validation is changed
  if (modifiedPayload?.supplierType?.value || modifiedPayload?.supplierType?.title) {
    modifiedPayload.supplierType = {
      title: modifiedPayload?.supplierType?.label || modifiedPayload?.supplierType?.title,
      id: modifiedPayload?.supplierType?.value || modifiedPayload?.supplierType?.id,
    };
  } else delete modifiedPayload?.supplierType;

  if (modifiedPayload?.riskType?.title) {
    modifiedPayload.riskType = {
      title: modifiedPayload.riskType.title,
      id: modifiedPayload.riskType.id,
      description: modifiedPayload.riskType.description || '',
    };
  } else {
    delete modifiedPayload?.riskType;
  }

  if (modifiedPayload?.sponsor?.value || modifiedPayload?.sponsor?.id) {
    modifiedPayload.sponsor = getUser(users, modifiedPayload.sponsor.value || modifiedPayload.sponsor.id);
  } else delete modifiedPayload?.sponsor;

  if (!modifiedPayload?.approvers?.length) {
    delete modifiedPayload?.approvers;
  }

  if (modifiedPayload?.documents?.length) {
    modifiedPayload.documents = modifiedPayload?.documents
      .filter((document: any) => document.file || document.files?.length)
      .map((document: any) => {
        if (document.files?.length) {
          const output = {
            id: document.id,
            title: document?.name || document?.title,
            file: !!document.file
              ? document.file
              : {
                  // This  block has to be removed once file{} is retired in BE
                  id: document.files[0]?.id,
                  description: document.files[0]?.description,
                  fileName: document.files[0]?.name || document.files[0]?.fileName,
                  file_id: document.files[0]?.file_id || 0,
                  type: document?.files[0]?.type ? document.files[0].type : 'FILE',
                },
            files: document.files.map((f: File) => ({
              id: f.id,
              description: f.description,
              fileName: f.fileName,
              file_id: f.file_id || 0,
              type: f.type,
            })),
          };

          if (!output.file.description) {
            delete output.file.description;
          }
          output.files.map((f: any) => (f.description ? f.description : delete f.description));
          return output;
        } else {
          // This  block has to be removed once file{} is retired in BE
          const output = {
            id: document.id,
            title: document?.name || document?.title,
            fileName: document.file?.name || document.file?.fileName,
            file: {
              id: document.file?.id,
              description: document.file?.description,
              fileName: document.file?.name || document.file?.fileName,
              file_id: document.file?.file_id || 0,
              type: document?.file?.type ? document.file.type : 'FILE',
            },
            files: [
              {
                id: document.file?.id,
                description: document.file?.description,
                fileName: document.file?.name || document.file?.fileName,
                file_id: document.file?.file_id || 0,
                type: document?.file?.type ? document.file.type : 'FILE',
              },
            ],
          };

          if (!output.file.description) {
            delete output.file.description;
            delete output.files[0].description;
          }

          return output;
        }
      });
  } else {
    delete modifiedPayload.documents;
  }

  return modifiedPayload;
};

export const prepareAuditPayload = (payload: Audit): Audit => {
  const modifiedPayload = { ...payload };

  if (!modifiedPayload.notes) {
    delete modifiedPayload.notes;
  }

  return modifiedPayload;
};

export const preparePolicyLinksV2 = (data: Array<{ supplierType: any; riskLevel: any; links: any }>): any => {
  const newLinks = {} as any;
  data?.forEach((dataItem: { supplierType: ConfigItem; riskLevel: ConfigItem; links: any }) => {
    const { supplierType, riskLevel, links } = dataItem;

    links.forEach((link: any) => {
      const { linkData, linkType } = link;
      if (newLinks[`${supplierType.id}-${riskLevel.id}`]) {
        newLinks[`${supplierType.id}-${riskLevel.id}`].linkData.push({
          riskLevel,
          supplierType,
          linkType,
          linkData,
        });
      } else {
        newLinks[`${supplierType.id}-${riskLevel.id}`] = {
          riskLevel: riskLevel,
          supplierType: supplierType,
          linkData: [],
        };
        newLinks[`${supplierType.id}-${riskLevel.id}`].linkData = [
          {
            riskLevel,
            supplierType,
            linkData,
            linkType,
          },
        ];
      }
    });
  });

  return newLinks;
};

export const getUser = (users: Array<User & { userGroups?: string[] }>, userId: string): any => {
  const sponsorUser = users.filter((user: any) => parseInt(user.id) === parseInt(userId));
  if (sponsorUser.length) {
    const { id, fullName, email, userGroups } = sponsorUser[0];
    return { id, fullName, email, userGroups };
  }
};

export const convertArrayOfObjectsToCSV = (array: Array<any>): string => {
  let result: any;

  if (!array.length) return '';
  const columnDelimiter = ',';
  const lineDelimiter = '\n';
  const keys = Object.keys(array[0]);

  result = '';
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item: any) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;

      result += item[key] ? `"${item[key]}"` : '';
      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
};

const prepareDataForDownload = (array: readonly (Supplier & Partial<NextAuditDueInfo>)[]) => {
  return array.map((item) => ({
    Name: item.name,
    'Supplier Type': item.supplierType?.title || '',
    Status: item?.status ? SUPPLIER_STATUS[item?.status].text : 'NaN',
    'Risk Level': item.riskType?.title || '',
    Sponsor: `${item?.sponsor?.fullName || item?.sponsor?.full_name}<${item?.sponsor?.email}>`,
    'Last Modified': item.modified,
    'Due At': item.dueAtISO ?? '',
  }));
};

export const downloadCSV = (array: readonly (Supplier & Partial<NextAuditDueInfo>)[]): void => {
  const downloadArray = prepareDataForDownload(array);
  let csv = convertArrayOfObjectsToCSV(downloadArray);
  if (csv == null) return;

  const filename = 'suppliers.csv';

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  const link = document.createElement('a');
  link.setAttribute('href', encodeURI(csv));
  link.setAttribute('download', filename);
  link.click();
};

export const getFallbackMap = (map: Map<string, string>, key: string): string => {
  if (!map.has(key)) {
    console.warn(`The key "${key}" was not found in your map`);
    return key;
  }

  return map.get(key) ?? '';
};

export const getRequiredAudits = (
  policyLinks: Array<any>,
  auditTypes: ConfigItem[] | undefined,
  supplierType: ConfigItem,
  riskLevel?: ConfigItem,
): Array<{ id: string; name: string; frequency: string; description: string }> => {
  if (!policyLinks) {
    return [];
  }

  const filteredLinks = policyLinks.filter(
    (link: any) =>
      link.supplierType.id === (supplierType?.value || supplierType?.id) && link.riskLevel.id === riskLevel?.id,
  );

  if (filteredLinks.length === 0) {
    return [];
  }

  return (
    filteredLinks[0].links
      ?.filter(
        (link: any) =>
          link.supplierType.id === (supplierType?.value || supplierType?.id) &&
          link.riskLevel.id === riskLevel?.id &&
          link.linkType === 'audits' &&
          link.linkData !== undefined,
      )
      .map((link: any) => {
        const auditTypeConfig = auditTypes?.filter((at: ConfigItem) => at.title === link.linkData.title) ?? [];

        return {
          id: link.linkData.id,
          name: link.linkData.title,
          frequency: link.linkData.periodic_review,
          description: auditTypeConfig?.[0]?.description || '--',
        };
      }) ?? []
  );
};

export const maybePluralize = (count: number, noun: string, suffix = 's', includeCount = true): string =>
  `${includeCount ? String(count).concat(' ') : ''}${noun}${count > 1 ? suffix : ''}`;

/*
 * This method receives a document array as input
 * returns updated document array that has "Additional Document" object which has files from all other sections
 */

export const moveFilesToAdditionalDocuments = (documents: Document[]): Document[] => {
  if (documents.length === 0) return [];

  let allFilesInDocuments: any = [];
  documents.forEach((item) => {
    allFilesInDocuments = allFilesInDocuments.concat(item.files);
  });

  let updatedDocuments = documents.filter((doc) => doc.name === 'Additional documents');
  if (updatedDocuments.length === 0) {
    updatedDocuments = documents.map((document: Document, index: number) => ({
      name: `Additional Document ${index + 1}`,
      required: false,
      loading: false,
      file: document.file,
      files: document.files,
    }));
  } else {
    updatedDocuments[0].files = allFilesInDocuments;
  }

  return updatedDocuments;
};
