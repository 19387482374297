import { fileApi } from 'api';

import supplierApi from './supplier.api';

const documentApi = {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async uploadDocument(company: number, file: any): Promise<void | string> {
    if (!company) return;
    let requestIdPayload: any;
    try {
      requestIdPayload = await supplierApi.getUploadUrl(company, { status: 'URL_REQUESTED' });
      await supplierApi.uploadDocument(requestIdPayload.url, file);
      await supplierApi.updateDocumentStatus(company, requestIdPayload.id, {
        status: 'UPLOADED',
        fileName: file.name,
      });
      return requestIdPayload;
    } catch (error) {
      try {
        await supplierApi.updateDocumentStatus(company, requestIdPayload.id, {
          status: 'ABORTED',
          fileName: file.name,
        });
      } catch (error) {
        console.error(error);
      }
    }
  },
  async removeDocument(company: number, documentId: string): Promise<void> {
    if (!company) return;
    try {
      await supplierApi.deleteDocument(company, documentId);
    } catch (error) {
      console.error(error);
    }
  },
  async getDocumentFile(company: number, documentId: string): Promise<string | any> {
    if (!company) return;
    try {
      const { data: url } = await supplierApi.fetchDocumentGetURL(company, documentId);
      //Get S3 item from URL
      const blob = await fileApi.get(url, {
        withCredentials: false,
        responseType: 'blob',
      });
      return blob;
    } catch (error) {
      console.error(error);
    }
  },
};

export default documentApi;
