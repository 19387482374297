import { useCurrentUser } from '@qualio/ui-components';
import { supplierApi } from 'api';
import { useQuery } from 'react-query';

type UsePoliciesQueryType = {
  policyLinks?: any;
  isPolicyLoading: boolean;
  refetchPolicies: () => void;
};

export const usePoliciesQuery = (isConfigsLoading: boolean): UsePoliciesQueryType => {
  const { companyId } = useCurrentUser();

  const {
    data: policyLinks,
    isLoading: isPolicyLoading,
    refetch: refetchPolicies,
  } = useQuery('supplierPolicyLinks', () => supplierApi.getPolicyLinks(companyId), {
    enabled: !isConfigsLoading,
  });

  return { policyLinks, isPolicyLoading, refetchPolicies };
};
