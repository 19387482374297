import React from 'react';

import { CurrentUser } from '@qualio/ui-components';
import { Page, StyleSheet, View } from '@react-pdf/renderer';
import { Supplier } from 'types';

import { aslRootStyle } from '../ASLExportDocument';
import ListHeader from './ListHeader';
import PageFooter from './PageFooter';
import PageHeader from './PageHeader';
import SupplierRow from './SupplierRow';

type SupplierListPageProps = {
  suppliers: Supplier[];
  currentUser: CurrentUser;
  policyLinks: any;
  auditTypes: any;
};

export const columnStyles = StyleSheet.create({
  column1: {
    width: '25%',
    paddingRight: 8,
  },
  column2: {
    width: '7%',
    paddingRight: 8,
  },
  column3: {
    width: '7.5%',
    paddingRight: 8,
  },
  column4: {
    width: '40.5%',
    paddingRight: 8,
  },
  column5: {
    width: '10%',
    paddingRight: 8,
  },
  column6: {
    width: '10%',
    paddingRight: 8,
  },
});

const SupplierListPage: React.FC<SupplierListPageProps> = ({ currentUser, suppliers, policyLinks, auditTypes }) => {
  return (
    <Page size="A4" style={aslRootStyle.page}>
      <PageHeader currentUser={currentUser} />
      <ListHeader />
      <View style={{ paddingBottom: 24 }}>
        {suppliers.map((supplier, index) => (
          <SupplierRow supplier={supplier} key={index} policyLinks={policyLinks} auditTypes={auditTypes} />
        ))}
      </View>
      <PageFooter />
    </Page>
  );
};

export default SupplierListPage;
