import React, { useEffect } from 'react';

import { QAlert, QBox, QCenter, QHeading, QLink, QSpinner, QStack, QText } from '@qualio/ui-components';
import { FormikProps } from 'formik';
import { usePolicyLinksQuery } from 'hooks';
import { Document } from 'providers';
import { routes } from 'utils';
import { AddSupplierFormikPayload } from 'v2types';

import { SupportingDocument } from './components';

type SupportingDocumentsFormProps = {
  formik: FormikProps<AddSupplierFormikPayload>;
};

export const SupportingDocumentsForm: React.FC<SupportingDocumentsFormProps> = ({ formik }) => {
  const { values, setFieldValue, errors, touched } = formik;
  const formDocuments = values.documents;
  const { policyDocuments, isLoading, isRefetching } = usePolicyLinksQuery(
    formDocuments,
    values.supplierType?.value,
    values.riskType?.id,
  );
  const handleDocumentUpdated = (newDocument: Document, oldDocuments: Document[]) => {
    let newDocuments: Document[] = oldDocuments ? [...oldDocuments] : [...policyDocuments];
    const index = newDocuments.findIndex((item) => item.name === newDocument.name);

    index < 0 && newDocument.name === 'Additional documents'
      ? newDocuments.push(newDocument)
      : (newDocuments[index] = newDocument);

    if (
      newDocument.name === 'Additional documents' &&
      newDocument.loading === false &&
      newDocument?.files &&
      newDocument.files?.length < 1
    ) {
      newDocuments = newDocuments.filter((item) => item.name !== 'Additional documents');
    }
    setFieldValue('documents', newDocuments);
  };

  useEffect(() => {
    if (formDocuments.length === 0) {
      setFieldValue('documents', policyDocuments);
      return;
    }
    const policyLookup = policyDocuments.reduce((lookup: Record<string, Document>, item) => {
      lookup[item.name] = item;
      return lookup;
    }, {});

    const updatedFormDocuments = formDocuments.map((document) => ({
      ...document,
      required: policyLookup[document.name]?.required ?? document.required,
    }));

    const requiredDocumentsInForm = updatedFormDocuments.filter((item) => item.required);
    const policyRequiredDocumentsNotInForm = policyDocuments.filter(
      (policyDoc) => policyDoc.required && !updatedFormDocuments.some((doc) => doc.name === policyDoc.name),
    );

    const nonRequiredDocuments = updatedFormDocuments.filter((item) => !item.required);

    setFieldValue('documents', [
      ...requiredDocumentsInForm,
      ...policyRequiredDocumentsNotInForm,
      ...nonRequiredDocuments,
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyDocuments, setFieldValue, formDocuments.length, isLoading]);

  const isFieldInvalid = (field: keyof AddSupplierFormikPayload) => {
    return !!touched && !!touched[field] && !!errors[field];
  };

  const requiredDocs = formDocuments?.filter((item) => item.required);
  const nonRequiredDocs = formDocuments?.filter((item) => !item.required);
  const showDocumentsMovedWarning = () => {
    return formik.values.supplierTypeOrRiskUpdated === true ? (
      <QAlert
        mb={4}
        mt={2}
        status="warning"
        title={`Documents moved to "Additional documents"`}
        description="Each risk level may require different documents. Because the risk level was changed, all documents have been moved to “Additional documents”. To reuse them, download and add them to the required documents. These changes will take effect when you save the supplier.
"
      ></QAlert>
    ) : (
      ''
    );
  };

  return (
    <QBox maxWidth="640px" mb={2}>
      <QHeading mb={2}>Supporting documents</QHeading>
      {isFieldInvalid('documents') ? <QAlert status="error" description={errors.documents} /> : null}
      {!values?.supplierType?.value || !values?.riskType?.id ? (
        <QAlert
          status="warning"
          description="Select supplier type and risk level to see which documents are required."
        />
      ) : isLoading || isRefetching ? (
        <QCenter p={5}>
          <QSpinner />
        </QCenter>
      ) : !requiredDocs?.length ? (
        <QBox my={5}>
          <QAlert
            status="warning"
            description={
              <QText>
                Your supplier policy does not require any mandatory documents for the supplier type{' '}
                <b>{values.supplierType.label}</b> and the risk level <b>{values?.riskType?.title}</b>.
              </QText>
            }
          />
          {showDocumentsMovedWarning()}
        </QBox>
      ) : (
        <>
          <QText fontSize="sm" color="gray.500" pb={6}>
            Your{' '}
            <QLink isExternal href={`/sqm/${routes.policy.root}/${routes.policy.documents}`}>
              supplier policy
            </QLink>{' '}
            requires the following documents for the supplier type <b>{values.supplierType.label}</b> and the risk level{' '}
            <b>{values?.riskType?.title}</b>.
          </QText>
          {showDocumentsMovedWarning()}

          <QStack spacing={4}>
            {requiredDocs.map((item: any, index: number) => (
              <SupportingDocument
                key={`document-card-${index}`}
                index={index}
                fileInputIndex={index}
                document={item}
                documentUpdated={(document: Document) => handleDocumentUpdated(document, formDocuments)}
              />
            ))}
          </QStack>
        </>
      )}

      <QStack spacing={4} pt={4}>
        {nonRequiredDocs.map((item, index: number) => (
          <SupportingDocument
            key={`additional-document-card-${index}`}
            index={index}
            fileInputIndex={requiredDocs.length + index}
            document={item}
            documentUpdated={(document: Document) => handleDocumentUpdated(document, formDocuments)}
          />
        ))}
      </QStack>
    </QBox>
  );
};
