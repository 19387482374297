import { useEffect, useState } from 'react';

import { QStepType } from '@qualio/ui-components';
import { FormikProps, useFormik } from 'formik';
import { useCreateSupplierMutate } from 'hooks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { routes } from 'utils';
import { AddSupplierFormikPayload, addSupplierValidationShape } from 'v2types';
import { toFormikValidationSchema } from 'zod-formik-adapter';

type UseStepperProps = {
  activeStep: number;
  steps: QStepType[];
  formik: FormikProps<AddSupplierFormikPayload>;
  handleStepChange: (stepIndex: number) => void;
  handleNextStep: () => void;
  handlePreviousStep: () => void;
  handleSubmitForm: () => void;
  handleCancelForm: () => void;
  isSupplierCreating: boolean;
};
export const useStepper = (formikValues: AddSupplierFormikPayload): UseStepperProps => {
  const navigate = useNavigate();
  const onSupplierCreated = (supplierId: string) => {
    navigate(`/${routes.supplierDetails.root}/${supplierId}/${routes.supplierDetails.detailsTab}`);
  };
  const { createSupplierMutate, isSupplierCreating } = useCreateSupplierMutate(onSupplierCreated);

  const formik = useFormik({
    initialValues: formikValues,
    enableReinitialize: true,
    validationSchema: toFormikValidationSchema(addSupplierValidationShape),
    onSubmit: async (args, { setFieldError, setSubmitting }): Promise<void> => {
      createSupplierMutate(
        { supplier: args, status: 'DRAFT', isDraft: false },
        {
          onError: (args) => {
            if (args.response?.data.errors) {
              args.response?.data.errors.forEach((error) => {
                setFieldError(error.field, error.message[0]);
              });
            }

            setSubmitting(false);
          },
        },
      );
    },
  });

  const [activeStep, setActiveStep] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [steps, setSteps] = useState<QStepType[]>([
    { title: 'General info', children: <></>, variant: 'default' },
    { title: 'Risk assessment', children: <></>, variant: 'default' },
    { title: 'Supporting documents', children: <></>, variant: 'default' },
    { title: 'Approval', children: <></>, variant: 'default' },
  ]);

  const markStepAsVisited = () => {
    setSteps((prev) => {
      const newSteps = [...prev];
      newSteps[activeStep].variant = 'visited';
      return newSteps;
    });
  };

  const handleStepChange = (stepIndex: number) => {
    markStepAsVisited();
    setActiveStep(stepIndex);
  };

  const handleNextStep = () => {
    markStepAsVisited();
    setActiveStep((prev) => prev + 1);
  };

  const handlePreviousStep = () => {
    markStepAsVisited();
    setActiveStep((prev) => prev - 1);
  };

  const handleCancelForm = () => {
    createSupplierMutate({ supplier: formik.values, status: 'DRAFT', isDraft: true });
  };

  const handleSubmitForm = () => {
    formik.setStatus('touched');
    formik.handleSubmit();
  };

  useEffect(() => {
    const stepSearchParam = searchParams.get('step');
    if (stepSearchParam && parseInt(stepSearchParam) <= steps.length) {
      setActiveStep(parseInt(stepSearchParam) - 1);
    }
    setSearchParams({});
  }, [searchParams, setSearchParams, steps.length]);

  return {
    formik,
    activeStep,
    steps,
    handleStepChange,
    handleNextStep,
    handlePreviousStep,
    handleCancelForm,
    handleSubmitForm,
    isSupplierCreating,
  };
};
