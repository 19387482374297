import React from 'react';

import { QText, QFlex, QButton, QBox } from '@qualio/ui-components';
import { errorNameMapping } from 'v2types';

type WarningMessageProps = {
  name: string;
  error: string;
  handleStepChange: (stepIndex: number) => void;
};

const WarningMessage: React.FC<WarningMessageProps> = ({ name, error, handleStepChange }) => {
  return (
    <QBox data-cy={`warning-message-${name}`}>
      <QFlex>
        <QText pr={2} fontSize="sm" weight="semibold">
          {errorNameMapping[name].errorName}
        </QText>
        <QButton variant="link" onClick={() => handleStepChange(errorNameMapping[name].step)}>
          Review
        </QButton>
      </QFlex>
      <QText color="gray.500" fontSize="sm">
        {error}
      </QText>
    </QBox>
  );
};
export default WarningMessage;
