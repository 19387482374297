import React from 'react';

import { QBox, QDivider, QHeading, QStack, QEmptyState } from '@qualio/ui-components';
import { Document, File } from 'providers/types';
import { ViewFileCard } from 'v2components';
import { useRouterOutletSupplier } from 'v2views';

const SupplierDocuments: React.FC = () => {
  const { supplier } = useRouterOutletSupplier();

  if (!supplier?.documents?.length)
    return (
      <QEmptyState
        title="Documents for this supplier will appear here"
        subtitle="If your Supplier Policy requires specific documents, edit the supplier to include them here."
      />
    );

  const requiredDocuments = supplier?.documents
    ?.filter((document) => !(document?.title?.toLowerCase() === 'additional documents'))
    .map((item) => ({ ...item, name: item.title || item.name }));

  const additionalDocuments = supplier?.documents
    ?.filter((document) => document?.title?.toLowerCase() === 'additional documents')
    .map((item) => ({ ...item, name: item.title || item.name }));

  return (
    <QBox display="flex" flexDirection="column" maxWidth="800px">
      {requiredDocuments.map((document: Document, index) => (
        <QBox key={index}>
          <QStack direction="column" spacing={0} data-cy="supplier-document">
            <QHeading size="sm" pb={4}>
              {document?.name}
            </QHeading>
            {document.files?.map((file: File, index: number) => (
              <ViewFileCard key={index} file={file} />
            ))}
          </QStack>
          {index < requiredDocuments.length - 1 ? <QDivider my={6} /> : null}
        </QBox>
      ))}
      {additionalDocuments.length ? (
        <QStack direction="column" spacing={0} data-cy="supplier-document">
          <QHeading size="sm" pb={4}>
            Additional documents
          </QHeading>
          {additionalDocuments.map((document) =>
            document.files.map((file: File, index: number) => <ViewFileCard key={index} file={file} />),
          )}
        </QStack>
      ) : null}
    </QBox>
  );
};

export default SupplierDocuments;
